// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputBox, InputRange, InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang, langAsHtml} from "../../../../assets/js/language-utils";

class ChangeCourseData extends Component {

    state = {
        name: this.props.course.courseName,
        key: this.props.course.key,
        courseID: this.props.course.courseId,
        minMember: Number(this.props.course.minMember),
        maxMember: Number(this.props.course.maxMember),
        reqStudentID: this.props.course.reqStudentID,
        sendReminder: this.props.course.sendReminder || false,
        canUsersManageTeams: this.props.course.canUsersManageTeams,
        isLocked: this.props.course.isLocked || false,
        isFetching: false,
        testHash: testHash,
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleInputBoxChange = (e) => {
        const name = e.target.name
        this.setState(prevState => (
            { [name]: !prevState[name] }
        ))
    }

    handleRangeChange = (value) => {
        this.setState({
            minMember: value[0],
            maxMember: value[1]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.name.length < 3) {
            this.setState({
                msg: {
                    type: 'error',
                    text: lang('courses.minNam')
                }
            })
        } else {
            this.editCourse()
        }
    }

    editCourse = () => {
        this.setState({isFetching: true})
        const data = qs.stringify(this.state)
        apiv3.put(`/course/${this.state.courseID}`, data)
        .then(res => {
            this.setState({isFetching: false})
            if (res.data.isValid) {
                this.editCourseSuccess()
            } else {
                this.editCourseFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.editCourseFailed(err)
        })
    }

    editCourseSuccess = () => {
        this.props.setModal(false)
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: lang('courses.updateSuccess')
        })
    }

    editCourseFailed = (err) => {
        this.setState({
            msg: {
                type: 'error',
                text: err
            }
        })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('courses.updateProcess')}>
                    <Loader />
                </Modal>
            )
        }

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        return (
            <Modal title={lang('courses.updateTitle')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.editCourse) }>
                    <div className="body">
                    
                        <InputText
                            label =         {lang('courses.name')}
                            type =          'text'
                            name =          'name'
                            placeholder =   {lang('courses.namePlaceholder')}
                            value =         { this.state.name }
                            onChange =      { this.handleInputChange }
                            focus
                        />

                        <InputText
                            label =         {lang('courses.key')}
                            type =          'text'
                            name =          'key'
                            placeholder =   {lang('courses.keyPlaceholder')}
                            desc =          {lang('courses.keyDesc')}
                            value =         { this.state.key }
                            onChange =      { this.handleInputChange }
                            maxLength =     {8}
                        />

                        <InputRange
                            label =     {lang('courses.membersPerTeam')}
                            name =      'members'
                            min =       { 1 }
                            max =       { 6 }
                            step =      { 1 }
                            minText =   {lang('courses.minMember')}
                            maxText =   {lang('courses.maxMember')}
                            value =     {[this.state.minMember, this.state.maxMember]}
                            onChange =  { this.handleRangeChange }
                            dots
                        />

                        <div className="field"><label>{lang('courses.advancedSetup')}</label></div>

                        <InputBox
                            type =      'checkbox'
                            name =      'reqStudentID'
                            id =        'reqStudentID'
                            label =     {lang('courses.studentIdRequired')}
                            checked =   { this.state.reqStudentID }
                            onChange =  { this.handleInputBoxChange }
                        />

                        {/*<InputBox */}
                        {/*    type =      'checkbox'*/}
                        {/*    name =      'sendReminder'*/}
                        {/*    id =        'sendReminder'*/}
                        {/*    label =     {lang('courses.sendRemainder')}*/}
                        {/*    checked =   { this.state.sendReminder }*/}
                        {/*    onChange =  { this.handleInputBoxChange }*/}
                        {/*/>*/}

                        <InputBox 
                            type =      'checkbox'
                            name =      'canUsersManageTeams'
                            id =        'canUsersManageTeams'
                            label =     {lang('courses.usersManageTeams')}
                            checked =   { this.state.canUsersManageTeams }
                            onChange =  { this.handleInputBoxChange }
                        />

                        <InputBox 
                            type =      'checkbox'
                            name =      'isLocked'
                            id =        'isLocked'
                            label =     {lang('courses.lock')}
                            checked =   { this.state.isLocked }
                            onChange =  { this.handleInputBoxChange }
                        />

                        <small>{langAsHtml('courses.editTip')}</small>

                        <FormMessage />
                    </div>

                    <div className="footer">
                        <Button className='primary' text={lang('courses.save')} type='submit'  onClick = { (e) => this.handleSubmit(e, this.editCourse) } />
                        <Button className='danger' text={lang('courses.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        simulations: state.user.simulations,
        course: state.ui.modal.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        fetchUserData: () => dispatch(fetchUserData()),
        createAlert: alert => dispatch(createAlert(alert))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeCourseData))