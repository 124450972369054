import React from "react";
import {connect} from "react-redux";
import Modal from "../Modal";
import {Button} from "../../Button/Button";
import {setModal} from "../../../store/actions/ui";
import {lang} from "../../../assets/js/language-utils.js";

const Confirm = ({
                     title, confirm = () => {
    }, cancel = () => {
    }, setModal,
                 }) => {
    return (
        <Modal
            title={title}>
            <form className="form">
                <div className="body">
                    <div className="line">
                        <Button
                            type="button"
                            className="danger"
                            text={lang("courses.no")}
                            onClick={() => {
                                setModal(null);
                                cancel();
                            }} />
                        <Button
                            type="button"
                            className="primary"
                            text={lang("courses.yes")}
                            onClick={() => {
                                setModal(null);
                                confirm();
                            }} />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

const mapStateToProps = state => ({
    confirm: state.ui.modal.data.confirm,
    cancel: state.ui.modal.data.cancel,
    title: state.ui.modal.data.title,
});

const mapDispatchToProps = dispatch => ({
    setModal: modal => dispatch(setModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);