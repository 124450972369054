// External
import React, {Component} from "react";
import {connect} from "react-redux";
//Actions
import {createAlert, setModal} from "../../../store/actions/ui";
// Components
import Modal from "../Modal";
import {Button} from "../../Button/Button";
import {InputBox, InputTextArea} from "../../Form/Inputs";
import {BuildInputs} from "../../Form/BuildInputs";
import {Loader} from "../../Loader/Loader";
// Request
import {handleSubmit} from "../../../assets/js/utility";
import {apiv3, testHash} from "../../../axios";
import qs from "qs";
import {lang} from "../../../assets/js/language-utils";

class Licence extends Component {

    state = {
        licence: 'premium',
        country: '',
        organization: '',
        jobTitle: '',
        phone: '',
        comment: '',
        isFetching: false,
        testHash: testHash
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleRadioChange = (e) => {
        this.setState({ [e.target.name]: e.target.id })
    }

    sendLicenceRequest = () => {
        this.setState({ isFetching: true })
        const data = qs.stringify(this.state)
        apiv3.post("/user/licence/upgrade", data)
        .then(res => {
            if (res.data.isValid) {
                this.sendLicenceRequestSuccess()
            } else {
                this.sendLicenceRequestFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.sendLicenceRequestFailed(err)
        })
    }

    sendLicenceRequestSuccess = () => {
        this.setState({ isFetching: false })
        this.props.setModal(false)
        this.props.createAlert({
            type: 'success',
            msg: lang('licence.requestSent')
        })
    }

    sendLicenceRequestFailed = err => {
        this.setState({ isFetching: false })
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
    }

    render() {

        const radioBoxData = [
            {
                label: lang('licence.basic'),
                id: 'basic'
            }, {
                label: lang('licence.premium'),
                id: 'premium'
            }, {
                label: lang('licence.pro'),
                id: 'pro'
            }
        ]

        const inputData = [
            {
                label: lang('licence.country'),
                type: 'text',
                name: 'country',
                placeholder: lang('licence.countryPlaceholder'),
                col: 'md-6'
            }, {
                label: lang('licence.organization'),
                type: 'text',
                name: 'organization',
                placeholder: lang('licence.organizationPlaceholder'),
                col: 'md-6'
            }, {
                label: lang('licence.jobTitle'),
                type: 'text',
                name: 'jobTitle',
                placeholder: lang('licence.jobTitlePlaceholder'),
                col: 'md-6'
            }, {
                label: lang('licence.phoneNumber'),
                type: 'text',
                name: 'phone',
                placeholder: lang('licence.phoneNumberPlaceholder'),
                col: 'md-6'
            }
        ]

        const BuildRadioBoxes = () => radioBoxData.map(box => {
            return (
                <InputBox
                    type =      'radio'
                    name =      'licence'
                    label =     { box.label }
                    id =        { box.id }
                    key =       { box.id }
                    checked =   { this.state.licence === box.id}
                    onChange =  { this.handleRadioChange }
                />
            )
        })

        if (this.state.isFetching) {
            return (
                <Modal title={lang('licence.processing')}>
                    <Loader />
                </Modal>
            )
        }

        return (
            <Modal title={lang('licence.title')}>
                <form className='form' onSubmit={ (e) => handleSubmit(e, this.sendLicenceRequest) }>
                    <div className="body">
                        <div className='label'>{lang('licence.licencePreference')}</div>
                        <div className="inline">
                            <BuildRadioBoxes />
                        </div>

                        <BuildInputs
                            data = { inputData }
                            value = { this.state }
                            onChange = { this.handleInputChange }
                        />

                        <InputTextArea
                            name = 'comment'
                            label = {lang('licence.comment')}
                            placeholder = {lang('licence.commentPlaceholder')}
                            maxLength = { 300 }
                            value = { this.state.comment }
                            onChange = { this.handleInputChange }
                        />

                        <small>{lang('licence.tip')}</small>
                    </div>
                    <div className="footer">
                        <Button className='primary' text={lang('licence.send')} type='submit' onClick = { (e) => handleSubmit(e, this.sendLicenceRequest) }/>
                        <Button className='danger' text={lang('licence.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        createAlert: alert => dispatch(createAlert(alert))
    }
}

export default connect(null, mapDispatchToProps)(Licence)