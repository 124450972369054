import memoize from "fast-memoize";
import {find, get} from "lodash";
import {sl} from "../../views/Simulation";
import {simLang} from "../../assets/js/language-utils";

export const logAndReturn = (value, name = "") => {
    if (name.length > 0) {
        console.log(`${name}:`, value);
    } else {
        console.log(value);
    }
    return value;
};

export const getDecision = ({path, period, teamID, teamIndex, teams}) => {
    return getDecisionFromPath({period, teamID, teamIndex, teams}, path);
};

export const getDecisionFromPath = ({period, teamID, teamIndex, teams}, path) => {
    path = `decisions.${period}.${path}`;
    const selectedTeam = teamID === null
        ? teams[teamIndex] || teams[0]
        : find(teams, team => team.id === teamID);
    
    let val = get(selectedTeam, path);
    if (val === "true" || val === "false")
        return +(val === "true");
    return Number(val || 0);
};

export const getAllDecisions = ({path, period, teams}) => {
    /*console.log("getAllDecisions", path, period, teams);*/
    /*console.log(period)*/
    teams.map(team => {
        /*console.log("team", period, team.name, get(team.decisions, `${period}.${path}`));*/
        /*console.log(getPeriodValue(team.decisions, period), path)*/
    })
    return teams.map(team => Number(get(getPeriodValue(team.decisions, period), path)));
};

export const createItems = (items, category) => {
    return items.map(({id, icon}) => {
        if (typeof sl === "undefined") {
            throw new Error("This language is not supported!");
        }
        if (typeof sl[category][id] === "undefined") {
            throw new Error(`${id} is not found in simulation language!`);
        }
        return {
            id,
            name: sl[category][id].name,
            desc: sl[category][id].desc,
            icon: icon || sl[category][id].name,
        };
    });
};

export const memoizeCaches = [];

function sizedCache(size) {
    return {
        create() {
            const track = new Array(size);
            const store = {};
            let idx = 0;
            return {
                has(key) {
                    return (key in store);
                },
                get(key) {
                    return store[key];
                },
                set(key, value) {
                    store[key] = value;

                    if (track[idx])
                        delete store[track[idx]];

                    track[idx] = key;

                    ++idx;

                    if (idx === size)
                        idx = 0;
                },
            };
        },

    };
}

/* Custom memoize function, implemented as memoize with a custom serializer */
export const mem = fn => {
    return memoize(fn, {
        serializer: function () {
            let args = arguments[0].length > 1
                ? {...arguments[0][0]}
                : {...arguments[0]};

            if (typeof args.decisionSetup === "undefined" || typeof args.market === "undefined") {
                throw new Error("Decision setup or market is missing!");
            }
            
            args.decisionSetup = null;
            args.market = null;
            
            if (args.period < args.selectedPeriod) {
                args.teams = null;
            }

            
            return JSON.stringify(args);
        },
        cache: sizedCache(16384),
    });
};

export const getPeriodValue = (values, period) => {
    if (period >= values.length)
        period = values.length - 1;
    if (period < 0)
        period = 0;
    return values[period];
};

export const generateTeamNames = o => {
    return o.teams.map(team => team.name).join(";");
};

export const generateCSVFromItems = (o, title, category, items, factory, depth = 0, itemNames = null, marketResearch = false) => {
    let output = "    ".repeat(depth) + title + "\n";
    for (let item of items) {
        const name = itemNames === null ? simLang(`${category}.${item}.name`) : itemNames[item];
        output += "    ".repeat(depth + 1) + name + "\n";
        output += generateCSVFromReportData({...o, item, category}, factory("", item), depth + 1, marketResearch);
    }
    return output;
};

export const generateCSVFromReportData = (o, data, depth = 0, marketResearch = false) => {
    return data.body.map(row => {
        if (row === null)
            return "";
        let res = "    ".repeat(depth + 1);
        let teamIDs = (marketResearch ? o.teams.map(team => team.id) : [o.teamID]);
        let padding = marketResearch ? 0 : o.teamIndex;

        if (row.hidden) {
            return "";
        } else if (row.field.type === "decision") {
            const langPath = get(o.decisionSetup, row.field.path).lang;
            res += `${simLang(langPath)};`;
            res += ";".repeat(padding);
            res += teamIDs.map(teamID =>
                getDecision({
                    ...o,
                    teamID,
                    teamIndex: o.teams.findIndex(team => team.id === teamID),
                    path: row.field.path,
                }).toFixed(2)).join(";") + ";";
        } else if (row.field.type === "sales") {
            const langPath = get(o.decisionSetup, row.field.path).lang;
            res += `${simLang(langPath)};`;
            res += ";".repeat(padding);
            res += teamIDs.map(teamID =>
                row.field.value({
                    ...o,
                    teamID,
                    teamIndex: o.teams.findIndex(team => team.id === teamID),
                }).toFixed(2)).join(";") + ";";
        } else if (row.type === "desc") {
            res += `${row.field.show}`;
        } else {
            res += `${row.field.show};`;
            res += ";".repeat(padding);
            res += teamIDs.map(teamID => {
                let val = row.field.value({...o, teamID, teamIndex: o.teams.findIndex(team => team.id === teamID)});
                // console.log(row.field.show, val)
                if (typeof val === "string")
                    return val;
                // Limit the precision to 15 significant digits
                val = Number(val.toString().slice(0, 15));
                return val.toFixed(2);
            }).join(";") + ";";
        }
        return res;
    }).filter(line => line.length > 0).join("\n") + "\n";
};

// Create report data from a collection
export const create = (collection) => ([
    {type: "desc", field: {show: collection.show}},
    ...collection.items.map(item => (
        {type: "sub", field: item}
    )),
    {type: "sum", field: collection.sum},
]);

export const balance = (o, base, balancee) => {
    if (o.period)
        return 0;
    return base(o) - balancee(o);
};

export const teamPieChartGenerator = (func, path, teams, itemName = "") => ({
    title: simLang(path) + (itemName.length > 0 ? ` - ${itemName}` : ""),
    head: teams.map(team => team.name),
    body: teams.map((team, i) => ({value: o => func({...o, teamID: team.id, teamIndex: i})})),
});

export const productChartGenerator = (func, path, items, selectedPeriod, category, selectedTeamIndex = null) => ({
    title: simLang(path),
    body: items.map(item => ({
        field: {
            show: item.name,
            value: o => func({
                ...o,
                item: item.id,
                category: category ?? item.category ?? o.category,
                teamID: (o.teams[selectedTeamIndex ?? o.teamIndex] ?? {}).id ?? null,
                teamIndex: selectedTeamIndex ?? o.teamIndex ?? 0,
            }),
        },
    })),
    plusPeriod: selectedPeriod - 2,
    periodDiff: 1 - selectedPeriod,
});

export const radarChartGenerator = (func, path, items, itemName = "") => ({
    title: simLang(path) + (itemName.length > 0 ? ` - ${itemName}` : ""),
    head: items.map(item => item.name),
    body: items.map(item => ({show: simLang(path), value: o => func({...o, item: item.id})})),
});

export const stackedChartGenerator = (funcs, labels, path, teams, selectedPeriod) => ({
    title: simLang(path),
    head: "teamNames",
    body: funcs.map((func, i) => ({
        field: {
            show: labels[i],
            value: o => func({ ...o, teamIndex: o.period - selectedPeriod + 1, teamID: teams[o.period - selectedPeriod + 1]?.id, period: selectedPeriod }), 
        },
    })),
    plusPeriod: teams.length,
});
