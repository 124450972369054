import Box from "../../../../components/Box/Box";
import {lang} from "../../../../assets/js/language-utils.js";
import Team from "../../../../components/Lobby/Team.js";
import React from "react";
import {connect} from "react-redux";
import {Button} from "../../../../components/Button/Button.js";
import "./Teams.css";
import qs from "qs";
import {apiv3, testHash} from "../../../../axios.js";
import {setModal} from "../../../../store/actions/ui.js";
import {shuffle} from "../../../../assets/js/utility";


export const organize = async (selectedCourse, unassignedTeams, maxTeam, pools, courseID, roundID, setModal) => {
    const teamsWithAdequateUsers = (unassignedTeams.map(team => team.users.length >= parseInt(selectedCourse.minMember) && team.users.length <= parseInt(selectedCourse.maxMember) ? team : null)).filter(Boolean)
    if (unassignedTeams.length <= 0)
        return;
    setModal("loader", {center: true});
    let copy = [...teamsWithAdequateUsers];
    copy = shuffle(copy);
    await Promise.all(pools.map(async pool => {
        for (let i = 0; i < maxTeam - pool.teams.length; i++) {
            const currentTeam = copy.length ? copy.shift() : null;
            if (currentTeam) {
                const data = qs.stringify({teamIdArray: [currentTeam.id], poolID: pool.id, roundID, type: "move", testHash});
                await apiv3.post(`/course/${courseID}/moveTeam`, data);
            }
        }
    }));

    for (let i = 0; i < copy.length; i += maxTeam) {
        const teamIds = copy.slice(i, i + maxTeam).filter(team => team).map(team => team.id);
        const data = qs.stringify({teamIdArray: teamIds, poolID: null, roundID, type: "new", testHash});
        await apiv3.post(`/course/${courseID}/moveTeam`, data);
    }
    setModal(null, null);
    location.reload();
};

const Teams = ({selectedCourse, roundID, maxTeam, setModal}) => {

    const teams = selectedCourse.teams;
    const pools = selectedCourse.rounds.find(round => round.id === roundID).pools;
    const haveTeams = typeof teams === "undefined" ? false : teams.length > 0;

    const teamsInPools = (pools ?? []).reduce((prev, curr) => [...prev, ...curr.teams.map(team => team.id)], []);
    const unassignedTeams = (teams ?? []).filter(team => teamsInPools.indexOf(team.id) === -1);

    return (
        <Box title={lang("lobby.unassignedTeams")} collapsed={!haveTeams}>
            <Button onClick={() => organize(selectedCourse, unassignedTeams, maxTeam, pools, selectedCourse.courseId, roundID, setModal)}
                    text={lang("lobby.automaticAssignment")}
                    title={lang("lobby.autoAssignTeams")}
                    className="primary organize" icon="fas fa-cogs" />
            {(haveTeams && unassignedTeams.length > 0) ? unassignedTeams.map(team => {
                return (
                    <Team
                        key={team.id}
                        id={team.id}
                        name={team.name}
                        move={selectedCourse.isOwner}
                        delete={selectedCourse.isOwner}
                        pools={pools}
                        courseID={selectedCourse.courseId}
                        roundID={roundID}
                        poolID={null} />
                );
            }) : <div>{lang("lobby.noUnassignedTeams")}</div>}
        </Box>
    );
};

const mapStateToProps = state => ({
    selected: state.user.selected,
    maxTeam: +state.user.courses
        .find(course => course.courseId === state.user.selected.courseID).rounds
        .find(round => round.id === state.user.selected.roundID).maxTeam
});

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);