import {getActiveItem, getItemWithID} from "../../../../assets/js/utility";
import {connect} from "react-redux";

const RoundName = ({courses, selectedRound}) => {
    const activeCourse = getActiveItem(courses);
    return getItemWithID(selectedRound, activeCourse.rounds).name;
};

const mapStateToProps = state => {
    return {
        selectedRound: state.user.selected.roundID,
        courses: state.user.courses,
    };
};

export default connect(mapStateToProps, null)(RoundName);