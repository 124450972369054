import {fetchEnd, initActiveItems, resetActiveItems} from "./ui";

export const INIT_SIMULATION = "INIT_SIMULATION";
export const INIT_FINISH = "INIT_FINISH";
export const INIT_REQUEST = "INIT_REQUEST";
export const CHANGE_DECISION = "CHANGE_DECISION";
export const SELECT_TEAM = "SELECT_TEAM";
export const SET_CALCULATED = "SET_CALCULATED";
export const SET_SAVE_STATE = "SET_SAVE_STATE";
export const NEXT_PERIOD = "NEXT_PERIOD";
export const PREV_PERIOD = "PREV_PERIOD";
export const SELECT_PERIOD = "SELECT_PERIOD";
export const SET_CSV_DATA_GENERATOR = "SET_CSV_DATA_GENERATOR";
export const SET_ENGINE_DEA_GENERATOR = "SET_ENGINE_DEA_GENERATOR";

export const initSimulation = (init) => (dispatch) => {
    dispatch(initActiveItems());
    dispatch({
        type: INIT_SIMULATION,
        init,
    });
    dispatch(initFinish());
    dispatch(fetchEnd());
};
export const initFinish = () => {
    return {
        type: INIT_FINISH,
    };
};
export const initRequest = () => dispatch => {
    dispatch(resetActiveItems());
    dispatch({type: INIT_REQUEST});
};
export const changeDecision = (dec) => (dispatch) => {
    dispatch({
        ...dec,
        type: CHANGE_DECISION,
    });
};
export const changeDecisionTS = dec => ({
    type: CHANGE_DECISION,
    ...dec,
});

export const selectTeam = (selector) => {
    return {
        type: SELECT_TEAM,
        selector: selector,
    };
};
export const setCalculated = () => {
    return {
        type: SET_CALCULATED,
    };
};
export const setSaveState = (status) => {
    return {
        type: SET_SAVE_STATE,
        status: status,
    };
};
export const nextPeriod = () => {
    return {
        type: NEXT_PERIOD,
    };
};

export const prevPeriod = () => {
    return {
        type: PREV_PERIOD,
    };
};

export const selectPeriod = (period) => {
    return {
        type: SELECT_PERIOD,
        period: period,
    };
};

export const setCSVDataGenerator = (generator) => ({
    type: SET_CSV_DATA_GENERATOR,
    generator,
});

export const setEngineDEAGenerator = generator => ({
    type: SET_ENGINE_DEA_GENERATOR,
    generator,
});