import React, { Component } from "react";
import { CardBox } from "../components/Card/CardBox";

class Gateway extends Component {

    render() {
        return (
            <div className='page-not-found'>
                <div className="container fluid">
                    <CardBox>
                        <h3>Gateway</h3>
                        <div className='main'>This is the gateway page</div>
                    </CardBox>
                </div>
            </div>
        );
    }
}

export default Gateway;