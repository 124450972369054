import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";

const CourseCode = ({code}) => (
    <div className='line'>
        <div>{lang("lobby.code")}</div>
        <div>{code}</div>
    </div>
);

export default CourseCode;