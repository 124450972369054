import React from "react";

export const DefaultHeader = () => {
    const style = {
        padding: "0",
        borderTop: "none",
    };
    return (
        <div className="bar" style={style}/>
    );
};