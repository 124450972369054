import {lang} from "../../../../../../assets/js/language-utils.js";
import {Button} from "../../../../../../components/Button/Button.js";
import React from "react";
import {connect} from "react-redux";
import {setModal} from "../../../../../../store/actions/ui.js";

const RoundTitle = props => {
    const {name, index, selectedCourse, setModal} = props;
    return (<div className='line sum'>
        <div>{lang("lobby.round")}{index} - {name}</div>
        {selectedCourse.isOwner ? (
            <div>
                <Button
                    className='primary small'
                    title={lang("round.updateTitle")}
                    icon='fas fa-edit'
                    onClick={() => setModal("editRound", {
                        course: selectedCourse,
                        round: props,
                    })} />
                {props.index > 1 ? (
                    <Button
                        className='danger small'
                        title={lang("round.deleteRound")}
                        icon='far fa-trash-alt'
                        onClick={() => setModal("deleteRound", {
                            course: selectedCourse,
                            round: props,
                        })} />
                ) : null}
            </div>
        ) : null}
    </div>);
};


const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
});

export default connect(null, mapDispatchToProps)(RoundTitle);