import React from "react";

import "./Loader.css";

export const Loader = ({showProgress, progress, center}) => {
    return (
        <div className={`spinner-container ${center ? "center" : ""}`}>
            <div className="spinner-loader">
                <div className="blue">
                </div>
                <div className="white">
                </div>
                <div className="orange">
                </div>
                {showProgress ? (
                    <div className="progress-bar">
                        <div className="filler" style={{width: Math.round(progress * 100) + "%"}} />
                    </div>
                ) : null}
            </div>
        </div>
    );
    
};