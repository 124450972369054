import React, {useState} from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import "./Form.css";
import "./Slider.css";
import "flatpickr/dist/themes/material_blue.css";

import moment from "moment";
import {InputNumber} from "./InputNumber";
import Flatpickr from "react-flatpickr";

export const InputText = props => {

    return (
        <div className={"field"}>
            <label>{props.label}</label>
            <input
                style={{color: props.disabled ? "darkgrey" : ""}}
                type={props.type}
                name={props.name}
                autoFocus={props.focus}
                placeholder={props.placeholder}
                maxLength={props.maxLength || 30}
                required={props.required}
                onChange={props.onChange}
                value={props.value}
                disabled={props.disabled}
                className={props.className}
            />
            <small>{props.desc}</small>
        </div>
    );
};

InputText.propTypes = {
    type: PropTypes.oneOf(["email", "password", "text"]).isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

export const InputBox = props => {

    const className = "checkbox " + (props.type === "radio" ? "radio" : null);

    return (
        <div className={props.className ? "field " + props.className : "field"}>
            <div className={className}>
                <input
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    onChange={props.onChange}
                    checked={props.checked}
                />
                <label htmlFor={props.id}>{props.label}</label>
            </div>
        </div>
    );
};

export const InputSelect = ({className, label, id, name, required, placeholder, selected, onChange, options}) => {

    return (
        <div className={className ? "field " + className : "field"}>
            {label ? <label htmlFor={id}>{label}</label> : null}
            <div className='select'>
                <select
                    name={name}
                    id={id}
                    required={required}
                    value={selected}
                    onChange={onChange}>
                    <option value='' disabled hidden>{placeholder}</option>
                    {options.map((option, index) => {
                        return (
                            <option
                                key={index}
                                value={option.value || option.id || index}
                            > {option.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export const InputCheckbox = ({name, decType, item, category, path, onChange, value}) => {

    let [val, setVal] = useState(value);
    return (
        <input
            className='decision'
            type='checkbox'
            name={name}
            data-dec-type={decType}
            data-item={item}
            data-category={category}
            data-path={path}
            onChange={() => setVal(!val)}
            onClick={e => onChange({...e, target: {dataset: {...e.target.dataset}, value: +(!val)}})}
            checked={val}
        />
    );
};

InputNumber.defaultProps = {
    maxLength: 8,
    step: 1,
    min: 0,
};

InputNumber.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export const InputTextArea = props => {

    return (
        <div className={"field"}>
            <label>{props.label}</label>
            <textarea
                name={props.name}
                placeholder={props.placeholder}
                rows={props.rows || 5}
                maxLength={props.maxLength || 30}
                required={props.required}
                onChange={props.onChange}
                value={props.value}
            />
        </div>
    );
};

export const InputRange = props => {

    const Range = Slider.Range;

    return (
        <div className={"field"}>
            <label>{props.label}</label>
            <Range
                min={props.min}
                max={props.max}
                dots={props.dots}
                value={props.value}
                onChange={props.onChange}
            />
            <div className='line'>
                <small>
                    {props.minText}{props.value[0]}
                </small>
                <small>
                    {props.maxText}{props.value[1]} </small>
            </div>
            <small>{props.desc}</small>
        </div>
    );
};

export const InputDate = ({selected, onChange, label, desc}) => {
    const [date, setDate] = useState((selected ? moment(selected) : moment()).toDate());

    return (
        <div className="field">
            <label>{label}</label>
            <Flatpickr
                value={date}
                options={{
                    time_24hr: true,
                    minuteIncrement: 1,
                    enableTime: true,
                }}
                onChange={([date]) => {
                    setDate(date);
                }}
                onClose={([date]) => onChange(moment(date))} />
            <small>{desc}</small>
        </div>
    );
};
