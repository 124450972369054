import React from "react";

// import './Error.CommonSkiResort'

export const Error = props => {

    const style = {
        fontSize: '3rem',
        fontWeight: '700',
        textAlign: 'center',
        margin: '10% 0'
    }

    return (
        <div className='error'>
            <div className='jumbotron' style={style}>
                <i className='far fa-frown fa-2x'/>
                <div className='content'>{props.text}</div>
                <small>{props.small}</small>
            </div>
        </div>
    )

}