import {isEmptyArr} from "../../assets/js/utility";

/* Takes a decision setup as an input and returns a complete decision object
   filled with the default values. Default value should be marked in the setup. */
export const getDefaultDecision = (obj) => {

    return Object.keys(obj).reduce((newObj, key) => {
        return (typeof obj[key] === 'object' && !obj[key].hasOwnProperty('default'))
        ? { ...newObj, [key]: getDefaultDecision(obj[key]) }
        : { ...newObj, [key]: obj[key]['default'] }
    }, {})

}

export const completeTeamsDecisions = (teams, defaultDecision, currentPeriod) => {

    const updateTeamDecision = (team, decison, period) => {

        return ({
            ...team,
            decisions: {
                ...team.decisions,
                [period]: decison
            }
        })
    }

    const updatedTeams = [ ...teams ]
    const numbOfTeams = updatedTeams.length

    for (let per = 0; per <= currentPeriod; per++) {
    
       for (let team = 0; team < numbOfTeams; team++) {

            if (per === 0) {
                updatedTeams[team] = updateTeamDecision(updatedTeams[team], defaultDecision, per)
                continue
            }
            
            if (isEmptyArr(updatedTeams[team].decisions[per])) {
                updatedTeams[team] = updateTeamDecision(updatedTeams[team], updatedTeams[team].decisions[per - 1], per)
            }

        }
    
    }
    
    return updatedTeams
}