import "requestidlecallback-polyfill";

export function handleLongTask<T>(func: Generator<void, T, boolean>, total: number, progressCallback: (progress: number) => void) {
    let i = 0;
    return new Promise((resolve) => {
        const runNext = () => {
            const next = func.next();
            if (next.done) {
                resolve(next.value);
                return;
            }
            i++;
            progressCallback(i / total);
            // @ts-ignore
            window.requestIdleCallback(runNext);
        };
        runNext();
    });
}
