import React from "react";
import {connect} from "react-redux";
import {setModal} from "../../store/actions/ui";

import "./Modal.css";

const Modal = props => {

    return (
        <div className = { props.className ? 'modal active ' + props.className : 'modal active' }>
            <div className='dimmer'>
                <div className='window'>
                    { props.title ? (
                        <div className='header'>
                            <div className='title'>{ props.title }</div>
                            <div className='close-icon' onClick = { () => props.setModal(false) } >
                                <i className='fas fa-times'></i>
                            </div>
                        </div>
                    ) : null }
                    { props.children }
                </div>
            </div>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal))
    }
}

export default connect(null, mapDispatchToProps)(Modal)