import {Button} from "../../../Button/Button";
import {lang} from "../../../../assets/js/language-utils";
import React from "react";
import {connect} from "react-redux";

const SaveButton = ({saveState, onSave, disabled}) => {
    switch (saveState) {
        case true:
            return <Button className='success buckle' text={lang("pageHeader.save")} icon='fas fa-check'
                           onClick={onSave} disabled={disabled}/>;
        case "saving":
            return <Button className='primary buckle' icon='fas fa-spinner' text={lang("pageHeader.saving")}
                           onClick={onSave} disabled={disabled}/>;
        default:
            return <Button className='primary buckle' text={lang("pageHeader.save")} icon='far fa-save'
                           onClick={onSave} disabled={disabled}/>;
    }
};

const mapStateToProps = state => {
    return {
        saveState: state.simulation.saveState,
    };
};

export default connect(mapStateToProps, null)(SaveButton);