import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import Box from "../components/Box/Box";
import {apiv3} from "../axios";
import { lang } from "../assets/js/language-utils";

export default function User(props) {
    const { id } = props.match.params;
    const [userData, setUserData] = useState({
        id: "",
        name: "",
        email: "",
        language: "",
        lastLogin: "",
        selectedCourse: "",
        registered: false,
        courses: [],
        licences: [],
    });

    useEffect(() => {
        getUser();
    }, [id]);

    const getUser = () => {
        const uri = `admin/user/${id}`
        apiv3
            .get(uri)
            .then((res) => {
                if (res.data.isValid) {
                    setUserData({ ...res.data.data });
                } else {
                    console.error(res.data.msg);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const { name, email, language, lastLogin, selectedCourse, registered, courses, licences } = userData;

    return (
        <div className="admin">
            <div className="container fluid">
                <Box>
                    <h3>{name}</h3>
                    <div>{email}</div>
                    <div className="mt-1">{lang("admin.user.lastLogin")} {lastLogin}</div>
                    <div>{lang("admin.user.selectedCourse")} {selectedCourse}</div>
                    <div>{lang("admin.user.language")} {language}</div>
                    <div>{registered ? lang("admin.user.registered") : lang("admin.user.notRegistered")}</div>
                    <div className="row my-2">
                        <div className="col-md-6">
                            <h4 className="mb-1">{lang("admin.user.licences")}</h4>
                            <LicenceList licences={licences} />
                        </div>
                        <div className="col-md-6">
                            <h4 className="mb-1">{lang("admin.user.courses")}</h4>
                            <CourseList courses={courses} />
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    );
}

const LicenceList = ({ licences }) => (
    <ul>
        {licences.map((licence) => (
            <Route
                key={licence.id}
                render={({ history }) => (
                    <li>
                        <div
                            onClick={() => {
                                history.push("/admin/licences/" + licence.id);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {licence.name} <small>{licence.expire}</small>
                        </div>
                    </li>
                )}
            />
        ))}
    </ul>
);

const CourseList = ({ courses }) => (
    <ul>
        {courses.map((course) => (
            <Route
                key={course.id}
                render={({ history }) => (
                    <li>
                        <div
                            onClick={() => {
                                history.push("/admin/courses/" + course.id);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {course.name}
                        </div>
                    </li>
                )}
            />
        ))}
    </ul>
);