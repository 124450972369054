import {lang} from "../../../../../assets/js/language-utils.js";
import Team from "../../../../../components/Lobby/Team.js";
import React from "react";
import {connect} from "react-redux";

const Pool = ({selectedCourse, round, name, maxTeam, teams, id, roundID}) => (
    <div className='pool mb-1'>
        <div className='sum line'>
            <div>{lang("lobby.poolPre")}{name}{lang("lobby.poolSuf")}</div>
            <small>{lang("lobby.maxTeams")} {maxTeam}</small>
        </div>
        {teams.map(team => {
            return <Team
                key={team.id}
                id={team.id}
                name={team.name}
                move={selectedCourse.isOwner}
                pools={round.pools}
                courseID={selectedCourse.courseId}
                roundID={roundID}
                poolID={id} />;
        })}
    </div>
);

const mapStateToProps = state => ({
    selected: state.user.selected,
});

export default connect(mapStateToProps, null)(Pool);
