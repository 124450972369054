import {FullSimulationObjectBase, ItemInterface, SimulationObjectBase, Team, Values} from "./commonTypes";
import memoize from "fast-memoize";
import {doesLangExist, simLang} from "../../assets/js/language-utils";
import {sl} from "../../views/Simulation";
import {find, get, truncate} from "lodash";
import {getPeriodValue} from "./util";


type ItemlessSimFunction<SimObject> = (o: SimObject, period: number) => number;
type SimFunction<SimObject, Item> = ((o: SimObject, period: number, item: Item) => number);

export function getValue<T>(values: Values<T>, period: number) {
    if (period >= values.values.length)
        period = values.values.length - 1;
    if (period < 0)
        period = 0;
    return values.values[period];
}

export function getAllTeamValues<SimObject extends SimulationObjectBase<any, any, any>, Item>(o: SimObject, period: number, func: ItemlessSimFunction<SimObject>): number[];
export function getAllTeamValues<SimObject extends SimulationObjectBase<any, any, any>, Item>(o: SimObject, period: number, func: SimFunction<SimObject, Item>, item: Item): number[];

export function getAllTeamValues<SimObject extends SimulationObjectBase<any, any, any>, Item>(o: SimObject, period: number, func: SimFunction<SimObject, Item>, item?: Item) {
    /*console.log(period)*/
    const startTeamIndex = o.teamIndex;
    /*console.log("startTeamIndex", startTeamIndex, o.teamIndex)*/
    const result = o.teams.map((team, i) => {
        o.teamID = team.id;
        o.teamIndex = i;
        if (item) {
            return func(o, period, item);
        } else {
            return (func as ItemlessSimFunction<SimObject>)(o, period);
        }
    });
    o.teamIndex = startTeamIndex;
    o.teamID = o.teams[startTeamIndex].id;

    return result;
}

export function getAllItemValues<SimObject extends SimulationObjectBase<any, any, any>, Item>(o: SimObject, period: number, itemEnum: ItemInterface, func: SimFunction<SimObject, Item>) {
    return Object.values(itemEnum).map(item => func(o, period, item));
}

export const getAllDecisions = ({ path, period, teams }: { path: string, period: number, teams: any[] }): number[] => {
    return teams.map(team => Number(get(getValue(team.decisions, period), path)));
};

function sizedCache(size: number) {
    return {
        create() {
            const track: (string | number)[] = new Array(size);
            const store: { [key: string]: any } = {};
            let idx = 0;
            return {
                has(key: string | number): boolean {
                    return key in store;
                },
                get(key: string | number): any {
                    return store[key];
                },
                set(key: string | number, value: any): void {
                    if (track[idx]) delete store[track[idx]];

                    track[idx] = key;
                    idx = (idx + 1) % size;
                    store[key] = value;
                },
            };
        },
    };
}

export function simmemitem<SimObject extends SimulationObjectBase<any, any, any>, Item>(func: SimFunction<SimObject, Item>): SimFunction<SimObject, Item> {
    return memoize(func, {
        // @ts-ignore
        cache: sizedCache(16384),
        serializer: function () {
            let args = {...arguments[0][0]};
            args.period = arguments[0][1];
            args.item = arguments[0][2];

            if (typeof args.decisionSetup === "undefined" || typeof args.market === "undefined") {
                throw new Error("Decision setup or market is missing!");
            }

            args.decisionSetup = null;
            args.market = null;

            if (args.period < args.selectedPeriod) {
                args.teams = null;
            }

            return JSON.stringify(args);
        },
    });
}

export function simmem<SimObject extends SimulationObjectBase<any, any, any>>(func: ItemlessSimFunction<SimObject>): ItemlessSimFunction<SimObject> {
    return memoize(func, {
        // @ts-ignore
        cache: sizedCache(128),
        serializer: function () {
            let args = {...arguments[0][0]};
            args.period = arguments[0][1];

            if (typeof args.decisionSetup === "undefined" || typeof args.market === "undefined") {
                throw new Error("Decision setup or market is missing!");
            }

            args.decisionSetup = null;
            args.market = null;

            if (args.period < args.selectedPeriod) {
                args.teams = null;
            }

            return JSON.stringify(args);
        },
    });
}

export function createConditionalValueField<Category, Market, Decisions, Item>(condition: boolean, func: SimFunction<SimulationObjectBase<Category, Market, Decisions>, Item>, path: string, percentage = false, decimal = 0, sum = false) {
    if (!condition)
        return null;
    return {
        type: sum ? "sum" : null,
        field: {
            show: simLang(path),
            value: (o: FullSimulationObjectBase<Category, Market, Decisions, Item>) => func(o, o.period, o.item) * (percentage ? 100 : 1),
            decimal,
            tip: doesLangExist(path + "Desc") ? simLang(path + "Desc") : null,
        },
    };
}

export function createValueField<Category, Market, Decisions, Item>(func: SimFunction<SimulationObjectBase<Category, Market, Decisions>, Item>, path: string, percentage = false, decimal = 0, sum = false, item?: Item, underline = false, rank = false) {
    return {
        type: sum ? "sum" : underline ? "underline" : rank ? "rank" : null,
        field: {
            show: simLang(path),
            value: (o: FullSimulationObjectBase<Category, Market, Decisions, Item>) => func(o, o.period, item ?? o.item) * (percentage ? 100 : 1),
            decimal,
            tip: doesLangExist(path + "Desc") ? simLang(path + "Desc") : null,
        },
    };
}

export function createConditionalDecisionField(condition: boolean, path: string) {
    if (!condition)
        return null;
    return {field: {type: "decision", path}};
}

export function createDecisionField(path: string, limit?: (o: any) => ({min?: number, max?: number})) {
    return {field: {type: "decision", path, limit}};
}

export function createDescField(path: string) {
    return {type: "desc", field: {show: simLang(path)}};
}

export function createSalesField<Category, Market, Decisions, Item>(func: SimFunction<SimulationObjectBase<Category, Market, Decisions>, Item>, path: string) {
    return {
        field: {
            type: "sales",
            path,
            value: (o: FullSimulationObjectBase<Category, Market, Decisions, Item>) => func(o, o.period, o.item),
            decimal: 0,
        },
    };
}

export function createItems(itemType: ItemInterface, category: string) {
    return Object.values(itemType).map(item => {
        if (typeof sl === "undefined") {
            throw new Error("This language is not supported!");
        }
        // @ts-ignore
        const itemLang = sl[category][item];
        if (typeof itemLang === "undefined") {
            throw new Error(`${item} is not found in simulation language!`);
        }
        const result = {
            id: item,
            ...itemLang,
        };
        if (!result.name)
            result.name = itemLang.title;

        return result;
    });
}

export function getDecision<Category, Market, Decisions>(o: SimulationObjectBase<Category, Market, Decisions>, period: number, path: string): number {
    const selectedTeam = o.teamID === null ? o.teams[o.teamIndex] : find(o.teams, (team: Team<Decisions>) => team.id === o.teamID);

    if (!selectedTeam)
        return 0;

    let val = get(selectedTeam.decisions[Math.max(period, 0)], path);

    if (val === "true" || val === "false")
        return +(val === "true");

    if (typeof val === "undefined" && period > 0)
        return getDecision(o, period - 1, path);

    return Number(val || 0);
}

export function setStorage(key: string, value: string) {
    window.localStorage.setItem(key, value);
}

export function getStorageWithDefault(key: string, defaultValue: string, mustContain?: string[]): string {
    const storedValue = window.localStorage.getItem(key);

    if (storedValue === null || (mustContain !== null && mustContain?.indexOf(storedValue) === -1)) {
        window.localStorage.setItem(key, defaultValue);
        return defaultValue;
    }

    return storedValue;
}

export function setValidatedStorage(key: string, value: string, validationID: string) {
    window.localStorage.setItem(key, value + "|" + validationID);
}

export function getValidatedStorage(key: string, defaultValue: string, validationID: string): string {
    const storedValue = window.localStorage[key];
    if (storedValue === null || typeof storedValue === "undefined") {
        setValidatedStorage(key, defaultValue, validationID);
        return defaultValue;
    }

    const parts = storedValue.split("|");
    const storedValidationID = parts[parts.length - 1];
    if (storedValidationID !== validationID) {
        setValidatedStorage(key, defaultValue, validationID);
        return defaultValue;
    }

    return storedValue.split("|").slice(0, -1).join("|");
}

export const getTotalProjectEffect = (o: any, period: number, effectKey: string, add = false, startZero = false): number => {
    if (period === 0)
        return add ? (startZero ? 0 : 1) : 1;
    const team = o.teams.find((team: any) => team.id === o.teamID);
    if (typeof team === "undefined")
        return add ? (startZero ? 0 : 1) : 1;
    const decisions = getPeriodValue(team.decisions, period);
    if (typeof decisions === "undefined") {
        return add ? (startZero ? 0 : 1) : 1;
    }

    let projDecisions = decisions.projects;
    if (typeof projDecisions === "undefined") {
        projDecisions = getPeriodValue(team.decisions, period - 1);
    }
    // @ts-ignore
    return Object.keys(o.market.projects).reduce((acc: number, project: string) => {
        // @ts-ignore
        const repeatable = o.market.projects[project].repeatable ?? false;
        if (typeof projDecisions === "undefined") {
            return 0;
        }
        if ((period !== 0 && repeatable && +projDecisions[project] === period) || (!repeatable && +projDecisions[project] === 1)) {
            // @ts-ignore
            const effects = o.market.projects[project].effects;
            if (add) {
                acc += (effects[effectKey] ?? 0);
            } else {
                acc *= 1 + (effects[effectKey] ?? 0);
            }
        }

        return acc;
    }, startZero ? 0 : 1);
};

export const getProjectInvestmentEffect = (o: any, period: number, projectName: string, weight: number): number => {
    // get project by projectName
    const project = o.market.projects[projectName];
    if (typeof project === "undefined")
        return 0;

    //if project is running in current period, return project invest

    if(o.teamIndex===0){

    }

    if (getStartedProjects(o, period).includes(projectName) && o.market.version==="scc-challenge-semifinal" || "scc-challenge-final") {
        return project.finances.invest * weight;
    }
    return 0
}

export const getRunningProjects = (o: any, period: number): string[] => {
    const team = o.teams.find((team: any) => team.id === o.teamID);
    if (typeof team === "undefined")
        return [];
    const decisions = getPeriodValue(team.decisions, period);
    if (typeof decisions === "undefined") {
        return [];
    }

    let projDecisions = decisions.projects;
    if (typeof projDecisions === "undefined") {
        projDecisions = getPeriodValue(team.decisions, period - 1);
    }
    // @ts-ignore
    return Object.keys(o.market.projects).filter((project: string) => {
        // @ts-ignore
        const repeatable = o.market.projects[project].repeatable ?? false;
        if (typeof projDecisions === "undefined") {
            return 0;
        }
        if (repeatable) {
            return +projDecisions[project] === period && +projDecisions[project] > 0;
        }
        return +projDecisions[project] === 1;
    });
};

export const getStartedProjects = (o: any, period: number): string[] => {
    const currRunning = getRunningProjects(o, period);
    if (period === 0)
        return currRunning;
    const lastRunning = getRunningProjects(o, period - 1);
    return currRunning.filter((proj: string) => {
        return !lastRunning.includes(proj) || o.market.projects[proj].repeatable;
    });
};

export function teamPieChartGenerator<SimObject extends SimulationObjectBase<any, any, any>, Decision>(func: ItemlessSimFunction<SimObject>, path: string, teams: Team<Decision>[], itemName: string = "") {
    return {
        title: simLang(path) + (itemName.length > 0 ? ` - ${itemName}` : ""),
        head: teams.map(team => team.name),
        body: teams.map((team, i) => ({value: (o: any) => func({...o, teamID: team.id, teamIndex: i}, o.period)})),
    };
}

export function productChartGenerator<SimObject extends SimulationObjectBase<any, any, any>, Item>(func: SimFunction<SimObject, Item>, path: string, items: any[], selectedPeriod: number, category: string, selectedTeamIndex: number | null = null) {
    return {
        title: simLang(path),
        body: items.map(item => ({
            field: {
                show: item.name,
                value: (o: any) => func({
                    ...o,
                    category: category ?? item.category ?? o.category,
                    teamID: (o.teams[selectedTeamIndex ?? o.teamIndex] ?? {}).id ?? null,
                    teamIndex: selectedTeamIndex ?? o.teamIndex ?? 0,
                }, o.period, item.id),
            },
        })),
        plusPeriod: selectedPeriod - 2,
        periodDiff: 1 - selectedPeriod,
    };
}

export function convertToDisplayPercentage<SimObject extends SimulationObjectBase<any, any, any>, Item>(func: SimFunction<SimObject, Item>) {
    return (o: SimObject, period: number, item: Item) => func(o, period, item) * 100;
}

export function createChartFromFields<SimObject>(title: string, rows: { field: { show: string, value: SimFunction<SimObject, ItemlessSimFunction<SimObject>> } }[], category: string = "", item: string = "", bar: boolean = false) {
    return {
        title,
        head: bar ? null : rows.map(row => row.field.show),
        body: rows,
        category, item
    };
}

export const combinedReportFactoryField = (selectedPeriod: number, teams: any, field: { show: string, value: (...params: any) => number, decimal?: number }, category: string = "", item: string = "") => {
    return combinedReportFactory(selectedPeriod, teams, field.show, field.value, category, item);
};

export const combinedReportFactoryPath = (selectedPeriod: number, teams: any, path: string, func: (...params: any) => number, category: string = "", item: string = "") => {
    return combinedReportFactory(selectedPeriod, teams, simLang(path), func, category, item);
};

export function combinedReportFactory<SimObject, Item, Dec>(selectedPeriod: number, teams: any, title: string, func: (...params: any) => number, category: string = "", item: string = "") {
    return {
        body: teams.map((t: any) => ({
            field: {
                show: truncate(t.name, {length: 10}),
                value: (o: any) => func(o, o.period, o.item),
            },
        })),
        plusPeriod: selectedPeriod - 2,
        periodDiff: 1 - selectedPeriod,
        title, category, item,
    };
}

export function reportFactory(selectedPeriod: number, path: string, func: (...params: any) => number, category?: string, item?: string) {
    return {
        body: [
            {
                field: {
                    show: "",
                    value: (o:any) => func(o, o.period, o.item)
                }
            }
        ],
        plusPeriod: selectedPeriod - 2,
        periodDiff: 1 - selectedPeriod,
        title: simLang(path),
        category, item,
    };
}
