// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";

class JoinCourse extends Component {

    state = {
        courseKey: '',
        isFetching: false,
        testHash: testHash
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.courseKey.length < 6) {
            this.setState({
                msg: {
                    type: 'error',
                    text: lang('courses.keyMin')
                }
            })
        } else {
            this.joinCourse()
        }
    }

    joinCourse = () => {
        this.setState({isFetching: true})
        const data = qs.stringify(this.state)
        apiv3.post("/user/course/join", data)
        .then(res => {
            this.setState({isFetching: false})
            if (res.data.isValid) {
                this.joinCourseSuccess(res.data.data.id)
            } else {
                this.joinCourseFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.joinCourseFailed(err)
        })
    }

    joinCourseSuccess = () => {
        this.props.setModal(false)
        this.props.fetchUserData()
        this.props.history.push('/lobby')
        this.props.createAlert({
            type: 'success',
            msg: lang('courses.joinSuccess')
        })
    }

    joinCourseFailed = (err) => {
        this.setState({
            msg: {
                type: 'error',
                text: err
            }
        })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('courses.joinProcess')}>
                    <Loader />
                </Modal>
            )
        }

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        return (
            <Modal title={lang('courses.joinTitle')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.joinCourse) }>
                    <div className="body">
 
                         <InputText
                            label =         {lang('courses.key')}
                            type =          'text'
                            name =          'courseKey'
                            placeholder =   {lang('courses.keyPlaceholder')}
                            value =         { this.state.courseKey }
                            onChange =      { this.handleInputChange }
                            maxLength =     {8}
                            focus
                        />

                        <small>{lang('courses.joinTip')}</small>
                        <FormMessage />
                    </div>
                    <div className="footer">
                        <Button className='primary' text={lang('courses.join')} type='submit' onClick = { (e) => this.handleSubmit(e, this.joinCourse) }/>
                        <Button className='danger' text={lang('courses.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        fetchUserData: () => dispatch(fetchUserData()),
        createAlert: alert => dispatch(createAlert(alert))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(JoinCourse))