import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteAlert} from "../../store/actions/ui";
import {Alert} from "./Alert";
import "./Alert.css";

class AlertDeck extends Component {
    render() {

        if (!this.props.alerts.length) return null

        return (
            <div className="alert-deck">
                { this.props.alerts.map((alert, index) => (
                    <Alert
                        key = { index }
                        type = { alert.type }
                        msg = { alert.msg }
                        onClick = { () => this.props.deleteAlert(index) }
                    />
                )) }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        alerts: state.ui.alerts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteAlert: index => dispatch(deleteAlert(index))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDeck)