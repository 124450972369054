// External
import React, {Component} from "react";
import {connect} from "react-redux";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {deleteRound} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";

class DeleteRound extends Component {

    state = {
        isFetching: false
    }

    deleteRound = () => {
        this.setState({ isFetching: true })
        const data = qs.stringify({
            roundID: this.props.round.id,
            courseID: this.props.course.courseId
        })
        apiv3.delete(`/course/${this.props.course.courseId}/round/${this.props.round.id}`, data)
        .then(res => {
            if (res.data.isValid) {
                this.deleteRoundSuccess(res.data.msg)
            } else {
                this.deleteRoundFailed(res.data.msg)
            }
        })
        .catch(err => { this.deleteRoundFailed(err) })
    }

    deleteRoundSuccess = (msg) => {
        this.setState({ isFetching: false })
        this.props.setModal(false)
        this.props.createAlert({
            type: 'success',
            msg: msg
        })
        this.props.deleteRound(this.props.round.id, this.props.course.courseId)
        window.location.reload()
    }

    deleteRoundFailed = (err) => {
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
        this.setState({ isFetching: false })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('round.deleteProcess')}>
                    <Loader />
                </Modal>
            )
        }

        const Body = () => (
            <div className="body">
                <div className="main mb-1">
                    <p>{lang('round.deleteBody')}</p>
                    <b>{ this.props.round.name }</b>
                </div>
                <small>{lang('round.deleteTip')}</small>
            </div>
        )
        const Footer = () => (
            <div className="footer">
                <Button className='danger' text={lang('round.yes')} onClick = { () => this.deleteRound(this.props.round.id) } />
                <Button text={lang('round.cancel')} onClick = { () => this.props.setModal(false) } />
            </div>
        )

        return (
            <Modal title={lang('round.confirm')} className = 'small'>
                 { this.state.isFetching ? (
                    <Loader />
                ) : (
                    <div>
                        <Body />
                        <Footer />
                    </div>
                ) }
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        course: state.ui.modal.data.course,
        round: state.ui.modal.data.round
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        deleteRound: (roundID, courseID) => dispatch(deleteRound(roundID, courseID)),
        createAlert: alert => dispatch(createAlert(alert))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRound)