import React, {Component} from "react";
import {connect} from "react-redux";
import {apiv3, testHash} from "../../axios";
import qs from "qs";
import {createAlert} from "../../store/actions/ui";
import {fetchUserData} from "../../store/actions/user";
import {Button} from "../Button/Button";
import {lang} from "../../assets/js/language-utils";

class Invitation extends Component {

    acceptInvite = (id) => {
        const data = qs.stringify({testHash});
        apiv3.put(`/course/${this.props.courseID}/invite/${id}`, data)
        .then(res => {
            if (res.data.isValid) {
                this.acceptInviteSuccess(res.data)
            } else {
                this.inviteFailed(res.data)
            }
        })
    }

    deleteInvite = (id) => {
        apiv3.delete(`/course/${this.props.courseID}/invite/${id}`)
        .then(res => {
            if (res.data.isValid) {
                this.deleteInviteSuccess(res.data)
            } else {
                this.inviteFailed(res.data)
            }
        })
    }

    acceptInviteSuccess = (data) => {
        this.props.createAlert({
            type: 'success',
            msg: data.msg
        })
        this.props.fetchUserData()
    }

    deleteInviteSuccess = (data) => {
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: data.msg
        })
    }

    inviteFailed = (data) => {
        this.props.createAlert({
            type: 'danger',
            msg: data.msg
        })
    }

    render() {
        return (
            <div className='invitation'>
                { this.props.type === 'got' ? (
                    <div className='line'>
                        <div>{this.props.fromUserName} {lang('invitation.invitedTo')} {this.props.fromTeamName}</div>
                        <div style={{minWidth: '80px', textAlign: 'right'}}>
                            <Button icon = 'fas fa-check' className='primary small' onClick = { () => this.acceptInvite(this.props.id) } />
                            <Button icon = 'fas fa-times' className='danger small' onClick = { () => this.deleteInvite(this.props.id) } />
                        </div>
                    </div>
                ) : (
                    <div className='line'>
                        <div>{lang('invitation.sent')} {this.props.inviteEmail}</div>
                        <div style={{minWidth: '80px', textAlign: 'right'}}>
                            <Button icon = 'fas fa-times' className='danger small' onClick = { () => this.deleteInvite(this.props.id) } />
                        </div>
                    </div>
                ) }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
        simulations: state.user.simulations
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createAlert: alert => dispatch(createAlert(alert)),
        fetchUserData: () => dispatch(fetchUserData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invitation)