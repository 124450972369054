/**
 * @file The entering point.
 */
// External
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
// Main components
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
// Redux
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import reducer from "./store/reducers";
import thunk from "redux-thunk";
// Import main CSS
import "./assets/css/0-reset.css";
import "./assets/css/1-grid.css";
import "./assets/css/4-main.css";
import "./assets/css/6-special.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/regular.css";

// Create the Redux store
const composeEnhancers = process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose
    
const store = createStore(reducer, composeEnhancers(
    applyMiddleware(thunk)
))

// Render the main App
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'))
registerServiceWorker()
