import {getCourseById} from "../../../../assets/js/utility.js";
import {lang} from "../../../../assets/js/language-utils.js";
import Box from "../../../../components/Box/Box";
import React from "react";
import Pool from "./Pool/Pool.js";
import {connect} from "react-redux";
import {Button} from "../../../../components/Button/Button";
import manageTeamsIcon from "./manage-teams.svg";
import {setModal} from "../../../../store/actions/ui";

const Pools = ({selectedCourse, selected, courses, setModal, roundID}) => {
    const course = getCourseById(selected.courseID, courses);
    const round = selectedCourse.rounds.find(round => round.id === roundID);

    let pools
    if (selected.courseID) {
        pools = round.pools;
    }

    const teamsInPools = (pools ?? []).reduce((prev, curr) => [...prev, ...curr.teams.map(team => team.id)], []);

    return (
        <Box
            title={[lang("lobby.pools")]}
            right={course.isOwner ? <Button
                className="primary small"
                key={3}
                text={lang("lobby.manageTeams")}
                title={lang("lobby.teamAssign")}
                icon={manageTeamsIcon}
                onClick={() => {
                    setModal("moveTeams", {course, round})
                }}/> : null}>

            {teamsInPools.length !== 0 ? round.pools.map(pool => {
                return <Pool
                    key={pool.id}
                    id={pool.id}
                    name={pool.name}
                    teamCount={pool.teams.length}
                    maxTeam={round.maxTeam}
                    teams={pool.teams}
                    setModal={setModal}
                    selectedCourse={selectedCourse}
                    round={round}
                    roundID={roundID}/>;
            }) : <div>{lang("lobby.noPools")}</div>}

        </Box>
    );
};

const mapStateToProps = state => ({
    courses: state.user.courses,
    selected: state.user.selected,
});

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pools);