// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../store/actions/ui";
import {initRequest} from "../../../store/actions/simulation";
import {fetchUserData, selectPool, selectRound} from "../../../store/actions/user";
// Components
import Modal from "../Modal";
import {Button} from "../../Button/Button";
import {InputSelect} from "../../Form/Inputs";
import {Loader} from "../../Loader/Loader";
import {getCourseById, getItemWithID} from "../../../assets/js/utility";
import {lang} from "../../../assets/js/language-utils";
import {memoizeCaches} from "../../../model/common/util.js";

class SelectMarket extends Component {

    state = {
        roundID: this.props.selected.roundID,
        poolID: this.props.selected.poolID,
        isFetching: false
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.selectMarket()
    }

    selectMarket = () => {
        memoizeCaches.forEach(store => store.clear());
        this.props.selectRound(this.state.roundID)
        this.props.selectPool(this.state.poolID)
        this.props.initRequest()
        this.props.setModal(false)
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('selectMarket.wait')}>
                    <Loader />
                </Modal>
            )
        }

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        const selectedCourse = getCourseById(this.props.selected.courseID, this.props.courses)
        const pools = getItemWithID(this.state.roundID, selectedCourse.rounds).pools

        return (
            <Modal title={lang('selectMarket.chooseView')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.selectMarket) }>
                    <div className="body">

                        <InputSelect
                            label =         {lang('selectMarket.round')}
                            name =          'roundID'
                            placeholder =   {lang('selectMarket.roundPlaceholder')}
                            options =       { selectedCourse.rounds }
                            selected =      { this.state.roundID }
                            onChange =      { this.handleInputChange }
                            required
                        />

                        { selectedCourse.isOwner ? (
                            <InputSelect
                                label =         {lang('selectMarket.pool')}
                                name =          'poolID'
                                placeholder =   {lang('selectMarket.poolPlaceholder')}
                                options =       { pools }
                                selected =      { this.state.poolID }
                                onChange =      { this.handleInputChange }
                                required
                            />
                        ) : null }

                        <small>{lang('selectMarket.tip')}</small>
                        <FormMessage />
                    </div>
                    <div className="footer">
                        <Button className='primary' text={lang('selectMarket.save')} type='submit'  onClick = { (e) => this.handleSubmit(e, this.createCourse) } />
                        <Button className='danger' text={lang('selectMarket.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
        selected: state.user.selected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        fetchUserData: () => dispatch(fetchUserData()),
        createAlert: alert => dispatch(createAlert(alert)),
        initRequest: () => dispatch(initRequest()),
        selectRound: id => dispatch(selectRound(id)),
        selectPool: id => dispatch(selectPool(id))

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectMarket))