import React, {Component} from "react";
import {connect} from "react-redux";
import {apiv3, testHash} from "../../axios";
import qs from "qs";

import {createAlert, setModal} from "../../store/actions/ui";

import {Dropdown} from "../Dropdown/Dropdown";
import {Button} from "../Button/Button";
import {Loader} from "../Loader/Loader";
import {lang} from "../../assets/js/language-utils";
import {fetchUserData} from "../../store/actions/user";

class User extends Component {
    
    state = {
        toggleDropdown: false,
        isFetching: false,
    };
    
    moveDropdownHandler = () => {
        this.setState(prevState => (
            {toggleDropdown: !prevState.toggleDropdown}
        ));
    };
    
    moveUser = (userID, teamID, courseID, roundID, name = "") => {
        this.setState({isFetching: true});
        const data = qs.stringify({
            "userID": userID,
            "teamID": teamID,
            "roundID": roundID,
            "type": teamID === null ? "new" : "move",
            "teamName": name,
            "testHash": testHash
        });
        apiv3.post(`/course/${courseID}/moveUser`, data)
            .then(res => {
                if (res.data.isValid) {
                    this.moveUserSuccess(res.data.msg);
                } else {
                    this.moveUserFailed(res.data.msg);
                }
            })
            .catch(err => {
                this.moveUserFailed(err);
            });
    };
    
    moveUserSuccess = (msg) => {
        this.setState({isFetching: false, toggleDropdown: false});
        this.props.fetchUserData();
        if (msg) {
            this.props.createAlert({
                type: "success",
                msg,
            });
        }
    };
    
    moveUserFailed = (err) => {
        this.setState({isFetching: false, toggleDropdown: false});
        this.props.createAlert({
            type: "danger",
            msg: err,
        });
    };
    
    deleteUserFromTeam = (userID, teamID, courseID) => {
        this.setState({isFetching: true});
        apiv3.delete(`/course/${courseID}/team/${teamID}/user/${userID}`)
            .then(res => {
                if (res.data.isValid) {
                    this.deleteUserSuccess(res.data.msg);
                } else {
                    this.deleteUserFailed(res.data.msg);
                }
            })
            .catch(err => {
                this.deleteUserFailed(err);
            });
    };
    
    deleteUserSuccess = (msg) => {
        this.setState({isFetching: false});
        this.props.fetchUserData();
        this.props.createAlert({
            type: "success",
            msg: msg,
        });
    };
    
    deleteUserFailed = (err) => {
        this.setState({isFetching: false});
        this.props.createAlert({
            type: "danger",
            msg: err,
        });
    };

    addTeacherPermission = (courseID, userID) => {
        this.setState({isFetching: true});
        const data = qs.stringify({testHash})
        apiv3.put(`/course/${courseID}/permission/${userID}`, data)
            .then(res => {
                if (res.data.isValid) {
                    this.deleteUserSuccess(res.data.msg);
                } else {
                    this.deleteUserFailed(res.data.msg);
                }
            })
            .catch(err => {
                this.deleteUserFailed(err);
            });
    }
    
    render() {
        
        const MoveDropDown = (props) => {
            const Content = (props) => this.state.isFetching ? (<Loader/>) : (
                [
                    ...props.list.map(team => {
                        return <li key={team.id}>
                            <span
                                onClick={() => this.moveUser(this.props.id, team.id, this.props.courseID, this.props.roundID)}>{team.name}</span>
                        </li>;
                    }),
                    <li key="99999">
                        <span
                            onClick={() => {
                                this.props.setModal("askinput", {
                                    title: lang("lobby.createTeamName"),
                                    confirm: name => {
                                        this.moveUser(this.props.id, null, this.props.courseID, this.props.roundID, name);
                                    },
                                });
                            }}>{lang("lobby.create")}</span>
                    </li>,
                ]
            );
            
            return (
                <Dropdown className='small'>
                    <Content list={props.list}/>
                </Dropdown>
            );
        };

        if (this.props.user.profile.licenceType === "admin" || !this.props.isAdmin) {
            return (
                <div className='line'>
                    <div dangerouslySetInnerHTML={{__html: this.props.name}}/>
                    <div>
                        {this.props.captain ? (
                            <Button className='banana small' icon='fas fa-crown'/>
                        ) : null}

                        {this.props.haveLicence ? (
                            <div className="relative d-inline">
                                <Button
                                    className='danger small mr-1' icon='fa-solid fa-screwdriver-wrench'
                                    onClick={() => this.addTeacherPermission(this.props.courseID, this.props.id)}/>
                            </div>
                        ) : null}

                        {this.props.move ? (
                            <div className="relative d-inline">
                                <Button
                                    title={lang("lobby.moveUserToTeam")}
                                    text={lang("user.move")}
                                    className='primary small'
                                    onClick={() => this.moveDropdownHandler()}/>
                                {this.state.toggleDropdown ?
                                    <MoveDropDown list={this.props.teams}/> : null}
                            </div>
                        ) : null}


                        {this.props.delete ? (
                            <Button className='danger small' icon='far fa-trash-alt'
                                    onClick={() => this.deleteUserFromTeam(this.props.id, this.props.teamID, this.props.courseID)}
                                    title={lang("user.deleteUser")}/>
                        ) : null}

                    </div>
                </div>
            );
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        course: state.user.course,
        courses: state.user.courses,
        simulations: state.user.simulations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createAlert: alert => dispatch(createAlert(alert)),
        fetchUserData: () => dispatch(fetchUserData()),
        setModal: (name, data) => dispatch(setModal(name, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);