export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_MODAL = 'SET_MODAL'
export const CREATE_ALERT = 'CREATE_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'
export const INIT_ACTIVE_ITEMS = 'INIT_ACTIVE_ITEMS'
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM'
export const RESET_ACTIVE_ITEMS = 'RESET_ACTIVE_ITEMS'
export const FETCH_FAILED = 'FETCH_FAILED'
export const FETCH_START = 'FETCH_START'
export const FETCH_END = 'FETCH_END'
export const CREATE_TOOLTIP = 'CREATE_TOOLTIP'
export const CLOSE_TOOLTIP = 'CLOSE_TOOLTIP'
export const toggleSidebar = () => {
    return {
        type: TOGGLE_SIDEBAR
    }
}

export const setModal = ( name, data ) => {
    return {
        type: SET_MODAL,
        name: name,
        data: data
    }
}

export const createAlert = ( alert ) => dispatch => {
    dispatch({
        type: CREATE_ALERT,
        alert: alert
    })
    setTimeout(() => {
        dispatch(deleteAlert(0))
    }, alert.time || 5000)
}

export const deleteAlert = ( index ) => {
    return {
        type: DELETE_ALERT,
        index: index
    }
}
export const initActiveItems = () => ({ type: INIT_ACTIVE_ITEMS })
export const setActiveItem = ( tab, item ) => {
    return {
        type: SET_ACTIVE_ITEM,
        tab: tab,
        item: item
    }
}

export const resetActiveItems = () => {
    return { type: RESET_ACTIVE_ITEMS }
}

export const fetchStart = () => {
    return {
        type: FETCH_START
    }
}

export const fetchEnd = () => {
    return {
        type: FETCH_END
    }
}

export const fetchFailed = ( error ) => {
    return {
        type: FETCH_FAILED
    }
}

export const createTooltip = ( tip, target ) => {

    return {
        type: CREATE_TOOLTIP,
        tip,
        target: target
    }
}

export const closeTooltip = () => {
    return {
        type: CLOSE_TOOLTIP
    }
}

