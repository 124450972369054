import XLSX from "xlsx";
import {apiv3} from "../../../axios";
import {completeTeamsDecisions, getDefaultDecision} from "../../../store/reducers/simulation-utility";
import {lang, simLang} from "../../../assets/js/language-utils";
import {Button} from "../../Button/Button";
import React from "react";
import {Divider} from "./Divider/Divider";
import Deadline from "./Deadline/Deadline";
import PeriodSelect from "./PeriodSelect/PeriodSelect";
import TeamNameSelect from "./TeamNameSelect/TeamNameSelect";
import RoundName from "./RoundName/RoundName";
import SelectButton from "./SelectButton/SelectButton";
import Pool from "./Pool/Pool";
import {setModal} from "../../../store/actions/ui";
import {connect} from "react-redux";
import SaveButton from "./SaveButton/SaveButton";
import {handleLongTask} from "../../../utils/tasks";
import "requestidlecallback-polyfill";
import {getCourseById} from "../../../assets/js/utility";

const SimulationHeader = ({
                              simulation,
                              simulations,
                              onSave,
                              csvGenerator,
                              courses,
                              dea,
                              deaGenerator,
                              deaLengthLimit,
                              selected,
                              setModal,
                              myTeam,
                          }) => {
    const {teams, setup} = simulation;
    const isNoTeamName = myTeam.id === null || teams.length === 0 || myTeam.poolCode === null;
    const myTeamName = isNoTeamName ? "" : teams.filter(team => team.id === myTeam.id)[0].name;
    
    let selectedSimulationID = selected.simulationID;
    let selectedSimulation = simulations.find(simulation => simulation.id === selectedSimulationID);
    let isEngine = selectedSimulation && selectedSimulation.name.indexOf("Engine") !== -1;

    const course = courses.find(course => course.courseId === selected.courseID);
    const round = course.rounds.find(round => round.id === selected.roundID);
    const pool = round.pools.find(pool => pool.id === selected.poolID);
    const isOwner = course.isOwner;

    const selectedCourse = getCourseById(selected.courseID, courses) || {}

    const resultDeadline = setup.deadline.result
    const currentPeriod = setup.period.current
    const selectedPeriod = setup.selected.period
    const teamID = setup.selected.teamID

    let teamNotInPool = false
    const team = teams.find(team => team.id === teamID)
    if (team !== undefined && team.id === null) {
        teamNotInPool = true
    }

    const isNotInPool =
        !selectedCourse.isOwner
        && teamNotInPool

    const isBreak =
        !selectedCourse.isOwner
        && resultDeadline
        && currentPeriod === selectedPeriod
    
    const generateFile = () => {
        setModal("loader", {center: true});
        window.requestIdleCallback(() => {
            if (csvGenerator) {
                let title = `${course.courseName}_${myTeamName}_${round.name}_${pool.name}`;
                let wb = XLSX.utils.book_new();
                wb.props = {
                    Title: title,
                    Subject: "Simulation",
                    CreatedDate: new Date(),
                };
                for (let period = 0; period < simulation.setup.period.current; period++) {
                    wb.SheetNames.push(period + "");
                    
                    const simulationObject = {
                        ...simulation,
                        decisionSetup: simulation.setup.decisions,
                        selectedPeriod: simulation.setup.selected.period,
                        period,
                        teamID: simulation.setup.selected.teamID,
                        teamIndex: simulation.setup.selected.teamIndex,
                    };
                    let csv = csvGenerator(simulationObject);
                    let data = csv.split("\n").map(line => {
                        let data = line.split(";").map(x => x.match(/^\d+\.?\d*$/g) === null ? x : parseFloat(x));
                        let last = data[data.length - 1];
                        if (!/[a-zA-Z]/.test(last) && /[0-9]/.test(last))
                            data[data.length - 1] = parseFloat(last);
                        return data;
                    });
                    wb.Sheets[period + ""] = XLSX.utils.aoa_to_sheet(data);
                }
                
                let saveData = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
                let buffer = new ArrayBuffer(saveData.length);
                let view = new Uint8Array(buffer);
                for (let i = 0; i < saveData.length; i++) {
                    view[i] = saveData.charCodeAt(i) & 0xFF;
                }

                let link = document.createElement("a");
                link.style.display = "none";
                link.setAttribute("href", URL.createObjectURL(new Blob([buffer], {
                    type: "application/octet-stream",
                })));
                link.setAttribute("download", title + ".xlsx");
                document.body.appendChild(link);
                link.click();
                setModal(null, null);
            }
        });
    };

    const getPool = async (poolData, selectedPeriod) => {
        if (!poolData.data.isValid)
            return null;
        const simulation = poolData.data.data;
        let results = {};
        for (let i = 0; i < simulation.teams.length; i++) {
            const team = simulation.teams[i];
            if (team.id === null)
                continue;
            const period = selectedPeriod;
            const defaultDecision = getDefaultDecision(simulation.setup.decisions);
            const teamsWithCompletedDecisions = completeTeamsDecisions(simulation.teams, defaultDecision, simulation.setup.period.current);
            const simulationObject = {
                ...simulation,
                decisionSetup: simulation.setup.decisions,
                selectedPeriod: period + 1,
                period: period,
                teamID: team.id,
                teamIndex: i,
                teams: teamsWithCompletedDecisions,
            };
            let csv = csvGenerator(simulationObject, false, false);
            let data = csv.split("\n").map(line => {
                let data = line.split(";").map(x => isNaN(parseFloat(x)) ? x : parseFloat(x));
                let last = data[data.length - 1];
                if (!/[a-zA-Z]/.test(last) && /[0-9]/.test(last))
                    data[data.length - 1] = parseFloat(last);
                let res = data[i + 1];
                return [data[0], isNaN(parseFloat(res)) ? res : parseFloat(res)];
            });
            data.unshift(["Piac", simulation.setup.general.poolCodeLetter]);
            const marketing = team.decisions[period] && team.decisions[period].marketing
                ? team.decisions[period].marketing
                : defaultDecision.marketing || {};
            for (const [key, value] of Object.entries(marketing)) {
                if (typeof value === 'object' && value !== null) {
                    for (const [subKey, subValue] of Object.entries(value)) {
                        const displayName = simLang(`marketing.${key}`) || key;
                        data.push([displayName, subValue]);
                    }
                } else {
                    const displayName = simLang(`marketing.${key}`) || key;
                    data.push([displayName, value]);
                }
            }
            const projects = team.decisions[period] && team.decisions[period].projects
                ? team.decisions[period].projects
                : defaultDecision.projects || {};
            for (const [key, value] of Object.entries(projects)) {
                const displayName = simLang(`projects.${key}.name`) || key;
                data.push([displayName, value]);
            }
            results[team.name] = data;
        }
        return results;
    };
    
    const exportPoolData = pools => {
        let rows = [
            [""],
            [""]
        ];
        
        for (let poolData of pools) {
            for (let team in poolData) {
                rows[0].push(team);
            
                for (let i = 0; i < poolData[team].length; i++) {
                    let teamData = poolData[team];
                    if (rows.length <= i + 1) {
                        rows.push([teamData[i][0]]);
                    }
                    rows[i + 1].push(teamData[i][1] ?? "");
                }
            }
        }

        let title = `${course.courseName}_course`;
        let wb = XLSX.utils.book_new();
        wb.props = {
            Title: title,
            Subject: "Simulation",
            CreatedDate: new Date(),
        };
        wb.SheetNames.push("Sheet");
    
        wb.Sheets["Sheet"] = XLSX.utils.aoa_to_sheet(rows);
    
        let saveData = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
        let buffer = new ArrayBuffer(saveData.length);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < saveData.length; i++) {
            view[i] = saveData.charCodeAt(i) & 0xFF;
        }
    
        let link = document.createElement("a");
        link.style.display = "none";
        link.setAttribute("href", URL.createObjectURL(new Blob([buffer], {
            type: "application/octet-stream",
        })));
        link.setAttribute("download", title + ".xlsx");
        document.body.appendChild(link);
        link.click();
        setModal(null, null);
    }
    
    
    const generateCourseData = async () => {
        const selectedPeriod = simulation.setup.selected.period - 1;
        setModal("loader", {showProgress: true, progress: 0, center: true});
        
        let poolData = [];
        for (let pool of round.pools) {
            poolData.push(await apiv3.get(`/course/${course.courseId}/round/${round.id}/pool/${pool.id}`));
        }
        
        const poolGenerator = function* () {
            let pools = [];
            for (let pool of poolData) {
                pools.push(getPool(pool, selectedPeriod));
                yield;
            }
            return pools;
        };
        handleLongTask(poolGenerator(), round.pools.length, progress => {
            setModal("loader", {showProgress: true, progress: progress, center: true});
        }).then(pools => Promise.all(pools))
            .then(poolData => exportPoolData(poolData));
        
    };
    
    const generateDeaData = (selectedPeriod) => {
        setModal("loader", {center: true});
        window.requestIdleCallback(() => {
            const getPool = async pool => {
                return await apiv3.get(`/course/${course.courseId}/round/${round.id}/pool/${pool.id}`)
                    .then(res => {
                        if (!res.data.isValid)
                            return null;
                        const simulation = res.data.data;
                        let results = [];
                        for (let i = 0; i < simulation.teams.length; i++) {
                            const team = simulation.teams[i];
                            if (team.id === null)
                                continue;
                            
                            const defaultDecision = getDefaultDecision(simulation.setup.decisions);
                            const teamsWithCompletedDecisions = completeTeamsDecisions(simulation.teams, defaultDecision, simulation.setup.period.current);
    
                            const simulationObject = {
                                ...simulation,
                                decisionSetup: team.decisions[selectedPeriod - 1] ?? [],
                                selectedPeriod: selectedPeriod - 1,
                                period: selectedPeriod - 1,
                                teamID: team.id,
                                teamIndex: i,
                                teams: teamsWithCompletedDecisions,
                            };
                            results.push({
                                ...deaGenerator(simulationObject),
                                team: team.name,
                            });
                        }
                        return results;
                    }).catch(console.error);
            };
            
            let promises = [];
            for (let pool of round.pools) {
                promises.push(getPool(pool));
            }
            Promise.all(promises).then(raw => {
                let deaRows = raw.reduce((prev, next) => [...prev, ...next], []);
                let rows = {team: []};
                for (let i = 0; i < deaRows.length; i++) {
                    const deaRow = deaRows[i];
                    for (const key in deaRow) {
                        if (!rows[key])
                            rows[key] = [];
                        rows[key][i] = deaRow[key];
                    }
                }
                
                for (const key in rows) {
                    if (key === "team")
                        continue;
                    const max = Math.max(...rows[key]);
                    if (max === 0)
                        continue;
                    
                    const div = 10 ** (Math.floor(Math.log10(max)) - (deaLengthLimit || 4) + 1);
                    rows[key] = rows[key].map(x => x / div);
                }
                
                let data = [[]];
                data[0][0] = "";
                for (const key in rows) {
                    if (key !== "team")
                        data[0].push(key);
                }
                for (let i = 0; i < deaRows.length; i++) {
                    let newRow = [rows.team[i]];
                    for (const key in rows) {
                        if (key !== "team")
                            newRow.push(rows[key][i]);
                    }
                    data.push(newRow);
                }
                
                let wb = XLSX.utils.book_new();
                const title = `DEA_${course.courseName}_${round.name}`;
                wb.props = {
                    Title: title,
                    Subject: "Simulation",
                    CreatedDate: new Date(),
                };
                wb.SheetNames.push("DEA");
                wb.Sheets["DEA"] = XLSX.utils.aoa_to_sheet(data);
                let saveData = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
                let buffer = new ArrayBuffer(saveData.length);
                let view = new Uint8Array(buffer);
                for (let i = 0; i < saveData.length; i++) {
                    view[i] = saveData.charCodeAt(i) & 0xFF;
                }
                
                let link = document.createElement("a");
                link.style.display = "none";
                link.setAttribute("href", URL.createObjectURL(new Blob([buffer], {
                    type: "application/octet-stream",
                })));
                link.setAttribute("download", title + ".xlsx");
                document.body.appendChild(link);
                link.click();
                setModal(null, null);
            });
            
        });
    };
    
    return (
        <div className="bar">
            <div className="left">
                <RoundName />
                <Divider />
                <Pool />
                <Divider />
                <div>{myTeamName ? myTeamName : lang("pageHeader.noTeam")}</div>
                <Divider />
                <Deadline />
                <Divider />
                <PeriodSelect />
            </div>
            <div className="right">
                
                {isOwner ? <TeamNameSelect /> : null}
                <SelectButton />
                {dea && isOwner && isEngine ?
                    <Button className="dea-button primary narrow" text="DEA" onClick={() => generateDeaData(simulation.setup.selected.period)} /> : null}
                {isOwner ? <Button icon="far fa-file-archive" className="primary buckle"
                                   text={lang("lobby.courseExport")} onClick={generateCourseData}
                                   disabled={!csvGenerator} /> : null}
                <Button text={lang("pageHeader.csvExport")} icon="far fa-file" className="primary buckle"
                        onClick={generateFile} disabled={!csvGenerator || isBreak} />
                <SaveButton disabled={isNotInPool || isBreak} onSave={onSave} />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        myTeam: state.simulation.setup.myTeam,
        simulation: state.simulation,
        simulations: state.user.simulations,
        courses: state.user.courses,
        selected: state.user.selected,
        csvGenerator: state.simulation.csvGenerator,
        deaGenerator: state.simulation.deaGenerator,
        dea: state.simulation.market.dea,
        deaLengthLimit: state.simulation.market.deaLengthLimit,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (name, data) => dispatch(setModal(name, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationHeader);
