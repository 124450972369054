import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import Box from "../components/Box/Box";
import { apiv3 } from "../axios";
import "../components/Table/Table.css";
import { InputText } from "../components/Form/Inputs";
import { createAlert } from "../store/actions/ui";
import { connect } from "react-redux";
import { Button } from "../components/Button/Button";
import { lang } from "../assets/js/language-utils";

function CourseList(props) {
    const [courses, setCourses] = useState([]);
    const [search, setSearch] = useState("");
    const [timeout, setTimeoutId] = useState(null);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getCourses();
    }, [search]);

    const handleInputChange = (e) => {
        const newSearch = e.target.value;
        setSearch(newSearch);

        if (timeout) clearTimeout(timeout);

        if (newSearch.length >= 2) {
            setTimeoutId(
                setTimeout(() => {
                    getCourses(newSearch);
                }, 1000)
            );
        } else if (newSearch.length === 0) {
            getCourses();
        }
    };

    const getCourses = (search = "") => {
        apiv3
            .get("/admin/course?days=1000&search=" + search)
            .then((res) => {
                if (res.data.msg) {
                    props.createAlert({
                        type: res.data.isValid ? "success" : "danger",
                        msg: res.data.msg,
                    });
                }
                if (res.data.isValid) {
                    search = search.toLowerCase();
                    setCourses(
                        res.data.data.courses.filter((course) => {
                            if (!search) return true;
                            if (course.id === search) return true;
                            if (course.name.toLowerCase().indexOf(search) !== -1) return true;
                            if (course.email.toLowerCase().indexOf(search) !== -1) return true;
                            if (course.simulation.toLowerCase().indexOf(search) !== -1) return true;
                            return course.licenceName.toLowerCase().indexOf(search) !== -1;
                        })
                    );
                }
            })
            .catch((error) => {
                props.createAlert({
                    type: "danger",
                    msg: error,
                });
            });
    };

    const Courses = () => (
        <Box title="Courses">
            <div className="form">
                <InputText
                    type="text"
                    name="search"
                    placeholder={lang("admin.courseSearch")}
                    value={search}
                    onChange={handleInputChange}
                    focus
                />
            </div>
            <div className="table">
                <table>
                    <thead>
                    <tr>
                        <th>{lang("admin.courseTable.id")}</th>
                        <th>{lang("admin.courseTable.name")}</th>
                        <th>{lang("admin.courseTable.simulation")}</th>
                        <th>{lang("admin.courseTable.createdAt")}</th>
                        <th>{lang("admin.courseTable.licence")}</th>
                        <th>{lang("admin.courseTable.email")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {courses
                        .slice(page * 50, page * 50 + 50)
                        .map((course, id) => (
                            <Course key={id} {...course} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="table-footer row mt-3">
                <div className="col-md-4 text-left">
                    <Button
                        disabled={page === 0}
                        className="primary"
                        onClick={() => {
                            setPage(page - 1);
                        }}
                        text={lang("admin.courseTable.previous")}
                    />
                </div>
                <div className="col-md-4 text-center">
                    <span>{lang("admin.courseTable.page")} {page + 1}</span>
                </div>
                <div className="col-md-4 text-right">
                    <Button
                        disabled={page >= Math.floor(courses.length / 50)}
                        className="primary"
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        text={lang("admin.courseTable.next")}
                    />
                </div>
            </div>

        </Box>
    );

    const Course = ({ id, name, simulation, createdAt, licenceName, email }) => (
        <Route
            render={({ history }) => (
                <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        history.push("/admin/courses/" + id);
                    }}
                >
                    <td>{id}</td>
                    <td style={{ textAlign: "left" }}>{name}</td>
                    <td style={{ textAlign: "left" }}>{simulation}</td>
                    <td style={{ textAlign: "left" }}>{createdAt}</td>
                    <td style={{ textAlign: "left" }}>{licenceName}</td>
                    <td style={{ textAlign: "left" }}>{email}</td>
                </tr>
            )}
        />
    );

    return (
        <div className="admin">
            <div className="container fluid">
                <Courses />
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    createAlert: (alert) => dispatch(createAlert(alert)),
});

export default connect(null, mapDispatchToProps)(CourseList);
