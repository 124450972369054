import React, {Children, useCallback, useState} from "react";
import {Button} from "../Button/Button";
import ReactMarkdown from "react-markdown";
import "./Box.css";
import {connect, ConnectedProps} from "react-redux";

interface ZoomInfo {
    zoomed: boolean;
    nextZoomed: () => void;
    prevZoomed: () => void;
    toggleZoomed: () => void;
}

const mapStateToProps = (state: any) => ({
    market: state.simulation.market,
});

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & {
    className?: string;
    icon?: string;
    nonCollapse?: boolean;
    right?: React.ReactNode;
    title: string;
    zoomable?: boolean;
    zoomInfo?: ZoomInfo;
    noFlex?: boolean;
    methodChange?: () => void;
    children: React.ReactNode;
    onClick?: () => void;
}

function Box({
                 className = "",
                 icon = "",
                 nonCollapse = false,
                 right,
                 title,
                 zoomable = false,
                 zoomInfo,
                 noFlex = false,
                 methodChange,
                 children,
                 market,
                 onClick,
             }: Props) {
    const [zoomed, setZoomed] = useState(zoomable && (zoomInfo?.zoomed ?? false));
    const [collapsed, setCollapsed] = useState(false);
    
    const isZoomedIn = zoomable && (zoomed || zoomInfo?.zoomed);
    
    let boxClassName = `box ${className} ${collapsed ? "collapsed" : ""} ${isZoomedIn ? "zoom" : ""}`;
    const arrow = `fas fa-angle-${collapsed ? "down" : "up"}`;
    const zoomIcon = `fas fa-search-${isZoomedIn ? "minus" : "plus"}`;
    
    const toggleZoom = useCallback(() => {
        if (zoomInfo) {
            zoomInfo.toggleZoomed();
        } else {
            setZoomed(!zoomed && zoomable);
        }
    }, [zoomed, zoomable]);
    const toggleCollapsed = useCallback(() => setCollapsed(!collapsed && !nonCollapse), [collapsed, nonCollapse]);
    
    return (
        <>
            <div className={boxClassName} onClick={onClick}>
                {title ? (
                    <div className="title-row">
                        {icon ? <div className="icon">{icon}</div> : null}
                        <div className={`title ${noFlex ? " no-flex" : ""}`}
                             style={noFlex ? {width: "100%"} : {}}>
                            {title}
                        </div>
                        <div className="buttons line" style={{width: "auto"}}>
                            {right}
                            {methodChange ? (
                                <Button icon="fas fa-chart-pie" className="trans small" onClick={methodChange} />
                            ) : null}
                            {zoomable && !collapsed ? (
                                <Button icon={zoomIcon} className="trans small" onClick={toggleZoom} />
                            ) : null}
                            {nonCollapse || isZoomedIn ? null : (
                                <Button icon={arrow} className="trans small" onClick={toggleCollapsed} />
                            )}
                        </div>
                    </div>
                ) : null}
                <div className="body">
                    {Children.map(children, (child: React.ReactNode, i: number) => {
                        if (typeof child !== "string") {
                            return child;
                        }
                        let text = child.replace(/{{(.+?)}}/g, (_, content) => {
                            let current = market;
                            let last = "market";
                            for (let subpath of content.split(".")) {
                                current = current[subpath];
                                if (current === undefined)
                                    return `${last} has no child named ${subpath}`;
                                last = subpath;
                            }
                            return current.toString();
                        });
                        
                        return (
                            <ReactMarkdown key={i}>
                                {text}
                            </ReactMarkdown>
                        );
                    })}
                </div>
            </div>
            {isZoomedIn ? (
                <div className="dimmer" onClick={toggleZoom} />
            ) : null}
            {zoomInfo?.zoomed && zoomInfo?.prevZoomed ? (
                <div className="buttons z-prev">
                    <Button icon="fas fa-angle-left" className="trans small" onClick={zoomInfo.prevZoomed} />
                </div>
            ) : null}
            {zoomInfo?.zoomed && zoomInfo?.nextZoomed ? (
                <div className="buttons z-next">
                    <Button icon="fas fa-angle-right" className="trans small" onClick={zoomInfo.nextZoomed} />
                </div>
            ) : null}
        </>
    );
}

export default connector(Box);