import CourseDetails from "./CourseDetails/CourseDetails.js";
import Timing from "./Timing/Timing.js";
import {getCourseById} from "../../../assets/js/utility.js";
import React, {useState} from "react";
import SingleUsers from "./SingleUsers/SingleUsers.js";
import Teams from "./Teams/Teams.js";
import Pools from "./Pools/Pools.js";
import MyTeam from "./MyTeam/MyTeam.js";
import PoolTeams from "./PoolTeams/PoolTeams.js";
import {connect} from "react-redux";
import SelectRound from "./SelectRound/SelectRound.js";
import {getValidatedStorage, setValidatedStorage} from "../../../model/common/commonUtils.ts";

const SELECTED_ROUND = "SELECTED_ROUND";

const Content = ({user, selected, courses}) => {
    const selectedCourse = getCourseById(selected.courseID, courses);

    const def = getValidatedStorage(SELECTED_ROUND, selected.roundID, selected.courseID);
    const [selectedRoundID, setSelectedRoundID] = useState(def);
    if (selectedCourse.rounds.findIndex(round => round.id === selectedRoundID) === -1)
        setSelectedRoundID(selectedCourse.rounds[0].id);
    
    return (
        <div className='row'>
            <div className='col-lg-4'>
                <CourseDetails
                    selectedCourse={selectedCourse} />
                <Timing
                    selectedCourse={selectedCourse} />
            </div>
            <div className='col-lg-4'>
                <MyTeam selectedCourse={selectedCourse} />
                {selectedCourse.isOwner ? <SingleUsers selectedCourse={selectedCourse} user={user} selectedRoundID={selectedRoundID} /> : null}
                {selectedCourse.isOwner ? <PoolTeams roundID={selectedRoundID} selectedCourse={selectedCourse} /> : null}
            </div>
            <div className='col-lg-4'>
                {selectedCourse.isOwner ? <SelectRound selectedRoundID={selectedRoundID} setSelectedRoundID={roundID => {
                    setSelectedRoundID(roundID);
                    setValidatedStorage(SELECTED_ROUND, roundID, selected.courseID);
                }} /> : null}
                {selectedCourse.isOwner ? <Teams roundID={selectedRoundID} selectedCourse={selectedCourse} /> : null}
                <Pools roundID={selectedRoundID} selectedCourse={selectedCourse} />
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    courses: state.user.courses,
    selected: state.user.selected,
});

export default connect(mapStateToProps, null)(Content);