import {lang} from "../../../../../assets/js/language-utils.js";
import FinalTime from "./FinalTime/FinalTime.js";
import React from "react";
import RoundTitle from "./RoundTitle/RoundTitle.js";
import Periods from "./Periods/Periods.js";

const Rounds = ({rounds, selectedCourse}) => (
    rounds.map((round, index) => (
        <div key={index}>
            <RoundTitle
                name={round.name}
                index={index + 1}
                id={round.id}
                endTime={round.endTime}
                periods={round.periods}
                maxTeam={Number(round.maxTeam)}
                selectedCourse={selectedCourse} />
            {(typeof round.periods === "undefined") || round.periods.length === 0 ? (
                <div className='periods'>{lang("lobby.noPeriods")}</div>
            ) : (
                <div>
                    <Periods round={round} />
                    <FinalTime round={round} />
                </div>
            )}
        </div>
    ))
);

export default Rounds;