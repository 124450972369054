import {Button} from "../../../../../components/Button/Button.js";
import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";
import {setModal} from "../../../../../store/actions/ui.js";
import {connect} from "react-redux";

const EditRow = ({selectedCourse, setModal}) => (
    <div className='line mt-1'>
        <Button
            text={lang("lobby.edit")}
            className='primary'
            onClick={() => setModal("editCourse", selectedCourse)}
            title={lang("lobby.editCourseDetails")}
        />
        <Button
            text={lang("lobby.delete")}
            className='danger'
            onClick={() => setModal("deleteCourse", selectedCourse)}
            title={lang("lobby.deleteCourse")}
        />
    </div>
);

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
});

export default connect(null, mapDispatchToProps)(EditRow);