import axios from "axios";

export const testHash = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_HASH : ''

export const sessionHashName = process.env.NODE_ENV === 'development' ? 'ecoSimEduSessionHashTest' : 'ecoSimEduSessionHash'

const params =
    process.env.NODE_ENV === 'development' ? {testHash: process.env.REACT_APP_HASH} : ''

// Set the default path for AJAX
export const firebaseInstance = axios.create({
    baseURL: 'https://edu-app-v3.firebaseio.com/'
})

export const instance = axios.create({
    baseURL: '/api/v2/',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    params: params
})

export const apiv3 = axios.create({
    baseURL: "/api/v3/",
    headers: {
        "content-type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${localStorage.getItem(sessionHashName)}`
    },
    params: params
})

