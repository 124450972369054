import * as types from "../actions/user";
import {getActiveItem, getCourseById, getItemWithID, getLastItem, getTeamsPool} from "../../assets/js/utility";

const initialState = {
    courses: [],
    simulations: [],
    profile: {
        licence: {}
    },
    selected: {
        courseID: '',
        simulationID: '',
        roundID: '',
        poolID: ''
    }
}

// App language json
export let language = {}

export const user = (state = initialState, action) => {
    /*console.log("reducers/user.js state, action: ", state, action);*/
    switch ( action.type ) {

        case types.INIT_USER : return initUser(state, action)
        case types.ACTIVATE_COURSE : return activateCourse(state, action)
        case types.DELETE_COURSE : return deleteCourse(state, action)
        case types.DELETE_ROUND : return deleteRound(state, action)
        case types.SELECT_ROUND : return selectRound(state, action)
        case types.SELECT_POOL : return selectPool(state, action)
        case types.DELETE_USER_FROM_TEAM: return deleteUserFromTeam(state, action)
        
        default : return state
    }
}

const initUser = (state, action) => {
    const activeCourse = getActiveItem(action.data.courses)
    language = action.data.lang
    return {
        ...action.data,
        selected: getSelectedIDs(activeCourse) }
}

/**
 * @param {obj} course 
 * @param {string} roundID
 * @param {string} poolID
 */
const getSelectedIDs = (course, roundID, poolID) => {
    if (!course) {
        return {
            courseID: null,
            simulationID: null,
            roundID: null,
            poolID: null
        }
    }
    const selectedRound = getSelectedRound(roundID, course.rounds)
    if (!selectedRound) {
        return {
            courseID: course.courseId,
            simulationID: course.simulationId,
            roundID: null,
            poolID: null
        }
    }
    const selectedPool = getSelectedPool(poolID, selectedRound.pools, course.myTeam)
    if (!selectedPool) {
        return {
            courseID: course.courseId,
            simulationID: course.simulationId,
            roundID: selectedRound.id,
            poolID: null
        }
    }
    return {
        courseID: course.courseId,
        simulationID: course.simulationId,
        roundID: selectedRound.id,
        poolID: selectedPool.id
    }
}

const getSelectedRound = (roundID, rounds) => {
    /** Selection priority
     *  1. Selected round
     *  2. Active round
     *  3. Last round
     */
    return getItemWithID(roundID, rounds) || getActiveItem(rounds) || getLastItem(rounds)
}

const getSelectedPool = (poolID, pools, myTeam) => {
    /** Selection priority
     *  1. Selected pool
     *  2. My team's pool
     *  3. First pool, A
     */

    return getItemWithID(poolID, pools) || getTeamsPool(myTeam, pools) || pools[0]
}

const deleteCourse = (state, action) => {
    return {
        ...state,
        courses: state.courses.filter(course => course.id !== action.id)
    }
}

const deleteRound = (state, action) => {
    return {
        ...state,
        courses: state.courses.map(course => {
            if (course.id !== action.courseID) {
                return course
            }
            return {
                ...course,
                rounds: course.rounds.filter(round => round.id !== action.roundID)
            }
        })
    }
}

const activateCourse = (state, action) => {
    const selectedCourse = getCourseById(action.id, state.courses)
    if (!selectedCourse) {
        return { ...state }
    }
    return {
        ...state,
        courses: state.courses.map(course => ({
            ...course,
            active: course.courseId === action.id
        })),
        selected: getSelectedIDs(selectedCourse)
    }
}

const selectRound = (state, action) => {
    const selectedCourse = getCourseById(state.selected.courseID, state.courses)
    const selectedRound = getItemWithID(action.id, selectedCourse.rounds)
    const selectedPool = getSelectedPool('', selectedRound.pools, selectedCourse.myTeam) || {}
    return {
        ...state,
        selected: {
            ...state.selected,
            roundID: action.id,
            poolID: selectedPool.id
        }
    }
}

const selectPool = (state, action) => {
    const selectedCourse = getCourseById(state.selected.courseID, state.courses)
    const selectedRound = getItemWithID(state.selected.roundID, selectedCourse.rounds)
    const selectedPool = getSelectedPool(action.id, selectedRound.pools, selectedCourse.myTeam)
    return {
        ...state,
        selected: {
            ...state.selected,
            poolID: selectedPool.id
        }
    }
}

const deleteUserFromTeam = (state, action) => {
    const copy = {...state};

    copy.courses = copy.courses.map(course => {
        if (course.courseId !== action.courseID)
            return course;

        let deleted = null;
        course.teams = course.teams.map(team => {
            if (team.id !== action.teamID)
                return team;
            team.users = team.users.filter(user => {
                if (user.userId === action.userId) {
                    deleted = user;
                    return false;
                }
                return true;
            });
            return team;
        });
        if (deleted) {
            course.users.push({
                ...deleted,
                id: deleted.userId,
            });
        }
        return course;
    });
    return copy;
};