import Box from "../../../../components/Box/Box";
import {lang} from "../../../../assets/js/language-utils.js";
import User from "../../../../components/Lobby/User.js";
import React from "react";

const SingleUsers = ({selectedCourse, user, selectedRoundID}) => {
    const users = user.profile.licenceType === "admin" ? selectedCourse.users : selectedCourse.users.filter(u => !u.isAdmin);
    const teams = selectedCourse.teams;
    const haveSingleUsers = users.length > 0;
    return (
        <Box title={[<span key="singleUsersTitle">{lang("lobby.singleUsers")} ({users.length})</span>]} collapsed={!haveSingleUsers}>
            {haveSingleUsers ? users.map(user => (<User
                    key={user.id}
                    id={user.id}
                    name={user.firstName + " " + user.lastName}
                    move={selectedCourse.isOwner}
                    teams={teams}
                    haveLicence={user.haveLicence}
                    isAdmin={user.isAdmin}
                    courseID={selectedCourse.courseId}
                    roundID={selectedRoundID} />))
                : <div>{lang("lobby.noSingleUsers")}</div>}
        </Box>
    );
};

export default SingleUsers;