import React, {Component} from "react";
import {connect} from "react-redux";
import {apiv3, testHash} from "../../axios";
import qs from "qs";

import {createAlert} from "../../store/actions/ui";
import {fetchUserData} from "../../store/actions/user";

import {Dropdown} from "../Dropdown/Dropdown";
import {Button} from "../Button/Button";
import {Loader} from "../Loader/Loader";
import User from "./User";
import {lang} from "../../assets/js/language-utils";

class Team extends Component {

    state = {
        toggleDropdown: false,
        isFetching: false
    }

    moveDropdownHandler = () => {
        this.setState(prevState => (
            { toggleDropdown: !prevState.toggleDropdown }
        ))
    }

    moveTeam = (teamID, poolID, courseID, roundID, type) => {
        this.setState({ isFetching: true })
        const data = qs.stringify({teamIdArray: [teamID], poolID, roundID, type, testHash})
        apiv3.post(`/course/${courseID}/moveTeam`, data)
        .then(res => {
            if (res.data.isValid) {
                this.moveTeamSuccess()
            } else {
                this.moveTeamFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.moveTeamFailed(err)
        })
    }

    moveTeamSuccess = () => {
        this.setState({ isFetching: false, toggleDropdown: false })
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: lang('team.moveSuccess')
        })
    }

    moveTeamFailed = (err) => {
        this.setState({ isFetching: false, toggleDropdown: false })
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
    }

    deleteTeam = (courseID, id) => {
        this.setState({ isFetching: true })
        apiv3.delete(`/course/${courseID}/team/${id}`)
        .then(res => {
            if (res.data.isValid) {
                this.deleteTeamSuccess()
            } else {
                this.deleteTeamFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.deleteTeamFailed(err)
        })
    }

    deleteTeamSuccess = () => {
        this.setState({ isFetching: false, toggleDropdown: false })
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: lang('team.deleteSuccess')
        })
    }

    deleteTeamFailed = (err) => {
        this.setState({ isFetching: false, toggleDropdown: false })
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
    }

    render() {
        const MoveDropDown = (props) => {

            const Content = (props) => this.state.isFetching ? ( <Loader center={true} />) : (
                [...props.list.map(pool => {
                    return <li key = { pool.id } >
                        <span onClick={() => this.moveTeam(this.props.id, pool.id, this.props.courseID, this.props.roundID, 'move')}>{ pool.name }</span>
                    </li>
                }), <li key = { 'new' } >
                        <span onClick={() => this.moveTeam(this.props.id, null, this.props.courseID, this.props.roundID, 'new')}>{lang('team.newPool')}</span>
                    </li>
                , <li key = { 'delete' } >
                    <span onClick={() => this.moveTeam(this.props.id, this.props.poolID, this.props.courseID, this.props.roundID, 'delete')}>{lang('team.remove')}</span>
                </li>]
            )

            return (
                <Dropdown className = 'small'>
                    <Content list = { props.list } />
                </Dropdown>
            )
        }

        return (
            <div className='team'>
                <div className = { this.props.sum ? 'line sum' : 'line' }>
                    <div> { this.props.name } </div>
                    <div>
                        
                        { this.props.move ? (
                            <div className='relative d-inline'>
                                <Button text={lang('team.move')} className='primary small' onClick = { () => this.moveDropdownHandler() } title={lang("lobby.moveTeamToMarket")} />
                                { this.state.toggleDropdown ? <MoveDropDown list = { this.props.pools } /> : null }
                            </div>
                        ) : null }

                        { this.props.delete ?
                            <Button icon = 'fas fa-trash' className='danger small' title={lang("team.delete")} onClick = { () => this.deleteTeam(this.props.courseID, this.props.id) } />
                        : null }
                
                    </div>
                </div>
                { this.props.members ? this.props.members.map((member, index) => {
                        return <User key = { index } id = { member.id } name = { member.name } captain = { member.isCaptain } delete = { !member.isCaptain && this.props.userDelete } courseID = { this.props.courseID } teamID = { this.props.id } />
                }) : this.props.children }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
        simulations: state.user.simulations,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createAlert: alert => dispatch(createAlert(alert)),
        fetchUserData: () => dispatch(fetchUserData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
