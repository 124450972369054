import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";

const CreatedBy = ({name}) => (
    <div className='line'>
        <div>{lang("lobby.createdBy")}</div>
        <div>{name}</div>
    </div>
);

export default CreatedBy;