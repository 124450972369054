import * as types from "../actions/ui";

const initialState = {
    alerts: [],
    modal: {
        name: false,
        data: {}
    },
    isSidebarToggled: false,
    isFetching: false,
    isError: false,
    activeItemIDs: {},
    tooltip: null,
}

export const ui = (state = initialState, action) => {
    switch ( action.type ) {

        case types.TOGGLE_SIDEBAR : return toggleSidebar(state, action)
        case types.SET_MODAL : return setModal(state, action)
        case types.CREATE_ALERT : return createAlert(state, action)
        case types.DELETE_ALERT : return deleteAlert(state, action)
        case types.INIT_ACTIVE_ITEMS : return initActiveItems(state, action)
        case types.SET_ACTIVE_ITEM : return setActiveItem(state, action)
        case types.RESET_ACTIVE_ITEMS : return resetActiveItems(state, action)
        case types.FETCH_START : return fetchStart(state, action)
        case types.FETCH_END : return fetchEnd(state, action)
        case types.FETCH_FAILED : return fetchFailed(state, action)
        case types.CREATE_TOOLTIP : return createTooltip(state, action)
        case types.CLOSE_TOOLTIP : return closeTooltip(state, action)
        
        default : return state
    }
}

const toggleSidebar = (state, action) => {
    return {
        ...state,
        isSidebarToggled: !state.isSidebarToggled
    }
}

const setModal = (state, action) => {
    return {
        ...state,
        modal: {
            name: action.name,
            data: action.data
        }
    }
}

const createAlert = (state, action) => {
    return {
        ...state,
        alerts: [...state.alerts, action.alert]
    }
}

const deleteAlert = (state, action) => {
    return {
        ...state,
        alerts: state.alerts.filter((alert, index) => index !== action.index)
    }
}

const initActiveItems = (state, action) => {
    const value = localStorage.getItem('activeItemIDs')
    const activeItemIDs = JSON.parse(value) || {}
    return {
        ...state,
        activeItemIDs
    }
}

const setActiveItem = (state, action) => {
    return {
        ...state,
        activeItemIDs: {
            ...state.activeItemIDs,
            [action.tab]: action.item
        }
    }
}

const resetActiveItems = (state, action) => {
    localStorage.removeItem('activeItemIDs')
    return {
        ...state,
        activeItemIDs: {}
    }
}

const fetchStart = (state, action) => {
    return {
        ...state,
        isFetching: true
    }
}

const fetchEnd = (state, action) => {
    return {
        ...state,
        isFetching: false
    }
}

const fetchFailed = (state, action) => {
    return {
        ...state,
        isFetching: false,
        isError: true
    }
}

const createTooltip = (state, action) => {
    return {
        ...state,
        tooltip: {
            tip: action.tip,
            target: action.target
        }
    }
}

const closeTooltip = (state, action) => {
    return {
        ...state,
        tooltip: null
    }
}