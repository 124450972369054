import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import {toggleSidebar} from "../../store/actions/ui";
import {getItemWithID} from "../../assets/js/utility";
import "./Sidebar.css";
import logo from "./logo.png";
import {lang} from "../../assets/js/language-utils";

class Sidebar extends Component {

    state = {
        width: window.innerWidth
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    }

    toggleActive = e => {
        e.currentTarget.classList.toggle('active')
    }

    handleClick = () => {
        const isMobile = this.state.width <= 576
        if (isMobile) {
            this.props.toggleSidebar()
        }
    }

    render() {
        const className = this.props.isSidebarToggled ? 'toggled' : ''
        const selectedSimulation = getItemWithID(this.props.selected.simulationID, this.props.simulations)
        let simulationMenu = selectedSimulation ? selectedSimulation.menu : null
        if (simulationMenu)
            simulationMenu.forEach(item => item.name = lang(item.path))
        const menu = [
            {
                name: lang('menu.dashboard'),
                id: '/dashboard',
                icon: 'fas fa-home',
                link: '/dashboard'
            }, {
                name: lang('menu.courses'),
                id: '/courses',
                icon: 'fab fa-accusoft',
                link: '/courses'
            }, {
                name: lang('menu.lobby'),
                id: '/lobby',
                icon: 'fas fa-user-friends',
                link: '/lobby'
            }, {
                name: lang('menu.simulation'),
                id: '/simulation',
                icon: 'fas fa-chart-line',
                link: '/simulation',
                subMenu: simulationMenu
            }
        ]

        if (this.props.licenceType === "admin") {
            menu.push({
                name: 'Admin',
                id: '/admin',
                icon: 'fas fa-home',
                link: '/admin',
                subMenu: [{
                    name: 'Users',
                    id: '/admin//users',
                    icon: 'fas fa-users',
                    link: '/admin/users'
                }, {
                    name: 'Courses',
                    id: '/admin/courses',
                    icon: 'fab fa-accusoft',
                    link: '/admin/courses'
                }, {
                    name: 'Licences',
                    id: '/admin/licences',
                    icon: 'fas fa-id-badge',
                    link: '/admin/licences'
                }]
            })
        }

        const Brand = () => (
            <Link to='/' >
                <div className='brand'>
                    <img className='logo' src={logo} alt='Logo'/>
                    <div className='title'>Edu</div>
                </div>
            </Link>
        )

        const Menu = (props) => {
            const { className, items } = props
            return (
                <ul className = { className || 'menu' } >
                    { items.map(item => {
                        return <MenuItem
                            active = { this.props.activeMenuItem === item.id }
                            icon = { item.icon }
                            id = { item.id }
                            key = { item.id }
                            link = { item.link }
                            name = { item.name }
                            subMenu = { item.subMenu }
                        />
                    }) }
                </ul>
            )
        }

        const MenuItem = (props) => {
            const { icon, link, name, subMenu } = props
            return (
                <li className = 'item'>
                    <NavLink to = { link } onClick = { this.handleClick } >
                        <i className = { icon } />
                        { name }
                    </NavLink>
                    { subMenu ? <span className='submenu-toggle active' onClick={(e) => this.toggleActive(e)}><i className="fas fa-angle-down"></i></span> : null }
                    { subMenu ? <Menu items = { subMenu } className = 'sub' /> : null }
                </li>
            )
        }

        return (
            <aside id='sidebar' className={className}>
                <div className="main-content">
                    <div className='container'>
                        <Brand />
                        <nav>
                            <Menu items = { menu } />
                        </nav>
                    </div>
                </div>
            </aside>
        )
    }

}

const mapStateToProps = state => {
    return {
        isSidebarToggled: state.ui.isSidebarToggled,
        activeMenuItem: state.ui.activeMenuItem,
        simulations: state.user.simulations,
        selected: state.user.selected,
        licenceType: state.user.profile.licenceType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebar: () => dispatch(toggleSidebar())
    }
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))