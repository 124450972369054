/**
 * @file - The 404 error view.
 */

// External
import React, {Component} from "react";
// Components
import {CardBox} from "../components/Card/CardBox";
import {Button} from "../components/Button/Button";
// Assets
import errorImg from "../assets/img/404.svg";
import {lang} from "../assets/js/language-utils";

class PageNotFound extends Component {

    render() {
    
        return (
            <div className='page-not-found'>
                <div className="container fluid">
                    <CardBox img={errorImg} >
                        <h3>404</h3>
                        <div className="main">
                            {lang('error.pageNotFoundWithnavigation')}
                        </div>
                            <Button className='primary' text={lang('menu.dashboard')} onClick={() => this.props.history.push('/dashboard')} />
                    </CardBox>
                </div>
            </div>
        )
    }

}

export default PageNotFound