/**
 * @file Handling all the main routes.
 */

// External
import React, {Component} from "react";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
// Views
import Dashboard from "./Dashboard";
import Courses from "./Courses/Courses";
import Lobby from "./Lobby/Lobby.js";
import Simulation from "./Simulation";
import PageNotFound from "./PageNotFound";
import Profile from "./Profile";
import Admin from "./Admin";
import Gateway from "./Gateway";

class MainRouter extends Component {
    render() {
        return (
            <Switch>
                <Redirect exact path = '/' to = '/dashboard'/>
                <Route exact path = '/dashboard' component = { Dashboard } />
                <Route exact path = '/courses' component = { Courses } />
                <Route exact path = '/lobby' component = { Lobby } />
                <Route exact path = '/simulation' component = { Simulation } />
                <Route exact path = '/simulation/:tabId' component = { Simulation } />
                <Route exact path = '/profile' component = { Profile } />
                <Route path = '/admin' component = { Admin } />
                <Route path = '/gateway' component = { Gateway } />
                <Route path = '/' component = { PageNotFound } />
            </Switch>
        )
    }
}

export default withRouter(connect()(MainRouter))