// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputSelect, InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Utility
// import { handleSubmit } from '../../../assets/js/utility'
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";
import {initRequest} from "../../../../store/actions/simulation.js";

class CreateCourse extends Component {
    
    state = {
        courseName: "",
        simulationID: "",
        isFetching: false,
    };
    
    handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.courseName.length < 3) {
            this.setState({
                msg: {
                    type: "error",
                    text: lang("courses.minNam"),
                },
            });
        } else if (!this.state.simulationID) {
            this.setState({
                msg: {
                    type: "error",
                    text: lang("courses.selectSimulationError"),
                },
            });
        } else {
            this.createCourse();
        }
    };
    
    createCourse = () => {
        this.setState({isFetching: true});
        const data = qs.stringify({...this.state, testHash});
        apiv3.post("/course", data)
            .then(res => {
                this.setState({isFetching: false});
                if (res.data.isValid) {
                    this.createCourseSuccess();
                } else {
                    this.createCourseFailed(res.data.msg);
                }
            })
            .catch(err => {
                this.createCourseFailed(err);
            });
    };
    
    createCourseSuccess = () => {
        window.location.href = "/lobby";
    };
    
    createCourseFailed = (err) => {
        this.setState({
            msg: {
                type: "error",
                text: err,
            },
        });
    };
    
    render() {
        
        if (this.state.isFetching) {
            return (
                <Modal title={lang("courses.createProcess")}>
                    <Loader />
                </Modal>
            );
        }
        
        const FormMessage = () => {
            
            if (!this.state.msg) return null;
            
            return (
                <div className={"message " + this.state.msg.type}>
                    {this.state.msg.text}
                </div>
            );
        };
        
        const licencedSimulation = this.props.simulations.filter(simulation => simulation.licence);
        
        return (
            <Modal title={lang("courses.createTitle")}>
                <form className="form" onSubmit={(e) => this.handleSubmit(e, this.createCourse)}>
                    <div className="body">
                        
                        <InputText
                            label={lang("courses.name")}
                            type="text"
                            name="courseName"
                            placeholder={lang("courses.namePlaceholder")}
                            value={this.state.courseName}
                            onChange={this.handleInputChange}
                            focus
                        />
                        
                        <InputSelect
                            label={lang("courses.selectSimulation")}
                            name="simulationID"
                            placeholder={lang("courses.selectSimulationPlaceholder")}
                            options={licencedSimulation}
                            selected={this.state.simulationID}
                            onChange={this.handleInputChange}
                            required
                        />
                        
                        <small>{lang("courses.createTip")}</small>
                        <FormMessage />
                    </div>
                    <div className="footer">
                        <Button className="primary" text={lang("courses.create")} type="submit"
                                onClick={(e) => this.handleSubmit(e, this.createCourse)} />
                        <Button className="danger" text={lang("courses.cancel")}
                                onClick={() => this.props.setModal(false)} />
                    </div>
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        simulations: state.user.simulations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        fetchUserData: () => dispatch(fetchUserData()),
        createAlert: alert => dispatch(createAlert(alert)),
        initRequest: () => dispatch(initRequest()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateCourse));