import * as types from "../actions/simulation";
import {completeTeamsDecisions, getDefaultDecision} from "./simulation-utility";
import {set} from "dot-prop-immutable";
import {findIndex} from "lodash";

const initialState = {
    init: false,
    market: {},
    saveState: false,
    csvGenerator: null,
    setup: {
        selected: {},
    },
    teams: [],
};

export const simulation = (state = initialState, action) => {
    switch (action.type) {
        
        case types.INIT_SIMULATION :
            return initSimulation(state, action);
        case types.INIT_FINISH :
            return initFinish(state, action);
        case types.INIT_REQUEST :
            return initRequest(state, action);
        case types.CHANGE_DECISION :
            return changeDecision(state, action);
        case types.SELECT_TEAM :
            return selectTeam(state, action);
        case types.SET_CALCULATED :
            return setCalculated(state, action);
        case types.SET_SAVE_STATE :
            return setSaveState(state, action);
        case types.SELECT_PERIOD :
            return selectPeriod(state, action);
        case types.SET_CSV_DATA_GENERATOR :
            return setCSVGenerator(state, action);
        case types.SET_ENGINE_DEA_GENERATOR :
            return setEngineDEAGenerator(state, action);
        default :
            return state;
    }
};

const initSimulation = (state, action) => {
    const {setup, teams} = action.init;
    
    // As default choose my team and find my team index.
    let teamID = setup.myTeam.id;
    let teamIndex = findIndex(teams, (o) => o.id === teamID);
    // My team is not in the teams, select the first team
    if (teamIndex === -1) {
        teamID = teams[0].id;
        teamIndex = 0;
    }
    
    const selected = {
        teamID,
        period: setup.period.current,
        teamIndex,
    };
    const defaultDecision = getDefaultDecision(setup.decisions);
    const teamsWithCompletedDecisions = completeTeamsDecisions(teams, defaultDecision, setup.period.current);
    return {
        ...state,
        ...action.init,
        setup: {
            ...setup,
            selected,
        },
        teams: teamsWithCompletedDecisions,
    };
};

const initFinish = (state) => {
    return {
        ...state,
        init: true,
    };
};

const initRequest = (state) => {
    return {
        ...state,
        init: false,
    };
};

const changeDecision = (state, {path, value}) => {
    if (typeof value === "string" && !isNaN(Number(value)))
        value = Number(value);
    const {period, teamIndex} = state.setup.selected;
    const stateWithNewDecisions = set(state, `teams.${teamIndex}.decisions.${period}.${path}`, value);
    return set(stateWithNewDecisions, "saveState", false);
};

const selectTeam = (state, action) => {
    
    let {selector} = action;
    let id = selector;
    let index = findIndex(state.teams, o => o.id === selector);
    if (index === -1) {
        id = null;
        index = selector;
    }
    
    return {
        ...state,
        setup: {
            ...state.setup,
            selected: {
                ...state.setup.selected,
                teamID: id,
                teamIndex: index,
            },
        },
    };
};

const setCalculated = (state) => {
    return {
        ...state,
        isCalculated: true,
    };
};

const setSaveState = (state, action) => {
    return {
        ...state,
        saveState: action.status,
    };
};

const selectPeriod = (state, action) => {
    const period = Math.max(Math.min(state.setup.period.current, action.period), 1);
    return {
        ...state,
        setup: {
            ...state.setup,
            selected: {
                ...state.setup.selected,
                period,
            },
        },
    };
};

const setCSVGenerator = (state, action) => {
    return {
        ...state,
        csvGenerator: action.generator,
        csvGeneratorFile: action.file,
    };
};

const setEngineDEAGenerator = (state, action) => ({
    ...state,
    deaGenerator: action.generator,
});