import React from "react";

import "./Dropdown.css";

export const Dropdown = props => {

    return (
        <div className = {props.className ? 'dropdown ' + props.className : 'dropdown'} >
            <ul>
                { props.children }
            </ul>
        </div>
    )

}