import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";

const CourseName = ({name}) => (
    <div className='line'>
        <div>{lang("lobby.name")}</div>
        <div>{name}</div>
    </div>
);

export default CourseName;