import {lang} from "../../../../assets/js/language-utils.js";
import {Button} from "../../../../components/Button/Button.js";
import Invitation from "../../../../components/Lobby/Invitation.js";
import Box from "../../../../components/Box/Box";
import {isEmptyArr, isEmptyObj} from "../../../../assets/js/utility.js";
import Team from "../../../../components/Lobby/Team.js";
import React from "react";
import {createAlert, setModal} from "../../../../store/actions/ui.js";
import {connect} from "react-redux";

const MyTeam = ({selectedCourse, userID, createAlert, setModal}) => {
    
    const myTeam = selectedCourse.myTeam;
    const invitations = selectedCourse.invitations;
    const numbOfMembers = myTeam === null || typeof myTeam === "undefined" ? 0 : myTeam.members.length;
    let isCaptain;
    try {
        isCaptain = userID.toString() === myTeam.members.filter(member => member.isCaptain)[0].id;
    } catch (error) {
        isCaptain = false;
    }
    
    const NoTeam = ({isInvited}) => (
        <div className='no-team'>
            <div className='line'>
                <div>{lang("lobby.noTeam")}</div>
                <Button text={lang("lobby.create")} className={"primary" + (isInvited ? " fake-disabled" : "")}
                        title={lang("lobby.newTeam")}
                        onClick={() => {
                            if (isInvited) {
                                createAlert({
                                    type: "danger",
                                    msg: lang("lobby.alreadyInvited"),
                                });
                            } else {
                                setModal("createTeam", selectedCourse);
                            }
                        }} />
            </div>
        </div>
    );
    
    const InviteLine = () => {
        if (numbOfMembers >= selectedCourse.maxMember) {
            return null;
        }
        return (
            <div className='invite'>
                <div className='line'>
                    <div className='mr-1'>{lang("lobby.captainTip")}</div>
                    <Button text={lang("lobby.invite")} className='primary'
                            title={lang("lobby.inviteTeammate")}
                            onClick={() => setModal("inviteUser", selectedCourse)} />
                </div>
            </div>
        );
    };
    
    const Invitations = () => {
        return <div className='invitations'>
            {invitations.map(invitation => {
                const invitationObj = {...invitation, courseID: selectedCourse.courseId, key: invitation.id};
                return <Invitation {...invitationObj} />;
            })}
        </div>;
    };
    
    return (
        <Box title={lang("lobby.myTeam")}>
            {isEmptyArr(myTeam) || isEmptyObj(myTeam) || myTeam === null ? (
                <div>
                    <NoTeam isInvited={invitations.length > 0} />
                    <Invitations />
                </div>
            ) : (
                <div>
                    <Team id={myTeam.id} name={myTeam.name} members={myTeam.members}
                          courseID={selectedCourse.courseId} userDelete={isCaptain} sum />
                    <Invitations />
                    {isCaptain ? <InviteLine /> : null}
                </div>
            )}
        </Box>
    );
};

const mapStateToProps = state => ({
    userID: state.user.profile.userId,
});

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
    createAlert: alert => dispatch(createAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);