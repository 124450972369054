// External
import React, {Component} from "react";
import {connect} from "react-redux";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";

class CreateTeam extends Component {

    state = {
        teamName: '',
        courseID: this.props.course.courseId,
        isFetching: false
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.teamName.length < 3) {
            this.setState({
                msg: {
                    type: 'error',
                    text: lang('team.minMsg')
                }
            })
        } else {
            this.createTeam()
        }
    }

    createTeam = () => {
        this.setState({ isFetching: true })
        const data = qs.stringify({testHash, teamName: this.state.teamName});
            apiv3.post(`/course/${this.state.courseID}/team`, data)
        .then(res => {
            this.setState({ isFetching: false })
            if (res.data.isValid) {
                this.createTeamSuccess()
            } else {
                this.createTeamFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.createTeamFailed(err)
        })
    }

    createTeamSuccess = () => {
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: lang('team.createSuccess')
        })
        this.props.setModal(false)
    }

    createTeamFailed = (err) => {
        this.setState({
            msg: {
                type: 'error',
                text: err
            }
        })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('team.process')}>
                    <Loader />
                </Modal>
            )
        }

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        return (
            <Modal title={lang('team.title')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.createTeam) }>
                    <div className="body">
 
                         <InputText
                            label =         { lang('team.teamName') }
                            type =          'text'
                            name =          'teamName'
                            placeholder =   { lang('team.teamNamePlaceholder') }
                            value =         { this.state.teamName }
                            onChange =      { this.handleInputChange }
                            focus
                        />

                        <small>{lang('team.tip')}</small>
                        <FormMessage />
                    </div>
                    <div className="footer">
                        <Button className='primary' text={lang('team.create')} type='submit' onClick = { (e) => this.handleSubmit(e, this.createTeam) }/>
                        <Button className='danger' text={lang('team.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        course: state.ui.modal.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        createAlert: alert => dispatch(createAlert(alert)),
        fetchUserData: () => dispatch(fetchUserData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam)