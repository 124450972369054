import {Button} from "../../../Button/Button";
import {lang} from "../../../../assets/js/language-utils";
import React from "react";
import {selectPeriod} from "../../../../store/actions/simulation";
import {connect} from "react-redux";

const PeriodSelect = ({selectedPeriod, currentPeriod, finalPeriod, selectPeriod}) => {

    const Period = () => {
        if (selectedPeriod > finalPeriod) {
            return <div>{lang("pageHeader.results")}</div>;
        } else {
            return <div>{lang("pageHeader.period")} {selectedPeriod - 1}/{finalPeriod}</div>;
        }
    };

    return (
        <div className='d-flex'>
            <Button className='left-arrow trans small' icon='fas fa-angle-left'
                    onClick={() => selectPeriod(selectedPeriod - 1)} disabled={selectedPeriod <= 1}/>
            <Period/>
            <Button className='right-arrow trans small' icon='fas fa-angle-right'
                           onClick={() => selectPeriod(selectedPeriod + 1)}
                           disabled={selectedPeriod >= currentPeriod}/>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        selectedPeriod: state.simulation.setup.selected.period,
        currentPeriod: state.simulation.setup.period.current,
        finalPeriod: state.simulation.setup.period.final,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectPeriod: period => dispatch(selectPeriod(period)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSelect);