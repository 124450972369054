import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";

const MembersPerTeam = ({min, max}) => (
    <div className='line'>
        <div>{lang("lobby.membersPerTeam")}</div>
        <div>{min === max ? min : min + " - " + max}</div>
    </div>
);

export default MembersPerTeam;