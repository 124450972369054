import {connect} from "react-redux";
import React, {useState} from "react";
import Modal from "../../Modal";
import {InputSelect} from "../../../Form/Inputs";
import {Button} from "../../../Button/Button";
import qs from "qs";
import {apiv3, testHash} from "../../../../axios";
import {createAlert} from "../../../../store/actions/ui";
import {lang} from "../../../../assets/js/language-utils";

const DELETE_ID = "999999999";

const MoveTeams = ({course, round, createAlert}) => {
    
    const [selectedRound, setSelectedRound] = useState(round);
    
    
    let teamsWithPools = (selectedRound.pools ?? []).reduce((acc, next) => [...acc, ...next.teams.map(team => ({
        ...team,
        originalPool: next.id,
        pool: next.id,
    }))], []);
    
    const [teams, setTeams] = useState(teamsWithPools);
    
    const pools = [...(selectedRound.pools ?? []), {id: DELETE_ID, name: lang("lobby.removeFromPools")}];
    
    const teamList = teams.map((team, i) => {
        return (
            <InputSelect
                key={i}
                label={team.name}
                options={pools}
                selected={team.pool}
                onChange={e => {
                    setTeams(teams.map(t => t.id === team.id ? {...t, pool: e.target.value} : t));
                }}/>
        );
        
    });
    
    return (
        <Modal title={lang("lobby.manageTeamsTitle")}>
            <form className="form">
                <div className="body">
                    <h4 key={4} className="field">{lang("lobby.teamsLabel")}</h4>
                    {teamList}
                    <Button type="button"
                            key={5}
                            onClick={e => {
                                let promises = [];
                        
                                const pools = new Set();
                                teams.forEach(team => pools.add(team.pool));

                                pools.forEach(pool => {
                                    const newTeamsInPool = teams.filter(team => team.pool !== team.originalPool && team.pool === pool);
                                    let data = qs.stringify({
                                        teamIdArray: newTeamsInPool.map(team => team.id),
                                        poolID: pool === DELETE_ID ? null : pool,
                                        courseID: course.courseId,
                                        roundID: round.id,
                                        type: pool === DELETE_ID ? "delete" : "move",
                                        testHash,
                                    });
                                    promises.push(apiv3.post(`/course/${course.courseId}/moveTeam`, data)
                                        .then(res => {
                                            if (!res.data.isValid) {
                                                createAlert({
                                                    type: "danger",
                                                    msg: res.data.msg,
                                                });
                                                return false;
                                            }
                                            return true;
                                        })
                                        .catch(err => {
                                            createAlert({
                                                type: "danger",
                                                msg: err,
                                            });
                                            return false;
                                        }));
                                });

                                Promise.all(promises).then(results => {
                                    if (results.every(result => result)) {
                                        window.location.reload();
                                    }
                                });
                            }}
                            className="primary float-right"
                            text={lang("lobby.moveTeamsButton")}/>
                </div>
            </form>
        </Modal>
    );
};

const mapStateToProps = state => ({
    course: state.ui.modal.data.course,
    round: state.ui.modal.data.round,
});

const mapDispatchToProps = dispatch => ({
    createAlert: alert => dispatch(createAlert(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveTeams);