import React, {useState} from "react";
import {connect} from "react-redux";
import Modal from "../../Modal";
import {InputDate, InputSelect, InputText} from "../../../Form/Inputs";
import {Button} from "../../../Button/Button";
import {setModal} from "../../../../store/actions/ui";

const AddLicense = ({handler, setModal, edit, name, expire, startDate, licenceType}) => {
    
    const [licenseName, setLicenseName] = useState(edit ? name : "");
    let temp1 = new Date();
    temp1.setHours(0, 0, 0, 0);
    if (edit) {
        temp1 = new Date(startDate);
    }
    let temp2 = new Date();
    temp2.setHours(0, 0, 0, 0);
    if (edit) {
        temp2 = new Date(expire);
    }
    const [start, setStart] = useState(temp1);
    const [expiration, setExpiration] = useState(temp2);
    
    const licenceTypes = [
        {id: 1, name: "Basic"},
        {id: 2, name: "Premium"},
        {id: 3, name: "Pro"},
    ];
    const [type, setType] = useState(edit ? licenceType : 1);
    
    return (
        <Modal
            title={edit ? "Edit licence" : "New licence"}>
            <form className="form">
                <div className="body">
                    <InputText
                        type="text"
                        label={"Name"}
                        maxLength={50}
                        value={licenseName}
                        focus={true}
                        onChange={e => setLicenseName(e.target.value)}/>
                    <InputDate
                        label={"Start date"}
                        dateFormat="yyyy.MM.dd"
                        selected={start}
                        onChange={date => setStart(date)}
                        showTimeSelect={false}/>
                    <InputDate
                        label={"End date"}
                        dateFormat="yyyy.MM.dd."
                        selected={expiration}
                        onChange={date => setExpiration(date)}
                        showTimeSelect={false}/>
                    <InputSelect
                        selected={type}
                        options={licenceTypes}
                        label={"Type"}
                        onChange={e => setType(e.target.value)}/>
                    <Button
                        className="primary float-right"
                        type="button"
                        text={edit ? "Edit" : "Add"}
                        onClick={() => {
                            handler(licenseName, start, expiration, type);
                            setModal(null);
                        }}/>
                </div>
            </form>
        </Modal>
    );
};

const mapStateToProps = state => ({
    handler: state.ui.modal.data.handler,
    edit: state.ui.modal.data.edit ?? false,
    name: state.ui.modal.data.name,
    expire: state.ui.modal.data.expire,
    startDate: state.ui.modal.data.startDate,
    licenceType: state.ui.modal.data.licenceType,
});

const mapDispatchToProps = dispatch => ({
    setModal: modal => dispatch(setModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLicense);