import React from "react";
import {language} from "../../store/reducers/user";
import {sl} from "../../views/Simulation";
import {get} from "lodash";

export function lang(path) {
    return get(language, path);
}

export function langAsHtml(path) {
    return <span dangerouslySetInnerHTML={{__html: lang(path)}} />;
}

export function simLang(path, error = true) {
    let result = get(sl, path);
    if (!result && error) {
        console.debug("Missing lang value:", path);
        result = "!!Error!! " + path;
    }
    return result;
}

export const doesLangExist = path => {
    return !!get(sl, path);
};

export function applyLang(func, path, decimal = 0) {
    return {
        show: simLang(path),
        value: func,
        decimal,
        tip: simLang(path + "Desc", false),
    };
}