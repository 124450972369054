import {Button} from "../../../Button/Button";
import {lang} from "../../../../assets/js/language-utils";
import React from "react";
import {connect} from "react-redux";
import {setModal} from "../../../../store/actions/ui";

const SelectButton = ({activeCourse, setModal}) => (
    <Button
        className='primary buckle'
        text={lang("pageHeader.view")}
        icon='fas fa-caret-down'
        onClick={() => {
            setModal("selectMarket", activeCourse);
        }}
    />
);

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (modal, data) => dispatch(setModal(modal, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectButton);