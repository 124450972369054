import React, { useEffect, useState } from "react";
import { remainingTime } from "../../../../assets/js/utility";
import { connect } from "react-redux";
import { createAlert } from "../../../../store/actions/ui";
import { lang } from "../../../../assets/js/language-utils";

const Deadline = ({ deadline, createAlert }) => {
    let [elapsed, setElapsed] = useState(0);
    let [createdAlert, setCreatedAlert] = useState(new Date());
    let [serverTime, setServerTime] = useState(new Date(localStorage.getItem('ecoSrvDateTime')));

    useEffect(() => {
        const interval = setInterval(() => {
            setServerTime(prevTime => new Date(prevTime.getTime() + 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const remainingSeconds = Math.floor(
        (new Date(deadline.next) - serverTime) / 1000
    );

    let timeout = setTimeout(() => {
        setElapsed(elapsed + 1);
    }, 1000);

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    });

    useEffect(() => {
        const currentTime = new Date();
        if (
            currentTime - createdAlert > 10 * 1000 &&
            remainingSeconds < 5 * 60 &&
            remainingSeconds > 4 * 60 + 58
        ) {
            setCreatedAlert(currentTime);
            createAlert({
                type: "danger",
                msg: lang("pageHeader.deadlineClose")
            });
        }
    }, [createdAlert, remainingSeconds, createAlert]);

    return <div>{remainingTime(serverTime, deadline.next, elapsed)}</div>;
};

const mapStateToProps = (state) => {
    return {
        deadline: state.simulation.setup.deadline
    };
};

const mapDispatchToProps = (dispatch) => ({
    createAlert: (alert) => dispatch(createAlert(alert))
});


export default connect(mapStateToProps, mapDispatchToProps)(Deadline);