import React, { useState } from "react";
import { Route } from "react-router-dom";
import Box from "../components/Box/Box";
import { InputText } from "../components/Form/Inputs";
import {apiv3} from "../axios";
import "../components/Table/Table.css";
import {lang} from "../assets/js/language-utils";

export default function UserList() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");

    const handleInputChange = (e) => {
        const searchValue = e.target.value;
        setSearch(searchValue);

        if (searchValue.length >= 4) {
            getUsers(searchValue);
        } else {
            setUsers([]);
        }
    };

    const getUsers = (searchValue) => {
        const uri = `admin/user?search=${searchValue}`;
        apiv3
            .get(uri)
            .then((res) => {
                if (res.data.isValid) {
                    setUsers(res.data.data);
                } else {
                    console.error(res.data.msg);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const SearchBar = () => (
        <div className="form">
            <InputText
                type="text"
                name="search"
                placeholder={lang("admin.userSearch")}
                value={search}
                onChange={handleInputChange}
                focus
            />
        </div>
    );

    const UsersTable = ({ users }) =>
        users.length === 0 ? (
            <p className="my-2">{lang("admin.noUser")}</p>
        ) : (
            <div className="table">
                <table>
                    <thead>
                    <tr>
                        <th>{lang("admin.userTable.id")}</th>
                        <th>{lang("admin.userTable.email")}</th>
                        <th>{lang("admin.userTable.name")}</th>
                        <th>{lang("admin.userTable.hasLicence")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user) => (
                        <User key={user.id} {...user} />
                    ))}
                    </tbody>
                </table>
            </div>
        );

    const User = ({ id, email, name, hasLicence }) => (
        <Route
            render={({ history }) => (
                <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        history.push("/admin/users/" + id);
                    }}
                >
                    <td>{id}</td>
                    <td style={{ textAlign: "left" }}>{email}</td>
                    <td
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: name }}
                    />
                    <td style={{ textAlign: "center" }}>
                        {hasLicence ? <i className="fas fa-check" /> : null}
                    </td>
                </tr>
            )}
        />
    );

    return (
        <div className="admin">
            <div className="container fluid">
                <Box title="Users">
                    <SearchBar />
                    <UsersTable users={users} />
                </Box>
            </div>
        </div>
    );
}