// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
import {initRequest} from "../../../../store/actions/simulation";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputDate, InputRange, InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";
import moment from "moment";

class EditRound extends Component {

    state = {
        roundName: this.props.round.name || '',
        maxTeam: this.props.round.maxTeam || 5,
        periods: this.props.round.periods || [],
        endTime: this.props.round.endTime,
        roundID: this.props.round.id,
        courseID: this.props.course.courseId,
        isFetching: false
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleRangeChange = (value) => {
        this.setState({
            maxTeam: value[0]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.roundName.length < 3) {
            this.setState({
                msg: {
                    type: 'error',
                    text: lang('round.minName')
                }
            })
        } else {
            this.editRound()
        }
    }

    editRound = () => {
        this.setState({isFetching: true})
        const data = qs.stringify({...this.state, testHash})
        apiv3.put(`/course/${this.state.courseID}/round`, data)
        .then(res => {
            this.setState({isFetching: false})
            if (res.data.isValid) {
                this.editRoundSuccess()
            } else {
                this.editRoundFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.editRoundFailed(err)
        })
    }

    editRoundSuccess = () => {
        this.props.setModal(false)
        this.props.fetchUserData()
        this.props.initRequest()
        this.props.createAlert({
            type: 'success',
            msg: lang('round.updateSuccess')
        })
    }

    editRoundFailed = (err) => {
        this.setState({
            msg: {
                type: 'error',
                text: err
            }
        })
    }

    dateChangeHandler = (date, index, type) => {
        const formatDate = date ? moment(date).format() : date
        type === 'final' ? (
            this.setState({endTime: formatDate})
        ) : this.updatePeriod(formatDate, index, type)
    }

    addPeriod = () => {
        const lastPeriod = this.state.periods[this.state.periods.length - 1];
        const nextStart = new Date(lastPeriod.end);
        nextStart.setDate(nextStart.getDate() + 1);
        const nextEnd = new Date(lastPeriod.end);
        nextEnd.setDate(nextEnd.getDate() + 2);
        this.setState(prevState => ({
            periods: [...prevState.periods, {start: moment(nextStart).format(), end: moment(nextEnd).format()}]
        }))
    }

    updatePeriod = (date, index, type) => {
        const periods= this.state.periods;
        periods[index][type] = date;
        this.setState({periods});
        // // let newPeriods = this.state.periods.map((per, i) => (
        // //     index === i ? {...per, [type]: date} : per
        // // ))
        // this.setState({
        //     periods: newPeriods
        // })
    }

    deletePeriod = (index) => {
        let newPeriods = this.state.periods.filter((per, i) => (
            index !== i
        ))
        this.setState({
            periods: newPeriods
        })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('round.updateProcess')}>
                    <Loader />
                </Modal>
            )
        }

        const numbOfPeriods = typeof this.state.periods === 'undefined' ? 0 : this.state.periods.length

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        const PeriodTiming = (props) => (
            <div className='line'>
                <div className='mr-1 mt-1'>P{ props.index + 1 }</div>
                <InputDate
                    label = {lang('round.start')}
                    selected = { props.period.start }
                    onChange = { date => this.dateChangeHandler(date, props.index, 'start') }
                />
                <InputDate
                    label = {lang('round.end')}
                    selected = { props.period.end }
                    onChange = { date => this.dateChangeHandler(date, props.index, 'end') }
                />
                { props.index === numbOfPeriods - 1 ? (
                    <Button className='danger small mt-1' icon='fas fa-trash' onClick = { () => this.deletePeriod(props.index) } />
                ) : null }
            </div>
        )

        return (
            <Modal title={lang('round.updateTitle')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.editRound) }>
                    <div className='body'>

                        <div className="row">
                            <div className="col-lg-6">
                                <InputText
                                    label =         { lang('round.name') }
                                    type =          'text'
                                    name =          'roundName'
                                    placeholder =   { lang('round.namePlaceholder') }
                                    value =         { this.state.roundName }
                                    onChange =      { this.handleInputChange }
                                    focus
                                />                      
                            </div>
                            <div className="col-lg-6">
                                <InputRange
                                    label =     { lang('round.maxTeamsLabel') }
                                    name =      'maxTeam'
                                    min =       { 1 }
                                    max =       { 10 }
                                    step =      { 1 }
                                    minText =   { lang('round.maxTeams') }
                                    value =     { [this.state.maxTeam] }
                                    onChange =  { this.handleRangeChange }
                                    dots
                                />
                            </div>
                        </div>

                        { this.state.periods.map((period, index) => (
                            <PeriodTiming key = { index } index = { index } period = { period }/>
                        ) ) }

                        <div className='line'>
                            <Button className='primary' icon='fas fa-plus' type='button' onClick = { () => this.addPeriod() } />
                        </div>
                        
                        <small>{lang('round.periodTip')}</small>

                        <div className="mt-1">
                            <InputDate
                                label = {lang('round.finalResultsDate')}
                                className = 'mt-1'
                                selected = { this.state.endTime }
                                onChange = { date => this.dateChangeHandler(date, null, 'final') }
                            />
                        </div>

                        <small>{lang('round.updateTip')}</small>
                        <FormMessage />
                    </div>
                    <div className='footer'>
                        <Button className='primary' text={lang('round.save')} type='submit' onClick = { (e) => this.handleSubmit(e, this.editRound) }/>
                        <Button className='danger' text={lang('round.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        course: state.ui.modal.data.course,
        round: state.ui.modal.data.round
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        fetchUserData: () => dispatch(fetchUserData()),
        createAlert: alert => dispatch(createAlert(alert)),
        initRequest: () => dispatch(initRequest())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRound))