/**
 * @file - Profile view.
 */

// External
import React, {Component} from "react";
// Components
import {CardBox} from "../components/Card/CardBox";
import PageHeader from "../components/PageHeader/PageHeader";

class Profile extends Component {

    render() {
    
        return (
            <div className='profile'>
            <PageHeader />
                <div className="container fluid">
                    <CardBox >
                        <h3>Profile Page</h3>
                    </CardBox>
                </div>
            </div>
        )
    }

}

export default Profile