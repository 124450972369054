/**
 * @file - Rendering the courses.
 */

// External
import React, {useState} from "react";
import {connect} from "react-redux";
import qs from "qs";
// Actions
import {createAlert, setModal} from "../../store/actions/ui";
import {activateCourse} from "../../store/actions/user";
// Components
import {CardBox} from "../../components/Card/CardBox";
import {Button} from "../../components/Button/Button";
import Box from "../../components/Box/Box";
import PageHeader from "../../components/PageHeader/PageHeader";
import {SimIcon} from "../../model/SimTypeHandler";
// Assets
import courseImg from "../../assets/img/courses.svg";
// Request
import {apiv3, testHash} from "../../axios";
import {getCourseById} from "../../assets/js/utility";
import {lang, langAsHtml} from "../../assets/js/language-utils";
import {CourseSearch} from "./CourseSearch/CourseSearch";

const mapStateToProps = state => {
    let haveLicence = state?.user?.profile?.haveLicence ?? false;
    return {
        user: state.user,
        simulations: state.user.simulations,
        courses: state.user.courses,
        haveLicence: haveLicence,
        selected: state.user.selected,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (name, data) => dispatch(setModal(name, data)),
        activateCourse: id => dispatch(activateCourse(id)),
        createAlert: alert => dispatch(createAlert(alert)),
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function activateAndNavigate(courses, courseId, activateCourse, createAlert) {
    return new Promise((resolve, reject) => {
        if (getCourseById(courseId, courses).active) {
            resolve();
            return;
        }

        const data = qs.stringify({testHash})
        apiv3.put(`user/course/${courseId}/`, data)
            .then(res => {
                if (res.data.msg) {
                    createAlert({type: res.data.isValid ? "success" : "danger", msg: res.data.msg});
                } else {
                    courses[courses.findIndex(c => c.courseId ===  courseId)] = res.data.data.courseData;
                    activateCourse(courseId);
                }
                resolve();
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
}

const CourseList = ({history, simulations, user}) => {
    const [searchCourseName, setSearchCourseName] = useState("");
    const [searchCourseCode, setSearchCourseCode] = useState("");
    const [onlyEcoSim, setOnlyEcoSim] = useState(false);
    const [notEcoSim, setNotEcoSim] = useState(false);
    const [simulationType, setSimulationType] = useState("");
    const [isRunning, setIsRunning] = useState(false);

    return (
        <div className="container fluid">
            {
                user.profile.licenceType === "admin" ? (
                    <CourseSearch
                        courseName={searchCourseName}
                        courseCode={searchCourseCode}
                        setCourseName={setSearchCourseName}
                        setCourseCode={setSearchCourseCode}
                        onlyEcoSim={onlyEcoSim}
                        setOnlyEcoSim={setOnlyEcoSim}
                        notEcoSim={notEcoSim}
                        setNotEcoSim={setNotEcoSim}
                        simulationType={simulationType}
                        setSimulationType={setSimulationType}
                        isRunning={isRunning}
                        setIsRunning={setIsRunning}
                        simulations={simulations}
                    />
                ) : null
            }
            <div className="row">
                <CourseAction/>
                <MapCourses
                    history={history}
                    searchCourseName={searchCourseName.toLowerCase()}
                    searchCourseCode={searchCourseCode.toLowerCase()}
                    onlyEcoSim={onlyEcoSim}
                    notEcoSim={notEcoSim}
                    isRunning={isRunning}
                    simulationType={simulationType}
                />
            </div>
        </div>
    );
};

const CourseAction = connector(({setModal, haveLicence}) => {
    const title = lang("courses.joinCourse");
    return (
        <div className="col-lg-3 col-md-6">
            <Box title={title} className="course" nonCollapse>
                <div className="line mt-1">
                    <Button text={lang("courses.join")} className="primary full"
                            onClick={() => setModal("joinCourse")}/>
                    {haveLicence ? (
                        <Button title={lang("courses.createCourse")} icon="fas fa-plus" className="danger full"
                                onClick={() => setModal("createCourse")}/>
                    ) : null}
                </div>
            </Box>
        </div>
    );
});

// Update MapCourses component
const MapCourses = connector(({courses, searchCourseName, searchCourseCode, onlyEcoSim, notEcoSim, isRunning, simulationType, history}) =>
    courses
        .filter(course => {
            if (onlyEcoSim && !course.creatorEmail?.includes("ecosim.hu")) {
                return false;
            }
            if (notEcoSim && course.creatorEmail?.includes("ecosim.hu")) {
                console.log("notEcoSim", course.creatorEmail);
                return false;
            }
            if (simulationType && course.simulationId !== simulationType) {
                return false;
            }
            if (isRunning && !course.isRunning) {
                return false;
            }
            if (!searchCourseName && !searchCourseCode) {
                return true;
            }
            if (searchCourseName && course.courseName.toLowerCase().indexOf(searchCourseName) !== -1) {
                return true;
            }
            if (course.coursePassword !== undefined) {
                return searchCourseCode && course.coursePassword.toLowerCase().indexOf(searchCourseCode) !== -1;
            }
        })
        .map((course, index) => {
            return <div key={index} className="col-lg-3 col-md-6">
                <Course history={history} {...course} />
            </div>;
        })
);

const simulation = (courses, courseId, activateCourse, createAlert, history) => {
    activateAndNavigate(courses, courseId, activateCourse, createAlert)
        .then(() => {
            history.push("/simulation");
        })
        .catch((error) => {
            console.error(error);
        });
}

const Course = connector(({courseName, active, simulationId, courseId, history, setModal, courses, isOwner, activateCourse, createAlert, isRunning}) => (
    <Box
        title={courseName}
        icon={<SimIcon simulationId={simulationId}/>}
        className={active ? "active course" : "course"}
        nonCollapse
        onClick={() => activateAndNavigate(courses, courseId, activateCourse, createAlert)}>
        <div className="line mt-1">
            <Button text={lang("menu.lobby")} className="info full"
                    onClick={() => history.push("/lobby")}/>
            <Button text={lang("menu.simulation")} className="info full"
                    onClick={() => simulation(courses, courseId, activateCourse, createAlert, history)}/>

            {isOwner ? (
                <Button className="danger small" icon="far fa-trash-alt"
                        onClick={() => setModal("deleteCourse", getCourseById(courseId, courses))}/>
            ) : null}

        </div>
    </Box>
));

class Courses extends React.Component {
    render() {
        const haveActiveCourses = (this.props.courses && this.props.courses.length > 0);

        const WelcomeCard = () => (
            <CardBox img={courseImg}>
                <div className="main">{langAsHtml("courses.desc1")}</div>
                <div className="main">{langAsHtml("courses.desc2")}</div>
                <div className="line">
                    <Button className="primary" text={lang("courses.joinCourse")}
                            onClick={() => this.props.setModal("joinCourse")}/>

                    {this.props.haveLicence ? (
                        <Button className="danger" text={lang("courses.createCourse")}
                                onClick={() => this.props.setModal("createCourse")}/>
                    ) : null}
                </div>
            </CardBox>
        );

        return (
            <div className="Courses">
                <PageHeader/>
                {haveActiveCourses ? <CourseList history={this.props.history} simulations={this.props.simulations} user={this.props.user}/> : <WelcomeCard/>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses);