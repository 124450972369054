/**
 * @file - Rendering the main components in the dashboard.
 */

// External
import React, {Component} from "react";
import {connect} from "react-redux";
// Actions
import {setModal} from "../store/actions/ui";
// Components
import {CardBox} from "../components/Card/CardBox";
import {Button} from "../components/Button/Button";
import PageHeader from "../components/PageHeader/PageHeader";
// Utilities
import {getCourseById} from "../assets/js/utility";
// Assets
import img from "../assets/img/dashboard.svg";
import {lang, langAsHtml} from "../assets/js/language-utils";

class Dashboard extends Component {

    render() {

        const WelcomeCard = () => (
            <CardBox img={img} >
                <h3>{lang('dashboard.welcomeTitle')}</h3>
                <div className='main'>{langAsHtml('dashboard.welcomeDesc')}</div>
                <div className='line'>
                    <Button className='primary' text={lang('dashboard.joinCourse')} onClick = { () => this.props.setModal('joinCourse') } />
                    { this.props.haveLicence ? (
                            <Button className='danger' text={lang('dashboard.createCourse')} onClick = { () => this.props.setModal('createCourse') } />    
                        ) : null }
                </div>
            </CardBox>
        )

        const { courses, selected } = this.props

        if (!selected.courseID || !courses) {
            return (
                <div className='Dashboard'>
                    <PageHeader />
                    <div className='container fluid'>
                        <WelcomeCard />
                    </div>
                </div>
            )
        }

        const selectedCourse = getCourseById(selected.courseID, courses)
        const SelectedCourseCard = () => {

            return (
                <CardBox img={img} >
                    <h3>{ selectedCourse.name }</h3>
                    <div className='line'>
                        <div>
                            { langAsHtml('dashboard.simulationDesc') }
                        </div>
                    </div>
                    <div className='line mt-1'>
                        <Button className='primary' text={lang('menu.lobby')} onClick={() => this.props.history.push('/lobby')} />
                        <Button className='primary' text={lang('menu.simulation')} onClick={() => this.props.history.push('/simulation')} />
                    </div>
                </CardBox>
            )
        }

        return (
            <div className='Dashboard'>
                <PageHeader />
                <div className='container fluid'>
                    <SelectedCourseCard />
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    let haveLicence = false
    try {
        haveLicence = state.user.profile.haveLicence
    } catch (e) {}
    return {
        courses: state.user.courses,
        haveLicence: haveLicence,
        selected: state.user.selected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)