import {lang} from "../../../../../../assets/js/language-utils.js";
import moment from "moment";
import React from "react";

const Periods = ({round}) => (
    <div className='periods'>
        {round.periods.map((period, index) => (
            <div className='line' key={index}>
                <div>{lang("lobby.period")} {index + 1}</div>
                <div>{moment(period.start).format("MM.DD HH:mm")}</div>
                <div>{moment(period.end).format("MM.DD HH:mm")}</div>
            </div>
        ))}
    </div>
);

export default Periods;