import React from "react";
import PropTypes from "prop-types";

import "./Button.css";
import {Loader} from "../Loader/Loader";

export const Button = ({
                           className,
                           disabled = false,
                           icon = "",
                           loader = null,
                           text = "",
                           title = null,
                           type = "primary",
                           onClick = () => {},
                       }) => {
    let iconElement = null;
    
    if (icon) {
        if (icon.startsWith("icon:")) {
            iconElement = (
                <i style={{
                    display: "inline-block",
                    height: typeof text === "undefined" ? "1.5em" : "100%",
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/img/" + icon.replace("icon:", "")})`,
                    width: typeof text === "undefined" ? "100%" : "1.5em",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    color: "transparent",
                    filter: "invert(100%)",
                    imageRendering: "smooth",
                }}>
                    M
                </i>
            );
        } else if (icon.startsWith("data") || icon.endsWith(".png") || icon.endsWith(".svg")) {
            iconElement = (
                <i style={{
                    display: "inline-block",
                    height: typeof text === "undefined" ? "100%" : "100%",
                    backgroundImage: `url(${icon})`,
                    width: typeof text === "undefined" ? "100%" : "1.5em",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    color: "transparent",
                    filter: "invert(100%)",
                    imageRendering: "smooth",
                }}>
                    M
                </i>
            );
        } else {
            iconElement = (<i className={icon} />);
        }
    }
    
    return (
        <button
            className={"button " + className}
            disabled={disabled}
            title={disabled ? title : (title ?? text ?? "")}
            type={type}
            onClick={onClick}>
            {loader ? <Loader /> : <span>
                {iconElement}
                {text ? <span className="text">{text}</span> : null}
            </span>}
        </button>
    );
    
};

Button.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    icon: PropTypes.string,
};