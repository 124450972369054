import React, {useState, useEffect} from "react";
import {Route} from "react-router-dom";
import Box from "../components/Box/Box";
import {apiv3, testHash} from "../axios";
import {Button} from "../components/Button/Button";
import {InputSelect, InputText} from "../components/Form/Inputs";
import {connect} from "react-redux";
import qs from "qs";
import {createAlert, setModal} from "../store/actions/ui";
import {lang} from "../assets/js/language-utils";

const mapStateToProps = state => ({
    availableSimulations: state.user.simulations,
});

const mapDispatchToProps = dispatch => ({
    createAlert: alert => dispatch(createAlert(alert)),
    setModal: (name, data) => dispatch(setModal(name, data)),
});

const AddSimulation = connect(mapStateToProps, mapDispatchToProps)(({
                                                                        availableSimulations,
                                                                        simulations,
                                                                        licenceID,
                                                                        addSimulation,
                                                                        createAlert,
                                                                    }) => {
    const selectableSimulations = availableSimulations.filter(simulation => simulations.findIndex(otherSimulation => simulation.id === otherSimulation.id) === -1);
    const [selectedSimulation, setSelectedSimulation] = useState(selectableSimulations[0]?.id);

    return selectableSimulations.length > 0 ? <div className='form m-0'>
        <h5>{ lang("admin.licence.addSimulation") }</h5>
        <br/>
        <InputSelect
            label={ lang("admin.licence.simulation") }
            selected={selectedSimulation}
            options={selectableSimulations}
            onChange={e => setSelectedSimulation(e.target.value)}/>
        <Button
            className="primary"
            text={ lang("admin.licence.add") }
            onClick={() => {
                const data = qs.stringify({testHash});
                apiv3.post(`/admin/licence/${licenceID}/simulation/${selectedSimulation}`, data)
                    .then(res => {
                        if (!res.data.isValid) {
                            createAlert({
                                type: "danger",
                                msg: res.data.msg,
                            });
                            return;
                        }
                        addSimulation(availableSimulations.find(sim => sim.id === selectedSimulation));
                    })
                    .catch(err => createAlert({type: "danger", msg: err}));
            }}/>
    </div> : null;

});

const AddUser = connect(null, mapDispatchToProps)(({licenceId, createAlert, addUser}) => {

    const [email, setEmail] = useState("");

    return (
        <div className="form m-0">
            <h5>{ lang("admin.licence.addUser") }</h5>
            <br/>
            <InputText
                type="text"
                label={ lang("admin.licence.email") }
                maxLength={100}
                value={email}
                onChange={e => setEmail(e.target.value)}/>
            <Button
                className="primary"
                text={ lang("admin.licence.add") }
                onClick={() => {
                    apiv3.post(`/admin/licence/${licenceId}/user/`, qs.stringify({testHash, email}))
                        .then(res => {
                            setEmail("");
                            addUser(email, res.data.data.username);
                            createAlert({
                                type: res.data.isValid ? "success" : "danger",
                                msg: res.data.msg,
                            });
                        });
                }}/>
        </div>
    );
});

const Licence = ({
                     match,
                     setModal,
                     createAlert,
                 }) => {
    const [licenceData, setLicenceData] = useState({
        id: '0',
        name: '',
        startDate: '',
        expire: '',
        simulations: [],
        licenceType: 0,
        users: [],
        courseCount: [],
        userCount: [],
    });

    const years = [...new Set([...licenceData.courseCount.map(item => item.year), ...licenceData.userCount.map(item => item.year)])];
    const [selectedYear, setSelectedYear] = useState(years[years.length-1] || '');

    useEffect(() => {
        const getLicence = () => {
            const { id } = match.params;
            const uri = `admin/licence/${id}`;
            apiv3
                .get(uri)
                .then((res) => {
                    if (res.data.isValid) {
                        setLicenceData({ ...res.data.data });
                    } else {
                        console.error(res.data.msg);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        };

        getLicence();
    }, [match.params]);

    useEffect(() => {
        setSelectedYear(years[years.length-1] || '');
    }, [licenceData]);

    const { name, expire, startDate, licenceType, simulations, users, courseCount, userCount } = licenceData;

    const filteredCourseCount = courseCount.find(item => item.year === selectedYear);
    const filteredUserCount = userCount.find(item => item.year === selectedYear);

    return (
        <div className='admin'>
            <div className="container fluid">
                <Box>
                    <h3>
                        {name}{' '}
                        <Button
                            className={"small trans"}
                            icon="fas fa-edit"
                            onClick={() => {
                                setModal('addLicense', {
                                    name,
                                    expire,
                                    startDate,
                                    licenceType,
                                    edit: true, handler: (name, startDate, expire, licenceType) => {
                                        startDate = startDate._i === undefined ? startDate : startDate._i
                                        expire = expire._i === undefined ? expire : expire._i
                                        apiv3.put(`/admin/licence/${match.params.id}/`, qs.stringify({
                                            name,
                                            startDate,
                                            expire,
                                            licenceType: +licenceType,
                                            testHash,
                                        }))
                                            .then(res => {
                                                createAlert({
                                                    type: res.data.isValid ? "success" : "danger",
                                                    msg: res.data.msg,
                                                });
                                            });
                                        setLicenceData(() => ({
                                            ...licenceData,
                                            name,
                                            expire,
                                            startDate,
                                            licenceType,
                                        }));
                                    },
                                });
                            }}
                        />
                    </h3>
                    <div>{new Date(expire).toLocaleDateString()}</div>
                    <div className="form-group mt-2">
                        <label htmlFor="yearSelect" className="form-label">Select Year:</label>
                        <select id="yearSelect" className="form-select" value={selectedYear}
                                onChange={e => setSelectedYear(e.target.value)}>
                            {years.map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="row my-2">
                        <div className="col-md-4">
                            <h4>{lang("admin.licence.simulations")}</h4>
                            <SimulationsTable
                                simulations={simulations}
                                licenceId={licenceData.id}
                                setModal={setModal}
                                createAlert={createAlert}
                                deleteSimulation={simulationId => {
                                    setLicenceData(prevState => ({
                                        ...prevState,
                                        simulations: prevState.simulations.filter(simulation => simulation.id !== simulationId)
                                    }));
                                }}
                            />
                            <br/>
                            <AddSimulation
                                simulations={simulations}
                                licenceID={licenceData.id}
                                addSimulation={sim => setLicenceData(prevState => ({
                                    ...prevState,
                                    simulations: [...prevState.simulations, sim],
                                }))}
                            />
                        </div>
                        <div className="col-md-8">
                            <h4 className="mb-1">{lang("admin.licence.users")}</h4>
                            <div className="info-box">
                                {selectedYear && (
                                    <>
                                        <div className="info-item">
                                            <strong>{lang("admin.licence.coursesCreated")}:</strong>
                                            <div>{filteredCourseCount ? filteredCourseCount.count : 0}</div>
                                        </div>
                                        <div className="info-item">
                                            <strong>{lang("admin.licence.totalUsers")}:</strong>
                                            <div>{filteredUserCount ? filteredUserCount.count : 0}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <br/>
                            <AddUser licenceId={licenceData.id} addUser={(email, name) => setLicenceData(prevState => ({
                                ...prevState,
                                users: [...prevState.users, {
                                    email,
                                    name,
                                }],
                            }))}/>
                            <UsersTable users={users} setModal={setModal} licenceId={licenceData.id}
                                        createAlert={createAlert}
                                        deleteUser={userId => {
                                            setLicenceData(prevState => ({
                                                ...prevState,
                                                users: prevState.users.filter(user => user.id !== userId)
                                            }));
                                        }}
                                        selectedYear={selectedYear}/>
                            <br/>
                        </div>
                    </div>
                    <div>
                        <Button className="primary" text="Edit" disabled/>
                        <Button
                            className="danger"
                            text={lang("admin.licence.delete")}
                            onClick={() => {
                                setModal('confirm', {
                                    title: lang("admin.licence.deleteLicenceApproval"),
                                    confirm: async () => {
                                        const res = await apiv3.delete(`/admin/licence/${licenceData.id}/`);
                                        createAlert({
                                            type: res.data.isValid ? 'success' : 'danger',
                                            msg: res.data.msg,
                                        });
                                        if (res.data.isValid) {
                                            window.location.href = '/admin/licences/';
                                        }
                                    },
                                });
                            }}
                        />
                    </div>
                </Box>
            </div>
        </div>
    );
};

const SimulationsTable = ({simulations, licenceId, setModal, deleteSimulation, createAlert}) => simulations.length === 0 ? <p className="my-2">No simulations found!</p> : (
    <div className="table">
        <table>
            <thead>
            <tr>
                <th>{ lang("admin.licence.name") }</th>
                <th>{ lang("admin.licence.maxPerson") }</th>
                <th>{ lang("admin.licence.maxPerTeam") }</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {simulations.map((simulation, i) => (
                <tr key={i}>
                    <td style={{textAlign: "left"}}>{simulation.name}</td>
                    <td style={{textAlign: "left"}}>{simulation.maxPeriod}</td>
                    <td style={{textAlign: "left"}}>{simulation.maxTeam}</td>
                    <td><Button className="danger small" icon="fas fa-trash" onClick={e => {
                        setModal("confirm", {
                            title: lang("admin.licence.deleteSimulationApproval"),
                            confirm: () => {
                                apiv3.delete(`/admin/licence/${licenceId}/simulation/${simulation.id}`)
                                    .then(res => {
                                        console.log(res)
                                        createAlert({
                                            type: res.data.isValid ? "success" : "danger",
                                            msg: res.data.isValid ? lang("admin.licence.deleteSuccess") : res.data.msg,
                                        });
                                    });
                                deleteSimulation(simulation.id);
                            },
                        });
                        e.stopPropagation();
                        e.preventDefault();
                    }}/></td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>
);

const UsersTable = ({users, deleteUser, setModal, licenceId, createAlert, selectedYear}) => users.length === 0 ?
    <p className="my-2">{ lang("admin.licence.noUser") }</p> : (
        <div className="table">
            <table>
                <thead>
                <tr>
                    <th>{ lang("admin.licence.email") }</th>
                    <th>{ lang("admin.licence.name") }</th>
                    <th>{ lang("admin.licence.courseCreated") }</th>
                    <th>{ lang("admin.licence.courseUserCount") }</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    <Route key={user.id} render={({history}) => (
                        <tr onClick={() => {
                            history.push("/admin/users/" + user.id);
                        }} style={{cursor: "pointer"}}>
                            <td style={{textAlign: "left"}}>{user.email}</td>
                            <td style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: user.name}}/>
                            <td style={{textAlign: "left"}}>
                                {user.createdCourseCount.filter(item => item.year === selectedYear).map(item => (
                                    <div key={item.year}>{item.count}</div>
                                ))}
                            </td>
                            <td style={{textAlign: "left"}}>
                                {user.userCount.filter(item => item.year === selectedYear).map(item => (
                                    <div key={item.year}>{item.count}</div>
                                ))}
                            </td>
                            <td><Button className="danger small" icon="fas fa-trash" onClick={e => {
                                setModal("confirm", {
                                    title: lang("admin.licence.deleteUserFromLicenceApproval"),
                                    confirm: () => {
                                        apiv3.delete(`/admin/licence/${licenceId}/user/${user.id}`)
                                            .then(res => {
                                                createAlert({
                                                    type: res.data.isValid ? "success" : "danger",
                                                    msg: res.data.msg,
                                                });
                                            });
                                        deleteUser(user.id);
                                    },
                                });
                                e.stopPropagation();
                                e.preventDefault();
                            }}/></td>
                        </tr>
                    )}/>
                ))}
                </tbody>
            </table>
        </div>
    );

export default connect(null, mapDispatchToProps)(Licence);