import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {apiv3, sessionHashName} from "../../axios";

import {setModal, toggleSidebar} from "../../store/actions/ui";

import "./Nav.css";
import {capitalize, getCookie, getCourseById, getItemWithID, isEmptyArr, setCookie} from "../../assets/js/utility";
import {Dropdown} from "../Dropdown/Dropdown";
import {lang} from "../../assets/js/language-utils";

class Nav extends Component {

    state = {
        profileDropDownActive: false,
        actionsDropDownActive: false,
        languageDropdownActive: false
    }

    actionsDropdownToggle = () => {
        this.setState(prevState => {
            return {
                actionsDropDownActive: !prevState.actionsDropDownActive,
                profileDropDownActive: false,
                languageDropdownActive: false
            }
        })
    }

    profileDropdownToggle = () => {
        this.setState(prevState => {
            return {
                profileDropDownActive: !prevState.profileDropDownActive,
                actionsDropDownActive: false,
                languageDropdownActive: false
            }
        })
    }

    languageDropdownToggle = () => {
        this.setState(prevState => {
            return {
                languageDropdownActive: !prevState.languageDropdownActive,
                profileDropDownActive: false,
                actionsDropDownActive: false
            }
        })
    }

    formatTitle = (rawTitle) => {
        return capitalize(      // Return the capitalized verion
            rawTitle
            .replace('_',' ')   // Replace the underscore with space
            .split('/')         // Split into an array for each '/' char
            .pop()              // Then pop the last element
            .trim()             // Then trim the white spaces    
        )
    }

    logout = () => {
        apiv3.post('/user/logout')
        .then(res => {
            localStorage.removeItem(sessionHashName)
            window.location.replace(res.data.redirect)
        })
        .catch(err => {console.log(err)})
    }

    render() {

        const noCourseSelected = isEmptyArr(this.props.courses) || !this.props.selected.courseID

        const Title = () => {

            try {
                return <div className='title'>
                    { getCourseById(this.props.selected.courseID, this.props.courses).courseName }
                </div>
            } catch (error) {
                return null
            }
        }

        const ProfileDropdown = () => {
            return (
                <Dropdown>
                    <li className="small">{lang('nav.profile.welcome')} {this.props.name || lang('nav.profile.unknown')}</li>
                    <li className="small">{lang('nav.profile.currentLicence')}</li>
                    <li className="bold divide">{this.props.licence}</li>
                     <li><a href={"https://account.ecosim.hu/profile"} target={"_blank"} rel={"noreferrer"}>{lang('nav.profile.account')}</a></li>
                    <li><span onClick={() => this.props.setModal('licence')}>{lang('nav.profile.getLicence')}</span></li>
                    <li><span onClick={this.logout}>{lang('nav.profile.logout')}</span></li>
                </Dropdown>
            )
        }

        const ActionsDropdown = () => {

            let selectedCourse, selectedRound

            if (!noCourseSelected) {
                selectedCourse = getCourseById(this.props.selected.courseID, this.props.courses)
                selectedRound = getItemWithID(this.props.selected.roundID, selectedCourse.rounds)
            }

            return (
                <Dropdown>
                    { noCourseSelected ? (
                        <li className="small divide">{lang('nav.actions.noActiveCourse')}</li>
                    ) : (
                        <div>
                            <li className="small">{lang('nav.actions.activeCourse')}</li>
                            <li className="bold">{ selectedCourse.courseName }</li>
                        </div>
                    ) }
                    
                    { noCourseSelected ? ( null )
                        : (<li className = "bold divide" > { selectedRound.name} </li>)
                    }

                    <li><span onClick={() => this.props.setModal('joinCourse')}>{lang('nav.actions.joinCourse')}</span></li>

                    { this.props.haveLicence ? (
                        <li><span onClick={() => this.props.setModal('createCourse')}>{lang('nav.actions.createCourse')}</span></li>
                    ) : null }

                    <li><Link to='/courses'>{lang('nav.actions.activateCourse')}</Link></li>
                </Dropdown>
            )
        }

        const supportedLanguages = [{
            id: 'en',
            text: 'english',
            active: true
        }, {
            id: 'hu',
            text: 'magyar',
            active: false
        }, {
            id: 'sk',
            text: 'slovenský',
            active: false
        }, {
            id: 'ro',
            text: 'română',
            active: false
        }, {
            id: 'ua',
            text: 'українська',
            active: false
        }]

        const LanguageDropdown = () => {
            return (
                <Dropdown>
                    { supportedLanguages.map(lang => (
                        <li key = { lang.id }><span onClick = { () => setLanguage(lang.id) }>{ capitalize(lang.text) }</span></li>
                    )) }
                </Dropdown>
            )
        }

        return (
            <nav className="nav light">
                <div className="left d-flex">
                    <span className="toggle" onClick={this.props.toggleSidebar}>
                        <i className="fas fa-bars"></i>
                    </span>
                    <Title />
                </div>
                <div className="right">
                    <div className="actions" onClick={this.actionsDropdownToggle}>
                        <i className="fas fa-ellipsis-v"></i>
                        { this.state.actionsDropDownActive ? <ActionsDropdown /> : null }
                    </div>
                    <div className="profile" onClick={this.profileDropdownToggle}>
                        <i className="fas fa-user-astronaut"></i>
                        { this.state.profileDropDownActive ? <ProfileDropdown /> : null }
                    </div>
                    <div className="language" onClick={this.languageDropdownToggle}>
                        <span>{ getActiveLanguage().toUpperCase() }</span>
                        { this.state.languageDropdownActive ? <LanguageDropdown /> : null }
                    </div>
                </div>
            </nav>
        )
    }

}

const getActiveLanguage = () => {
    return getCookie('ecoLanguage') || 'en'
}

const setLanguage = (language) => {
    console.log("setCookie start")
    setCookie('ecoLanguage', language)
    window.location.reload()
}

const mapStateToProps = state => {
    return {
        licence: state.user.profile.licenceType || 'user',
        haveLicence: state.user.profile.haveLicence,
        name: state.user.profile.firstName,
        courses: state.user.courses,
        selected: state.user.selected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSidebar: () => dispatch(toggleSidebar()),
        setModal: modal => dispatch(setModal(modal))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav))