import {lang} from "./language-utils";

export const capitalize = ( string ) => (
    string.charAt(0).toUpperCase() + string.slice(1)
)

export const handleSubmit = ( e, callback ) => {
    e.preventDefault()
    validateForm(e, callback)
}

export const validateForm = ( e, callback ) => {
    callback()
}

export const objMap = (obj, callback) => {
    for ( let key in obj ) {
        if (typeof obj[key] === 'object') {
            objMap(obj[key], callback)
        } else {
           return callback(obj)
        }
    }
}

export const remainingTime = ( _now, _from, elapsed=0 ) => {
    const now = new Date(_now)
    const from = new Date(_from)
    
    const dayInMSec = 1000 * 60 * 60 * 24
    const hourInMSec = 1000 * 60 * 60
    const minuteInMSec = 1000 * 60
    const remainingTime = from - now - elapsed
    const time = {}
    
    time.day = Math.max( Math.floor( remainingTime / dayInMSec ), 0 )
    time.hour = Math.max ( Math.floor( ( remainingTime - time.day * dayInMSec ) / hourInMSec ), 0 )
    time.minute = Math.max ( Math.floor( ( remainingTime - time.day * dayInMSec - time.hour * hourInMSec ) / minuteInMSec ), 0 )
    time.second = Math.max ( Math.floor( ( remainingTime - time.day * dayInMSec - time.hour * hourInMSec - time.minute * minuteInMSec ) / 1000 ), 0 )

    if (time.day > 0) {
        return time.day + 'd ' + time.hour + 'h'
    } else if (time.hour > 0) {
        return time.hour + 'h ' + time.minute + 'm'
    } else if (time.minute >= 0) {
        return time.minute + 'm ' + time.second + 's'
    } else {
        return lang("pageHeader.over")
    }
}

export const isEmptyObj = (obj) => {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export const isEmptyArr = (arr) => {
    return typeof arr === "undefined" || arr === null || arr.length === null || arr.length === 0
}

export const sumArrays = (arr1, arr2, minus = 1) => {
    if (arr1.length !== arr2.length) return arr1
    return arr1.map( (num, index) => Math.max( Number(num) + Number(arr2[index]) * minus, 0 ))
}

export const isValid = value => (
    !(typeof value === 'undefined' || isEmptyArr(value) || isEmptyObj(value) || value === null)
)

export function omit(obj, omitKey) {
    return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
            result[key] = obj[key];
        }
        return result;
    }, {});
}

export const getTeamsPool = (_team, poolArr) => {
    if (_team === null) return false
    return poolArr.filter(pool =>
        pool.teams.filter(team => team.id === _team.id).length === 1)[0]
}

export const shuffle = (array) => {
    let currentIndex = array.length, randomIndex
    while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }
    return array
}

export const getItemWithID = (id, arr) => arr.filter(item => item.id === id)[0]

export const getCourseById = (id, arr) => arr.filter(item => item.courseId === id)[0]

export const getSimulationById = (id, arr) => arr.filter(item => item.simulationId === id)[0]

export const getActiveItem = (arr) => {
    if (!arr) return false
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].active) {
            return arr[i]
        }
    }
    return false
}

export const getLastItem = arr => {
    if (!arr) return {}
    if (arr.length === 0) return {}
    const lastItemIndex = arr.length - 1
    return arr[lastItemIndex]
}

export const roundNum = (number, dec) => {
    if (typeof number !== 'number') return number
    if (typeof dec === 'undefined') return Math.round(number)
    return Math.round(number * Math.pow(10, dec)) / Math.pow(10, dec)
}

export const median = arr => {
    const len = arr.length
    if (len === 0) return 0
    const sortedArr = [...arr].sort((a, b) => a - b)
    const half = Math.floor(len / 2)
    return len % 2 ? sortedArr[half] : (sortedArr[half - 1] + sortedArr[half]) / 2.0
}

export const sum = arr => arr.reduce((total, num) => total + Number(num), 0)

export const avg = arr => {
    if (typeof arr === 'undefined') return 0
    return arr.length ? sum(arr) / arr.length : 0
}

export const sqrt = numb => numb < 0 ? 0 : Math.sqrt(numb)

export const trunc = (_str, n) => {
    const str = _str.toString()
    return (
        (str.length > n) ? str.substr(0, n ) + '...' : str
    )
}

export const getCookie = (name) => {
    var value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
    return value ? value.pop() : ''
}

export const setCookie = (name = '', value, expireDays = 14) => {
    if (!name || !value) {
        return
    }
    var date = new Date()
    date.setTime(date.getTime() + (expireDays * 1000 * 3600 * 24));
    var expires = date.toUTCString()
    var domainName = window.location.hostname;
    var cookieSuffix = domainName === "localhost" ? "" : "secure;domain=." + domainName
    var cookieString = `${name}=${value};expires=${expires};path=/;${cookieSuffix}`
    document.cookie = cookieString
}