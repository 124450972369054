import {lang} from "../../../../assets/js/language-utils.js";
import {Button} from "../../../../components/Button/Button.js";
import Box from "../../../../components/Box/Box";
import React from "react";
import {setModal} from "../../../../store/actions/ui.js";
import {connect} from "react-redux";
import Rounds from "./Rounds/Rounds.js";

const Timing = ({selectedCourse, setModal}) => (
    <Box title={lang("lobby.timing")}>
        <Rounds
            rounds={selectedCourse.rounds}
            selectedCourse={selectedCourse} />
        {selectedCourse.isOwner ? (
            <div className='line mt-1'>
                <Button
                    className='primary'
                    text={lang("lobby.addRound")}
                    onClick={() => setModal("addRound", selectedCourse)} />
            </div>
        ) : null}
    </Box>
);

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
});

export default connect(null, mapDispatchToProps)(Timing);