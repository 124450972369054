import React from "react";

import "./Card.css";

export const CardBox = props => {

    return (
        <div className='card box'>
            <div className="body">
            { props.img ? (
                <div className="img-grid">
                    <img className='image' src={props.img} alt='img' />
                </div>
            ) : null }
            <div className='content'>{props.children}</div>
            </div>
        </div>
    )

}