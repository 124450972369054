import React, { useEffect, useState } from "react";
import Box from "../components/Box/Box";
import { apiv3, testHash } from "../axios";
import "../components/Table/Table.css";
import { Button } from "../components/Button/Button";
import qs from "qs";
import { closeTooltip, createAlert, createTooltip, setModal } from "../store/actions/ui";
import { connect } from "react-redux";
import {lang} from "../assets/js/language-utils";

function LicenceList(props) {
    const history = props.history;
    const [licences, setLicences] = useState([]);
    const [licenceRequests, setLicenceRequests] = useState([]);

    useEffect(() => {
        getLicences();
    }, []);

    const getLicences = () => {
        apiv3.get("/admin/licence/")
            .then((res) => {
                if (res.data.isValid) {
                    setLicences(res.data.data.licences);
                    setLicenceRequests(res.data.data.requests);
                } else {
                    console.error(res.data.msg);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteRequest = (id) => {
        const uri = `admin/licence/request/${id}`;
        apiv3
            .delete(uri)
            .then((res) => {
                if (res.data.isValid) {
                    setLicenceRequests((prevRequests) =>
                        prevRequests.filter((req) => req.id !== id)
                    );
                } else {
                    console.error(res.data.msg);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const addLicense = (name, startDate, expire, licenceType) => {
        if (name.length > 50) {
            name = name.slice(0, 50);
        }
        expire = expire._i;
        apiv3
            .post("/admin/licence/", qs.stringify({ testHash, name, startDate, expire, licenceType }))
            .then((res) => {
                props.createAlert({
                    type: res.data.isValid ? "success" : "danger",
                    msg: res.data.msg,
                });
                if (res.data.isValid) {
                    const newLicence = {
                        name,
                        expire,
                        simulations: [],
                        id: +licences[licences.length - 1].id + 1,
                    };
                    setLicences((prevState) => [...prevState, newLicence]);
                }
            });
    };

    const newLicenseButton = (
        <Button
            icon="fas fa-plus-circle"
            className="primary"
            text={lang("admin.licence.newLicence")}
            onClick={() => {
                props.setModal("addLicense", { handler: addLicense });
            }}
        />
    );

    const LicenceRequests = () => {
        if (licenceRequests.length === 0) {
            return null;
        }
        return (
            <Box title="Requests">
                <div className="table">
                    <table>
                        <thead>
                        <tr>
                            <th>{lang("admin.licenceTable.type")}</th>
                            <th>{lang("admin.licenceTable.simulations")}</th>
                            <th>{lang("admin.licenceTable.institution")}</th>
                            <th>{lang("admin.licenceTable.fullName")}</th>
                            <th>{lang("admin.licenceTable.email")}</th>
                            <th>{lang("admin.licenceTable.mobile")}</th>
                            <th>{lang("admin.licenceTable.createdAt")}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {licenceRequests.map((request, i) => (
                            <tr key={i}>
                                <td style={{ textAlign: "left" }}>{request.licenceType ?? ""}</td>
                                <td style={{ textAlign: "left" }}>{request.simulations}</td>
                                <td style={{ textAlign: "left" }}>{request.institution}</td>
                                <td style={{ textAlign: "left" }}>{request.fullName}</td>
                                <td style={{ textAlign: "left" }}>{request.email}</td>
                                <td style={{ textAlign: "left" }}>{request.mobile}</td>
                                <td style={{ textAlign: "left" }}>{request.createDateTime}</td>
                                <td>
                                    <Button
                                        className="danger small"
                                        icon="fas fa-trash-alt"
                                        onClick={() => deleteRequest(request.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Box>
        );
    };

    const Licences = () =>
        <Box title="Licences" right={newLicenseButton}>
            <div className="table">
                <table>
                    <thead>
                    <tr>
                        <th>{lang("admin.licenceTable.id")}</th>
                        <th>{lang("admin.licenceTable.name")}</th>
                        <th>{lang("admin.licenceTable.simulations")}</th>
                        <th>{lang("admin.licenceTable.expire")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {licences.map(licence => <Licence key={licence.id} {...licence} />)}
                    </tbody>
                </table>
            </div>
        </Box>;

    const Licence = ({ id, name, expire, simulations }) => {
        const displayedSimulations = simulations.map((sim) => sim.name);
        let displayed = displayedSimulations;
        let hover = null;
        if (displayed.length > 4) {
            displayed = displayed.slice(0, 4);
            displayed.push("...");
            hover = (e) => {
                props.createTooltip({ tip: displayedSimulations.join(", ") }, e.target);
            };
        }

        return (
            <tr
                style={{ cursor: "pointer" }}
                onClick={() => {
                    history.push(`/admin/licences/${id}`);
                }}
            >
                <td>{id}</td>
                <td style={{ textAlign: "left" }}>{name}</td>
                <td style={{ textAlign: "left" }} onMouseEnter={hover} onMouseLeave={() => props.closeTooltip()}>
                    {displayed.join(", ")}
                </td>
                <td style={{ textAlign: "left" }}>{new Date(expire).toLocaleDateString()}</td>
            </tr>
        );
    };

    return (
        <div className="admin">
            <div className="container fluid">
                <LicenceRequests />
                <Licences />
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
    createAlert: (alert) => dispatch(createAlert(alert)),
    createTooltip: (tip, target) => dispatch(createTooltip(tip, target)),
    closeTooltip: () => dispatch(closeTooltip()),
});

export default connect(null, mapDispatchToProps)(LicenceList);
