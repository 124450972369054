// External
import React, {Component} from "react";
import {connect} from "react-redux";

import JoinCourse from "./views/Course/JoinCourse";
import CreateCourse from "./views/Course/CreateCourse";
import DeleteCourse from "./views/Course/DeleteCourse";
import EditCourse from "./views/Course/EditCourse";
import Licence from "./views/Licence";
import CreateTeam from "./views/Team/CreateTeam";
import InviteUser from "./views/User/InviteUser";
import AddRound from "./views/Round/AddRound";
import EditRound from "./views/Round/EditRound";
import SelectMarket from "./views/SelectMarket";
import DeleteRound from "./views/Round/DeleteRound";

import Modal from "./Modal";
import {Loader} from "../Loader/Loader";
import MoveTeams from "./views/Team/MoveTeams";
import AddLicense from "./views/Admin/AddLicense";
import Confirm from "./views/Confirm";
import AskInput from "./views/AskInput";

class ModalHandler extends Component {
    
    switchModal = () => {
        
        switch (this.props.modal.name) {
            
            case "joinCourse":
                return <JoinCourse />;
            case "createCourse":
                return <CreateCourse />;
            case "deleteCourse":
                return <DeleteCourse />;
            case "editCourse":
                return <EditCourse />;
            case "licence":
                return <Licence />;
            case "createTeam":
                return <CreateTeam />;
            case "inviteUser":
                return <InviteUser />;
            case "addRound":
                return <AddRound />;
            case "editRound":
                return <EditRound />;
            case "deleteRound":
                return <DeleteRound />;
            case "selectMarket":
                return <SelectMarket />;
            case "loader" :
                return <ModalLoader {...this.props.modal.data} />;
            case "moveTeams" :
                return <MoveTeams />;
            case "addLicense" :
                return <AddLicense />;
            case "confirm" :
                return <Confirm />;
            case "askinput":
                return <AskInput />;
            default:
                return null;
        }
    };
    
    render() {
        return this.switchModal();
    }
    
}

function ModalLoader(props) {
    return (
        <Modal className="loader" noescape>
            <Loader {...props} />
        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        modal: state.ui.modal,
    };
};

export default connect(mapStateToProps)(ModalHandler);