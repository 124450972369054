import React from "react";
import {isEmptyObj} from "../../assets/js/utility";

export const Alert = (props) => {
    const className = 'alert ' + props.type
    
    const content = isEmptyObj(props.msg) || props.msg === '' || props.msg === null || typeof props.msg === 'undefined'
        ? ('Valami hiba történt!')
        : props.msg;
    
    return (
        <div className = { className } onClick = { props.onClick }>
            <div className="content" dangerouslySetInnerHTML={{__html: content}} />
        </div>
    )
}