import React from "react";

import "./Form.css";
import {InputText} from "./Inputs";

export const BuildInputs = props => {

    return (
        <div className="row">
            { props.data.map((input, index) => {
                return (
                    <div className={'col-' + input.col} key = { input.name }>
                        <InputText
                            label =         { input.label }
                            type =          { input.type }
                            name =          { input.name }
                            placeholder =   { input.placeholder }
                            value =         { props.value[input.name] }
                            onChange =      { props.onChange }
                            focus =         { !index }
                        />
                    </div>
                )
            }) }
        </div>
    )

}