import {lang} from "../../../../../assets/js/language-utils.js";
import React from "react";

const CourseID = ({id}) => (
    <div className='line'>
        <div>{lang("lobby.id")}</div>
        <div># {id}</div>
    </div>
);

export default CourseID;