import React from "react";
import Box from "../../../../components/Box/Box";
import {InputSelect} from "../../../../components/Form/Inputs.js";
import {connect} from "react-redux";
import {getCourseById} from "../../../../assets/js/utility.js";
import {lang} from "../../../../assets/js/language-utils.js";

const SelectRound = ({courses, selectedCourseID, selectedRoundID, setSelectedRoundID}) => {
    const course = getCourseById(selectedCourseID, courses);
    
    return (
        <Box
            title={lang("lobby.roundSelection")}>
            {lang("lobby.roundSelectionDesc")}
            <div className='form'>
                <InputSelect
                    selected={selectedRoundID}
                    onChange={e => setSelectedRoundID(e.target.value)}
                    options={course.rounds} />
            </div>
        </Box>
    );
};

const mapStateToProps = state => ({
    courses: state.user.courses,
    selectedCourseID: state.user.selected.courseID,
});


export default connect(mapStateToProps)(SelectRound);