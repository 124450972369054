import React, {createRef, useState} from "react";
import {Button} from "../../../../../../components/Button/Button.js";
import {apiv3, testHash} from "../../../../../../axios.js";
import qs from "qs";
import {createAlert} from "../../../../../../store/actions/ui.js";
import {connect} from "react-redux";
import {lang} from "../../../../../../assets/js/language-utils.js";

const File = ({id, name, file, data, canEdit, courseID, onDelete, createAlert}) => {
    const downloadLinkRef = createRef();
    const nameInputRef = createRef();
    
    let [editing, setEditing] = useState(false);
    let [currentName, setCurrentName] = useState(name);
    
    const handleNameChange = name => {
        setCurrentName(name);
        setEditing(false);
        const query = qs.stringify({
            testHash,
            description: name,
        });
        apiv3.put(`/courseFile/${courseID}/${id}`, query)
            .then(res => createAlert({type: res.data.isValid ? "success" : "danger", msg: res.data.msg}));
    };
    
    const handleDelete = () => {
        apiv3.delete(`/courseFile/${courseID}/${id}`)
            .then(res => createAlert({type: res.data.isValid ? "success" : "danger", msg: res.data.msg}));
        onDelete(id);
    };
    
    const handleDownload = async () => {
        const data = await apiv3.get(`/courseFile/${courseID}/${id}`)
            .then(res => res.data.data);
        const rawData = new Uint8Array(data.data.split("").map(x => x.codePointAt(0)));
        
        let blob = new Blob([rawData], {type: "octet/stream"});
        let url = window.URL.createObjectURL(blob);
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = data.fileName;
        downloadLinkRef.current.click();
    };
    
    return (
        <div className="line">
            <a ref={downloadLinkRef} style={{display: "none"}} href={data} download={file}>Download</a>
            {editing ? (
                <input
                    ref={nameInputRef}
                    defaultValue={currentName}
                    autoFocus={true}
                    onBlur={e => handleNameChange(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === "Enter")
                            handleNameChange(e.target.value);
                    }} />
            ) : (
                <span>{currentName}</span>
            )}
            <div className="line" style={{width: "auto"}}>
                <Button
                    className="primary small"
                    icon="fas fa-download"
                    onClick={handleDownload}
                    title={lang("lobby.fileDownload")}
                />
                {canEdit ? <Button
                    className="primary small"
                    icon="fas fa-edit"
                    onClick={() => setEditing(true)}
                    title={lang("lobby.fileNameEdit")}
                /> : null}
                {canEdit ? <Button
                    className="danger small"
                    icon="fa  fa-trash"
                    onClick={() => handleDelete()}
                    title={lang("lobby.fileRemove")}
                /> : null}
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    createAlert: alert => dispatch(createAlert(alert))
});

export default connect(null, mapDispatchToProps)(File);