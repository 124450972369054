import {lang} from "../../../../../assets/js/language-utils.js";
import {getItemWithID} from "../../../../../assets/js/utility.js";
import React from "react";
import {connect} from "react-redux";

const SimulationName = ({simulationID, simulations}) => (
    <div className='line'>
        <div>{lang("lobby.simulation")}</div>
        <div>{getItemWithID(simulationID, simulations).name}</div>
    </div>
);

const mapStateToProps = state => ({
    simulations: state.user.simulations
});

export default connect(mapStateToProps, null)(SimulationName);