import {CardBox} from "../../../components/Card/CardBox.js";
import lobbyImg from "../../../assets/img/lobby.svg";
import {lang, langAsHtml} from "../../../assets/js/language-utils.js";
import {Button} from "../../../components/Button/Button.js";
import React from "react";
import {setModal} from "../../../store/actions/ui.js";
import {connect} from "react-redux";

const WelcomeCard = ({history, setModal}) => (
    <CardBox img={lobbyImg}>
        <h3>{lang("lobby.welcomeTitle")}</h3>
        <div className='main'>{langAsHtml("lobby.desc1")}</div>
        <div className='main'>{langAsHtml("lobby.desc2")}</div>
        <div className='line'>
            <Button className='primary' text={lang("lobby.joinCourse")}
                    onClick={() => setModal("joinCourse")} />
            <Button className='danger' text={lang("lobby.activateCourse")}
                    onClick={() => history.push("/courses")} />
        </div>
    </CardBox>
);

const mapDispatchToProps = dispatch => ({
    setModal: (name, data) => dispatch(setModal(name, data)),
});

export default connect(null, mapDispatchToProps)(WelcomeCard);