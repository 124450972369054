import {lang} from "../../../../assets/js/language-utils";
import {connect} from "react-redux";

const Pool = ({courses, selectedCourseID, selectedRoundID, selectedPoolID}) => {
    const course = courses.find(course => course.courseId === selectedCourseID);
    const round = course.rounds.find(round => round.id === selectedRoundID);
    const pool = round.pools.find(pool => pool.id === selectedPoolID);
    return lang("selectMarket.pool") + ": " + pool.name;
};

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
        selectedCourseID: state.user.selected.courseID,
        selectedRoundID: state.user.selected.roundID,
        selectedPoolID: state.user.selected.poolID,
    };
};

export default connect(mapStateToProps, null)(Pool);