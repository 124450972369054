import React, {useState} from "react";
import {connect} from "react-redux";
import Modal from "../Modal.js";
import {InputText} from "../../Form/Inputs.js";
import {Button} from "../../Button/Button.js";
import {setModal} from "../../../store/actions/ui.js";
import {lang} from "../../../assets/js/language-utils.js";

const AskInput = ({title, confirm, cancel, setModal}) => {
    confirm = confirm ?? (() => {
    });
    cancel = cancel ?? (() => {
    });
    
    const [input, setInput] = useState("");
    
    return (
        <Modal
            title={title}>
            <div className="form">
                <div className="body">
                    <InputText
                        type="text"
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        focus/>
                    <div className="line">
                        <Button
                            type="button"
                            className="danger"
                            text={lang("lobby.cancel")}
                            onClick={() => {
                                setModal(null);
                                cancel();
                            }} />
                        <Button
                            type="button"
                            className="primary"
                            text={lang("lobby.confirm")}
                            onClick={() => {
                                setModal(null);
                                confirm(input);
                            }} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = state => ({
    confirm: state.ui.modal.data.confirm,
    cancel: state.ui.modal.data.cancel,
    title: state.ui.modal.data.title,
});

const mapDispatchToProps = dispatch => ({
    setModal: modal => dispatch(setModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AskInput);