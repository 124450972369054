/**
 * @file The main structure of the application and the basic requests are handled here.
 */

// External
import React, {Component} from "react";
import {Router} from "react-router-dom";
import {connect} from "react-redux";
import history from "./history";
// Actions
import {fetchUserData, initUser} from "./store/actions/user";
// Main components
import MainRouter from "./views/Router";
import Sidebar from "./components/Sidebar/Sidebar";
import Nav from "./components/Nav/Nav";
// Sub components
import ModalHandler from "./components/Modal/ModalHandler";
import AlertDeck from "./components/Alert/AlertDeck";
import {Loader} from "./components/Loader/Loader";
import {Error} from "./components/Error/Error";
import {getClass} from "./model/SimTypeHandler";
import Tooltip from "./components/Tooltip/Tooltip";

class App extends Component {

  state = {
    init: false
  }

  componentDidMount() {
    this.setState({ init: true });
    this.props.fetchUserData();
    this.updateEcoSrvDateTime();
    this.interval = setInterval(this.updateEcoSrvDateTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateEcoSrvDateTime = () => {
    const serverDateTime = new Date().toISOString();
    localStorage.setItem('ecoSrvDateTime', serverDateTime);
  }

  render() {
  
    // TODO: Please remove
    if (+this.props.selected.simulationID === 22) {
      document.body.classList.add("override-design")
    }
    if (!this.state.init) {
      return <Loader center={true} />
    }

    const Body = () => {
      if (this.props.isFetching) {
        return <Loader center={true} />
      }

      if (this.props.isError) {
        return <Error text='Oops! An error occured!' small='Try to refresh the page!' />
      }

      return <MainRouter />
    }

    return (
      <div className = 'App'>
        <Router history = { history } >
          <div className='wrapper'>
            <Sidebar />
            <main id = 'main' className = { getClass(this.props.selected) }>
              <Nav />
              <Body />
              <ModalHandler />
              <AlertDeck />
            </main>
            <Tooltip />
          </div>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      isFetching: state.ui.isFetching,
      isError: state.ui.isError,
      selected: state.user.selected,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      fetchUserData: () => dispatch(fetchUserData()),
      initUser: data => dispatch(initUser(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
