import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import Box from "../components/Box/Box";
import {apiv3} from "../axios";
import { lang } from "../assets/js/language-utils";

export default function Course(props) {
    const { id } = props.match.params;
    const [courseData, setCourseData] = useState({
        id: "",
        name: "",
        code: "",
        createdAt: "",
        simulation: "",
        rounds: [],
        pools: [],
        teams: [],
        users: [],
    });

    useEffect(() => {
        getCourse();
    }, [id]);

    const getCourse = () => {
        const uri = `/admin/course/${id}`;
        apiv3
            .get(uri)
            .then((res) => {
                if (res.data.isValid) {
                    setCourseData({ ...res.data.data });
                } else {
                    console.error(res.data.msg);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const { name, code, createdAt, simulation, rounds, pools, teams, users } = courseData;

    return (
        <div className="admin">
            <div className="container fluid">
                <Box>
                    <h3>#{id} {name}</h3>
                    <div>{lang("admin.course.createdAt")} {new Date(createdAt).toLocaleDateString()}</div>
                    <div>{lang("admin.course.code")} {code}</div>
                    <div className="mb-2">{lang("admin.course.simulation")} {simulation}</div>
                    <RoundList rounds={rounds} pools={pools} teams={teams} users={users} />
                </Box>
            </div>
        </div>
    );
}

const RoundList = ({ rounds, pools, teams, users }) => (
    rounds.map((round) => (
        <div className="my-2" key={round.id}>
            <h4 className="mb-1">{round.name}</h4>
            <PoolsList pools={pools.filter((pool) => pool.roundId === round.id)} teams={teams} users={users} />
        </div>
    ))
);

const PoolsList = ({ pools, teams, users }) => (
    <div className="row">{pools.map((pool) => (
        <div className="col-md-4" key={pool.id}>
            <div className="mx-2"><strong>{pool.name}</strong></div>
            <TeamsList poolId={pool.id} teams={teams.filter((team) => team.poolId === pool.id)} users={users} />
        </div>
    ))}
    </div>
);

const TeamsList = ({ poolId, teams, users }) => (
    teams.map((team) => (
        <div className="my-1" key={`${poolId} - ${team.id}`}>
            <strong>{team.index} - {team.name}</strong>
            <UserList users={users.filter((user) => user.teamId === team.id)} />
        </div>
    ))
);

const UserList = ({ users }) => (
    users.map((user) => (
        <div key={user.id}>
            <Route render={({ history }) => (
                <div onClick={() => { history.push(`/admin/users/${user.id}`) }} style={{ cursor: "pointer" }}>{user.email}</div>
            )} />
        </div>
    ))
);