import {lang} from "../../../../assets/js/language-utils.js";
import {Button} from "../../../../components/Button/Button.js";
import {getCourseById, getItemWithID} from "../../../../assets/js/utility.js";
import XLSX from "xlsx";
import Box from "../../../../components/Box/Box";
import React from "react";
import {connect} from "react-redux";
import qs from "qs";
import {apiv3, testHash} from "../../../../axios";
import {createAlert} from "../../../../store/actions/ui";
import {deleteUserFromTeam} from "../../../../store/actions/user";

const PoolTeams = ({selected, courses, teams, roundID, createAlert, deleteUserFromTeam}) => {
    let course, round, pools;
    if (selected.courseID) {
        course = getCourseById(selected.courseID, courses);
        round = getItemWithID(roundID, course.rounds);
        pools = round.pools;
        teams = course.teams;
    }

    const teamsInPools = (pools ?? []).reduce((prev, curr) => [...prev, ...curr.teams.map(team => team.id)], []);
    const unassignedTeams = (teams ?? []).filter(team => teamsInPools.indexOf(team.id) === -1);

    const canDelete = course.isOwner;

    return (
        <Box noFlex={true} title={[
            <div className="d-flex justify-content-between align-items-center" key="teams-header">
                <span>{lang("lobby.teams")} ({teams.length})</span>
                <Button
                    className="primary small btn-sm ml-auto"
                    title={lang("lobby.teamExport")}
                    key={1}
                    text={lang("pageHeader.csvExport")}
                    onClick={() => {
                        let arr = [];
                        if (!pools.some(item => item.teams && item.teams.length > 0)) {
                            arr.push(["No teams in pools, add teams to pools to export users"]);
                        } else {
                            pools.map(pool => (
                                pool.teams.forEach(team => (
                                    getItemWithID(team.id, teams).users.forEach(user => {
                                        arr.push([user["e-mail"], user.firstName + " " + user.lastName, "", user.lastName, user.firstName, team.name]);
                                    })
                                ))
                            ));
                        }
                        console.log(course)
                        let title = course.courseName + "_users";
                        let wb = XLSX.utils.book_new();
                        wb.props = {
                            Title: title,
                            CreatedDate: new Date(),
                        };
                        wb.SheetNames.push("Sheet");
                        wb.Sheets["Sheet"] = XLSX.utils.aoa_to_sheet(arr);
                        let saveData = XLSX.write(wb, {bookType: "xlsx", type: "binary"});
                        let buffer = new ArrayBuffer(saveData.length);
                        let view = new Uint8Array(buffer);
                        for (let i = 0; i < saveData.length; i++) {
                            view[i] = saveData.charCodeAt(i) & 0xFF;
                        }
                        let link = document.createElement("a");
                        link.style.display = "none";
                        link.setAttribute("href", URL.createObjectURL(new Blob([buffer], {
                            type: "application/octet-stream",
                        })));
                        link.setAttribute("download", title + ".xlsx");
                        document.body.appendChild(link);
                        link.click();
                    }}
                />
            </div>
        ]}>


        {unassignedTeams.length !== 0 ? (
                <Box title={lang("lobby.unassignedTeams")}>
                    {unassignedTeams.map(team => (
                        <div className="my-1" key={`${team.poolId} - ${team.id}`}>
                            <strong>{team.name}</strong>
                            {(getItemWithID(team.id, teams).users || []).map((user, i) => (
                                <div className="line" key={i}>
                        <span>
                            {user.firstName} {user.lastName} - {user["e-mail"]}
                        </span>
                                    {canDelete ? (
                                        <Button
                                            className="danger small"
                                            title={lang("user.deleteUser")}
                                            icon="far fa-trash-alt"
                                            onClick={() => {
                                                const data = qs.stringify({
                                                    userID: user.userId,
                                                    teamID: team.id,
                                                    roundID: roundID,
                                                    type: "delete",
                                                    testHash,
                                                });
                                                apiv3.post(`/course/${course.courseId}/moveUser`, data)
                                                    .then(res => {
                                                        if (res.data.isValid) {
                                                            deleteUserFromTeam(course.courseId, team.id, user.userId);
                                                            window.location.reload()
                                                        }
                                                        if (res.data.msg) {
                                                            createAlert({
                                                                type: res.data.isValid ? "success" : "danger",
                                                                msg: res.data.msg,
                                                            });
                                                        }
                                                    })
                                                    .catch(err => {
                                                        createAlert({
                                                            type: "danger",
                                                            msg: err,
                                                        });
                                                    });
                                            }}
                                        />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ))}
                </Box>
            ) : null}


            {teamsInPools.length !== 0 ? (
                <Box title={lang("lobby.assignedTeams")}>
                    {pools.map(pool => (
                        <Box title={pool.name} key={pool.id}>
                            {pool.teams.map(team => {
                                const teamData = getItemWithID(team.id, teams);
                                const teamUsers = teamData ? (teamData.users || []) : [];

                                return (
                                    <div className="my-1" key={`${team.poolId} - ${team.id}`}>
                                        <strong>{team.name}</strong>
                                        {teamUsers.map((user, i) => (
                                            <div className="line" key={i}>
                                    <span>
                                        {user.firstName} {user.lastName} {user.studentIdentity ? `(${user.studentIdentity})` : ''} - {user["e-mail"]}
                                    </span>
                                    {canDelete ? (
                                        <Button
                                            className="danger small"
                                            title={lang("user.deleteUser")}
                                            icon="far fa-trash-alt"
                                            onClick={() => {
                                                const data = qs.stringify({
                                                    userID: user.userId,
                                                    teamID: team.id,
                                                    roundID: roundID,
                                                    type: "delete",
                                                    testHash,
                                                });
                                                apiv3.post(`/course/${course.courseId}/moveUser`, data)
                                                    .then(res => {
                                                        if (res.data.isValid) {
                                                            deleteUserFromTeam(course.id, team.id, user.userId);
                                                            window.location.reload()
                                                        }
                                                        if (res.data.msg) {
                                                            createAlert({
                                                                type: res.data.isValid ? "success" : "danger",
                                                                msg: res.data.msg,
                                                            });
                                                        }
                                                    })
                                                    .catch(err => {
                                                        createAlert({
                                                            type: "danger",
                                                            msg: err,
                                                        });
                                                    });
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                );
                            })}
                        </Box>
                    ))}
                </Box>
            ) : null}
        </Box>
    );
};

const mapStateToProps = state => ({
    courses: state.user.courses,
    selected: state.user.selected,
});

const mapDispatchToProps = dispatch => ({
    createAlert: alert => dispatch(createAlert(alert)),
    setModal: (name, data) => dispatch(setModal(name, data)),
    deleteUserFromTeam: (courseID, teamID, userID) => dispatch(deleteUserFromTeam(courseID, teamID, userID)),
});


export default connect(mapStateToProps, mapDispatchToProps)(PoolTeams);
