import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import PageHeader from "../components/PageHeader/PageHeader";
import UserList from "../admin/UserList";
import User from "../admin/User";
import CourseList from "../admin/CourseList";
import LicenceList from "../admin/LicenceList";
import Licence from "../admin/Licence";
import Course from "../admin/Course";

class Admin extends Component {

    render() {
        const { match } = this.props
        return (
            <React.Fragment>
                <PageHeader />
                <Switch>
                    <Redirect exact path = {`${match.path}`} to = {`${match.path}/users`}/>
                    <Route exact path = {`${match.path}/users`} component = { UserList } />
                    <Route exact path = {`${match.path}/users/:id`} component = { User } />
                    <Route exact path = {`${match.path}/courses`} component = { CourseList } />
                    <Route exact path = {`${match.path}/courses/:id`} component = { Course } />
                    <Route exact path = {`${match.path}/licences`} component = { LicenceList } />
                    <Route exact path = {`${match.path}/licences/:id`} component = { Licence } />
                </Switch>
            </React.Fragment>
        )
    }

}

export default Admin