// External
import React, {Component} from "react";
import {connect} from "react-redux";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {fetchUserData} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {InputText} from "../../../Form/Inputs";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3, testHash} from "../../../../axios";
import qs from "qs";
import {lang} from "../../../../assets/js/language-utils";

class InviteUser extends Component {
    

    state = {
        email: '',
        reEmail: '',
        courseID: this.props.course.courseId,
        userID: this.props.userID,
        teamID: this.props.course.myTeam ? this.props.course.myTeam.id : null,
        isFetching: false,
        testHash: testHash,
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.email !== this.state.reEmail) {
            this.setState({
                msg: {
                    type: 'error',
                    text: lang('invitations.twoEmailsError')
                }
            })
        } else {
            this.inviteUser()
        }
    }

    inviteUser = () => {
        this.setState({ isFetching: true })
        const data = qs.stringify(this.state)
        apiv3.post(`/course/${this.props.course.courseId}/invite/`, data)
        .then(res => {
            this.setState({ isFetching: false })
            if (res.data.isValid) {
                this.inviteUserSuccess()
            } else {
                this.inviteUserFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.inviteUserFailed(err)
        })
    }

    inviteUserSuccess = () => {
        this.props.fetchUserData()
        this.props.createAlert({
            type: 'success',
            msg: lang('invitations.sentSuccess') + ' ' + this.state.email
        })
        this.props.setModal(false)
    }

    inviteUserFailed = (err) => {
        this.setState({
            msg: {
                type: 'error',
                text: err
            }
        })
    }

    render() {

        if (this.state.isFetching) {
            return (
                <Modal title={lang('invitations.process')}>
                    <Loader />
                </Modal>
            )
        }

        const FormMessage = () => {

            if (!this.state.msg) return null
            
            return (
                <div className = { 'message ' + this.state.msg.type }>
                    {this.state.msg.text}
                </div>
            )
        }

        return (
            <Modal title={lang('invitations.title')}>
                <form className='form' onSubmit={ (e) => this.handleSubmit(e, this.inviteUser) }>
                    <div className="body">
 
                         <InputText
                            label =         { lang('invitations.email') }
                            type =          'email'
                            name =          'email'
                            maxLength =     { 100 }
                            placeholder =   { lang('invitations.emailPlaceholder') }
                            value =         { this.state.email }
                            onChange =      { this.handleInputChange }
                            focus
                        />

                         <InputText
                            label =         { lang('invitations.reEmail') }
                            type =          'email'
                            name =          'reEmail'
                            maxLength =     { 100 }
                            placeholder =   { lang('invitations.reEmailPlaceholder') }
                            value =         { this.state.reEmail }
                            onChange =      { this.handleInputChange }
                        />

                        <small>{lang('invitations.tipPre')} { this.props.course.maxMember } {lang('invitations.tipAfter')}</small>
                        <FormMessage />
                    </div>
                    <div className="footer">
                        <Button className='primary' text={lang('invitations.invite')} type='submit' onClick = { (e) => this.handleSubmit(e, this.inviteUser) }/>
                        <Button className='danger' text={lang('invitations.cancel')} onClick = { () => this.props.setModal(false) } />
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        course: state.ui.modal.data,
        userID: state.user.profile.userID
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModal: modal => dispatch(setModal(modal)),
        createAlert: alert => dispatch(createAlert(alert)),
        fetchUserData: () => dispatch(fetchUserData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUser)