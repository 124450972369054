import React, {createRef, useState} from "react";
import Box from "../../../../../components/Box/Box";
import {lang} from "../../../../../assets/js/language-utils.js";
import {Button} from "../../../../../components/Button/Button.js";
import {createAlert} from "../../../../../store/actions/ui.js";
import {connect} from "react-redux";
import File from "./File/File.js";
import qs from "qs";
import {apiv3, testHash} from "../../../../../axios.js";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10Mb
const MAX_FILE_COUNT = 5;

const UploadedFiles = ({selectedCourse, createAlert}) => {
    let [files, setFiles] = useState(selectedCourse.files == null ? [] : selectedCourse.files);
    let fileInputRef = createRef();
    
    const handleFile = async file => {
        if (!file)
            return;
        
        if (file.size > MAX_FILE_SIZE) {
            createAlert({
                type: "danger",
                msg: lang("lobby.fileTooBig"),
            });
            return;
        }
        const filename = file.name.substr(-200);
        
        const reader = new FileReader();
        reader.onerror = err => createAlert({
            type: "danger",
            msg: err,
        });
        reader.readAsArrayBuffer(file);
        reader.onload = e => {
            const str = [...new Uint8Array(e.target.result)].map(x => String.fromCodePoint(x)).join("");
    
            const query = qs.stringify({
                filename,
                data: str,
                description: filename,
                testHash,
            });

            apiv3.post(`/courseFile/${selectedCourse.courseId}`, query)
                .then(res => {
                    createAlert({
                        type: res.data.isValid ? "success" : "danger",
                        msg: res.data.msg,
                    });
                    if (res.data.redirect && res.data.redirect.length > 0) {
                        setTimeout(() => {
                            window.location.href = res.data.redirect;
                        }, 3000);
                    }
                    // Must reload, because otherwise we don't know the file id
                    if (res.data.isValid)
                        window.location.reload(true);
                });
        };
    };
    return (
        <Box title={lang("lobby.uploadedFiles")}>
            {files.length > 0 ? files.map(file => (
                <File
                    id={file.id}
                    key={file.id}
                    canEdit={selectedCourse.isOwner}
                    name={file.description}
                    file={file.name}
                    data={""}
                    courseID={selectedCourse.courseId}
                    onDelete={fileId => setFiles(files.filter(file => file.id !== fileId))}/>)) : (
                <span>{lang("lobby.noFiles")}</span>
            )}
            <br />
            <br />
            {selectedCourse.isOwner ? (
                <div className="line">
                    <span>{lang("lobby.uploadDesc")}</span>
                    <input
                        ref={fileInputRef}
                        type="file"
                        name="name"
                        style={{display: "none"}}
                        onChange={e => handleFile(e.target.files[0])} />
                    <div className="spacing" />
                    <Button
                        className="primary"
                        text={lang("lobby.upload")}
                        onClick={() => fileInputRef.current.click()}
                        disabled={files.length >= MAX_FILE_COUNT}
                        title={files.length >= MAX_FILE_COUNT ? lang("lobby.tooManyFiles") : lang("lobby.fileUpload")}
                    />
                </div>
            ) : null}
        </Box>
    );
};

const mapDispatchToProps = dispatch => ({
    createAlert: alert => dispatch(createAlert(alert)),
});

export default connect(null, mapDispatchToProps)(UploadedFiles);