// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// Actions
import {createAlert, setModal} from "../../../../store/actions/ui";
import {activateCourse, deleteCourse} from "../../../../store/actions/user";
// Components
import Modal from "../../Modal";
import {Button} from "../../../Button/Button";
import {Loader} from "../../../Loader/Loader";
// Request
import {apiv3} from "../../../../axios";
import {lang} from "../../../../assets/js/language-utils";

class DeleteCourse extends Component {
    
    state = {
        isFetching: false,
    };
    
    deleteCourse = (id) => {
        this.setState({isFetching: true});
        apiv3.delete(`/course/${id}`)
            .then(res => {
                if (res.data.isValid) {
                    this.deleteCourseSuccess(res.data, id);
                } else {
                    this.deleteCourseFailed(res.data.msg);
                }
            })
            .catch(err => {
                this.deleteCourseFailed(err);
            });
    };
    
    deleteCourseSuccess = (data, id) => {
        this.setState({isFetching: false});
        this.props.setModal(false);
        window.location.reload();
        this.props.history.push("/courses");
        this.props.createAlert({
            type: "success",
            msg: data.msg,
        });
        this.props.deleteCourse(id);
        this.props.activateCourse(this.props.courses[1].id);
        apiv3.put(`/course/${id}/`)
            .then(res => createAlert({type: res.data.isValid ? "success" : "danger", msg: res.data.msg}))
            .catch(err => {
                console.log(err);
            });
    };
    
    deleteCourseFailed = (err) => {
        this.props.createAlert({
            type: "danger",
            msg: err,
        });
        this.setState({isFetching: false});
    };
    
    render() {
        
        if (this.state.isFetching) {
            return (
                <Modal title={lang("courses.deleteProcess")}>
                    <Loader/>
                </Modal>
            );
        }
        
        const Body = () => (
            <div className="body">
                <div className="main mb-1">
                    <p>{lang("courses.deleteBody")}</p>
                    <b>{this.props.course.name}</b>
                </div>
                <small>{lang("courses.deleteTip")}</small>
            </div>
        );
        const Footer = () => (
            <div className="footer">
                <Button className='danger' text={lang("courses.yes")}
                        onClick={() => this.deleteCourse(this.props.course.courseId)}/>
                <Button text={lang("courses.cancel")} onClick={() => this.props.setModal(false)}/>
            </div>
        );
        
        return (
            <Modal title={lang("courses.confirm")} className='small'>
                {this.state.isFetching ? (
                    <Loader/>
                ) : (
                    <div>
                        <Body/>
                        <Footer/>
                    </div>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        courses: state.user.courses,
        course: state.ui.modal.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateCourse: id => dispatch(activateCourse(id)),
        setModal: modal => dispatch(setModal(modal)),
        deleteCourse: id => dispatch(deleteCourse(id)),
        createAlert: alert => dispatch(createAlert(alert)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteCourse));