import React from "react";
import PageNotFound from "../views/PageNotFound";
import asyncComponent from "../components/AsyncComponent/AsyncComponent";

// Icons
import BankIcon from "../assets/img/icons/bank.png";
import FactoryIcon from "../assets/img/icons/factory.png";
import EngineIcon from "../assets/img/icons/factory.png";
import SmartSimIcon from "../assets/img/icons/smart.png";
import SnowHotelIcon from "../assets/img/icons/hotel.png";
import CornerShopIcon from "../assets/img/icons/shop.png";
import GearBikeIcon from "../assets/img/icons/gearbike.png";
import TechCoIcon from "../assets/img/icons/techco.png";
import SmartCityIcon from "../assets/img/icons/smartcity.png";

// Simulations
const BankSim = asyncComponent(() => import("./types/BankSim"));
const Factory = asyncComponent(() => import("./types/Factory"));
const SmartSim = asyncComponent(() => import("./types/SmartSim"));
const SnowHotel = asyncComponent(() => import("./types/SnowHotel"));
const CornerShop = asyncComponent(() => import("./types/CornerShop"));
const GearBike = asyncComponent(() => import("./types/GearBike"));
const Engine = asyncComponent(() => import("./types/Engine"));
const TechCo = asyncComponent(() => import("./types/TechCo"));
const FMCG = asyncComponent(() => import("./types/FMCG"));
const SmartCity = asyncComponent(() => import("./types/SmartCity"));
const BankSim2 = asyncComponent(() => import("./types/BankSim2"));

export const SimTypeHandler = ({simulationID}) => {
    switch (simulationID) {
        case "14":
            return <BankSim />;
        case "15":
            return <Factory />;
        case "16":
            return <SmartSim />;
        case "17":
            return <SnowHotel />;
        case "18":
            return <CornerShop />;
        case "19":
            return <Engine />;
        case "20":
            return <GearBike />;
        case "21":
            return <TechCo />;
        case "22":
            return <FMCG />;
        case "23":
            return <SmartCity />;
        case "24":
            return <BankSim2 />;
        default:
            return <PageNotFound />;
    }
};

// Specify the CSS in the assets/CommonSkiResort/6-special.CommonSkiResort based on theese class names
export const getClass = ({simulationID}) => {
    switch (simulationID) {
        case "14":
            return "bank";
        case "15":
            return "factory";
        case "16":
            return "smart";
        case "17":
            return "snowhotel";
        case "18":
            return "cornershop";
        case "19":
            return "engine";
        case "20":
            return "gearBike";
        case "21":
            return "techCo";
        case "22":
            return "fmcg";
        case "23":
            return "smartCity";
        case "24":
            return "bankSim_2";
        default:
            return "";
    }
};

export const SimIcon = ({simulationId}) => {
    switch (simulationId) {
        case "14":
            return <img src={BankIcon} alt="bank" />;
        case "15":
            return <img src={FactoryIcon} alt="factory" />;
        case "16":
            return <img src={SmartSimIcon} alt="smartsim" />;
        case "17":
            return <img src={SnowHotelIcon} alt="snowhotel" />;
        case "18":
            return <img src={CornerShopIcon} alt="cornershop" />;
        case "19":
            return <img src={EngineIcon} alt="engine" />;
        case "20":
            return <img src={GearBikeIcon} alt="gearbike" />;
        case "21":
            return <img src={TechCoIcon} alt="techCo" />;
        case "22":
            return <img src={SmartSimIcon} alt="fmcg" />;
        case "23":
            return <img src={SmartCityIcon} alt="smartCity" />;
        case "24":
            return <img src={BankIcon} alt="bank" />;
        default:
            return <img src={BankIcon} alt="bank" />;
    }
};