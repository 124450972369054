import React, {useState} from "react";
import "./InputNumber.css";
import {lang} from "../../assets/js/language-utils.js";

const formatValue = value => value.toFixed(3).replace(/\.?0+$/, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

const cleanupValue = value => Number(value.toString().replace(/\s/g, ""));

export const InputNumber = props => {
    const {hardMin, hardMax, maxLength, max, min, step, name, decType, item, category, path, onChange, value} = props;
    let [editedValue, setEditedValue] = useState("");
    let inputRef = React.createRef();
    let validateInput = e => {
        if (editedValue === "")
            return;
        let val = cleanupValue(editedValue).toString();
        if (val.replace("-", "").length > maxLength)
            val = val.slice(0, maxLength);
        val = Number(val);
        if (Number.isNaN(val)) {
            setEditedValue("");
            return;
        }
        if (val < hardMin)
            val = hardMin;
        if (val > hardMax)
            val = hardMax;
        // if (step)
        //     val = Math.round(val / step) * step;
        if (step < 1) {
            val = Math.round(val / step) * step;
        } else {
            val = Math.round(val)
        }
        setEditedValue("");
        e.target.value = val;
        onChange(e);
    };
    const stepValue = direction => {
        editedValue = Math.round((value + step * direction) / step) * step;
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("blur", false, true);
        inputRef.current.dispatchEvent(evt);
    };
    let warningText = "";
    if (max !== null && value > max) {
        warningText = `${lang("report.exceededMax")}${value} > ${max}`;
    }
    if (min !== null && value < min) {
        warningText = `${lang("report.exceededMin")}${value} < ${min}`;
    }
    
    return (
        <div className="input-number">
            <input
                ref={inputRef}
                className='decision'
                type='text'
                name={name}
                data-dec-type={decType}
                data-item={item}
                data-category={category}
                data-path={path}
                onBlur={validateInput}
                onFocus={() => setEditedValue(formatValue(value))}
                onKeyDown={e => {
                    if (e.key === "Enter")
                        validateInput(e);
                }}
                onChange={e => {
                    e.target.focus();
                    setEditedValue(e.target.value);
                }}
                value={editedValue === "" ? formatValue(value) : editedValue}
            />
            <div className="buttons">
                <button onMouseDown={() => stepValue(1)}>
                    ˄
                </button>
                <button onMouseDown={() => stepValue(-1)}>
                    ˅
                </button>
            </div>
            <div className={`warning ${warningText ? "enabled" : ""}`}>
                <span>!</span>
                <span className="warning-tooltip">{warningText}</span>
            </div>
            
        </div>
    );
};