import React from "react";
import "./PageHeader.css";
import {DefaultHeader} from "./DefaultHeader/DefaultHeader";
import SimulationHeader from "./SimulationHeader/SimulationHeader";

const PageHeader = ({type, onSave}) => {

    return (
        <header className="page-header">
            <div className="container fluid">
                {type === "simulation" ? <SimulationHeader onSave={onSave}/> :
                    <DefaultHeader/>}
            </div>
        </header>
    );

}

export default PageHeader;