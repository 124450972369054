import {lang} from "../../../../../../assets/js/language-utils.js";
import moment from "moment";
import React from "react";

const FinalTime = ({round}) => (
    <div className='line'>
        <div>{lang("lobby.timingEnd")}</div>
        <div>{moment(round.endTime).format("MM.DD HH:mm")}</div>
    </div>
);

export default FinalTime;