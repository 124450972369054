import "./Tooltip.css";
import {connect} from "react-redux";
import {closeTooltip} from "../../store/actions/ui";
import React, {Component} from "react";

class Tooltip extends Component {

    componentDidMount() {
        this.resize = this.resize.bind(this)
        this.click = this.click.bind(this)
        window.addEventListener('resize', this.resize)
        document.addEventListener('click', this.click)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
        document.removeEventListener('click', this.click)
    }

    render() {
        const tip = this.props.tooltip && this.props.tooltip.tip && this.props.tooltip.tip.tip
        if (!tip) return null

        const t = this.props.tooltip.target
        let rect = t.getBoundingClientRect()
        let getOffset = (el) => {
            if (el === null)
                return 0
            let parent = getOffset(el.offsetParent)
            return parent + el.offsetTop
        }
        let off = getOffset(t)
        return (
            <div className='tooltip' onClick = { this.props.onClick } style = { {
                position: "absolute",
                top: `calc(${off}px - 0.2rem)`,
                left: `calc(${rect.x}px + 0.2rem)`
            } } dangerouslySetInnerHTML={{__html: tip}}  />
        )
    }

    resize() {
        this.forceUpdate()
    }

    click(e) {
        if (this.props.tooltip != null && e.target.classList && !e.target.classList.contains('tip')) {
            this.props.closeTooltip()
        }
    }

}

const mapStateToProps = (state) => {
    return {
        tooltip: state.ui.tooltip
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeTooltip: () => dispatch(closeTooltip())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)