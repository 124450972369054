import {lang} from "../../../../assets/js/language-utils.js";
import Box from "../../../../components/Box/Box";
import React from "react";
import MembersPerTeam from "./MembersPerTeam/MembersPerTeam.js";
import CourseCode from "./CourseCode/CourseCode.js";
import SimulationName from "./SimulationName/SimulationName.js";
import CourseName from "./CourseName/CourseName.js";
import CourseID from "./CourseID/CourseID.js";
import EditRow from "./EditRow/EditRow.js";
import UploadedFiles from "./UploadedFiles/UploadedFiles.js";
import CreatedBy from "./CreatedBy/CreatedBy";

const CourseDetails = ({selectedCourse}) => (
    <Box title={lang("lobby.courseDetails")}>
        {selectedCourse.isOwner ? <CourseID id={selectedCourse.courseId} /> : null}
        <CourseName name={selectedCourse.courseName} />
        {selectedCourse.canUsersManageTeams ? <CourseCode code={selectedCourse.key} /> : null}
        <SimulationName simulationID={selectedCourse.simulationId} />
        <MembersPerTeam min={selectedCourse.minMember} max={selectedCourse.maxMember} />
        <CreatedBy name={selectedCourse.createdBy.firstName + ' ' + selectedCourse.createdBy.lastName}></CreatedBy>
        {selectedCourse.isOwner ? <EditRow selectedCourse={selectedCourse} /> : null}
        <UploadedFiles selectedCourse={selectedCourse} />
    </Box>
);

export default CourseDetails;