// External
import React, {Component} from "react";
import {connect} from "react-redux";
import {apiv3, testHash} from "../axios";
import qs from "qs";
import moment from "moment";
// Actions
import {createAlert, fetchFailed, fetchStart} from "../store/actions/ui";
import {initFinish, initSimulation, setSaveState} from "../store/actions/simulation";
// Model
import {SimTypeHandler} from "../model/SimTypeHandler";
// Components
import PageHeader from "../components/PageHeader/PageHeader";
import {CardBox} from "../components/Card/CardBox";
import {Loader} from "../components/Loader/Loader";
// Assets
import breakImg from "../assets/img/break.svg";
import {getCourseById, objMap} from "../assets/js/utility";
import {find, get, isEmpty} from "lodash";
import {lang} from "../assets/js/language-utils";
import {getPeriodValue} from "../model/common/util.js";

// Simulation language
export let sl = {}
class Simulation extends Component {

    componentDidMount() {
        if (this.props.simulation.init) return
        this.initSimState()
    }

    componentDidUpdate() {
        if (this.props.simulation.init) return
        this.initSimState()
    }

    initSimState = () => {
        const { courseID, roundID, poolID } = this.props.selected

        if (!courseID || !roundID || !poolID || isEmpty(this.props.selected)) {
            this.props.initFinish()
            return
        }

        /**
         * By adding the ?force=1 query string you can force the API
         * to download the latest changes to the EcoSim TEST FTP server
         */
        this.props.fetchStart()
        apiv3.get(`/course/${courseID}/round/${roundID}/pool/${poolID}?force=1`)
        .then(res => {
            if (this.props.licenceType === 'admin') {
                console.log(['Sim init'], res.data.data)
            }
            if (res.data.isValid) {
                this.simInitSuccess(res.data.data)
            } else {
                this.simInitFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.simInitFailed(err.message)
        })
    }

    simInitSuccess = ({ market, setup, teams, lang }) => {
        sl = lang
        this.props.initSimulation({ market, setup, teams })
    }

    simInitFailed = (err) => {
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
        this.props.initFinish()
        this.props.fetchFailed()
    }

    saveDecision = () => {
        const { selected, simulation } = this.props
        const { teams } = simulation
        const { courseID, roundID } = selected
        const { teamID, teamIndex } = simulation.setup.selected

        const period = simulation.setup.period.current
        
        const selectedTeam = teamID === null
            ? teams[teamIndex] || teams[0]
            : find(teams, team => team.id === teamID)
        const decision = getPeriodValue(selectedTeam.decisions, period)

        const validationResult = this.validateDecisions({ decision , setup: simulation.setup.decisions })

        if (validationResult.length > 0) {
            console.log(validationResult)
        }

        const data = qs.stringify({
            decision, teamID, teamIndex, period, testHash
        })
        
        this.props.setSaveState('saving')
        apiv3.post(`/course/${courseID}/round/${roundID}/decision`, data)
        .then(res => {
            if (res.data.isValid) {
                this.saveDecisionsSuccess()
            } else {
                this.saveDecisionsFailed(res.data.msg)
            }
        })
        .catch(err => {
            this.saveDecisionsFailed(err)
        })
    }

    saveDecisionsSuccess = () => {
        this.props.setSaveState(true)
        this.props.createAlert({
            type: 'success',
            msg: lang('simulation.saveDecisionsSuccess')
        })
    }

    saveDecisionsFailed = (err) => {
        this.props.setSaveState(false)
        this.props.createAlert({
            type: 'danger',
            msg: err
        })
    }

    validateDecisions = ({ decision, setup }) => {

        let res = []

        objMap(setup, (obj) => {
            const dec = get(decision, obj.path)

            if (typeof dec === 'undefined') return
            if ( dec > obj.max ) {
                res.push(`${lang('simulation.suggestedMax')} ${dec} (${obj.path}), ${obj.max} (max)`)
            }
            if ( dec < obj.min ) {
                res.push(`${lang('simulation.suggestedMax')} ${dec} (${obj.path}), ${obj.min} (min)`)
            }

        })

        return res
    }

    render() {
        const { courses, selected, simulation } = this.props
        const { init, setup } = simulation
        const { teams } = simulation
        const { teamID, teamIndex } = simulation.setup.selected

        let teamNotInPool = false
        const team = teams.find(team => team.id === teamID)
        if (team !== undefined && team.id === null) {
            teamNotInPool = true
        }

        if (!init) return <Loader />

        const ErrorCard = () =>  (
            <CardBox img = { breakImg } >
                <h3 className='mb-1'>{lang('simulation.errorTitle')}</h3>
                <div className='main'>{lang('simulation.errorDesc')}</div>
            </CardBox>
        )
        const hasError = !selected.courseID || !selected.roundID || !selected.poolID || isEmpty(setup.selected)
        if (hasError) return <ErrorCard />

        const currentPeriod = setup.period.current
        const resultDeadline = setup.deadline.result
        const selectedPeriod = setup.selected.period

        const selectedCourse = getCourseById(selected.courseID, courses) || {}

        const isLocked =
            selectedCourse.isLocked
            && !selectedCourse.isOwner

        const isBreak =
            !selectedCourse.isOwner
            && resultDeadline
            && currentPeriod === selectedPeriod

        const isNotInPool =
            !selectedCourse.isOwner
            && teamNotInPool


        const LockCard = () => (
            <CardBox img = { breakImg } >
                <h3 className='mb-1'>{lang('simulation.lockTitle')}</h3>
                <div className='main'>{lang('simulation.lockDesc')}</div>
            </CardBox>
        )

        const BreakCard = () =>  (
            <CardBox img = { breakImg } >
                <h3 className='mb-1'>{lang('simulation.breakTitle')}</h3>
                <div className='main'>{lang('simulation.breakDesc1')}</div>
                <div className='main'>{lang('simulation.breakDesc2')} <b>{ moment(resultDeadline).format('YYYY.MM.DD HH:mm') }</b></div>
            </CardBox>
        )

        const NotInPoolCard = () => (
            <CardBox img={ breakImg }>
                <h3 className='mb-1'>{lang('simulation.notInPoolTitle')}</h3>
                <div className='main'>{lang('simulation.notInPoolDesc')}</div>
            </CardBox>
        );
    
        return (
            <div className='simulation'>
                <PageHeader type='simulation'  onSave = { this.saveDecision } isOwner = { selectedCourse.isOwner } />
                <div className='container fluid'>
                    {isLocked ? <LockCard /> : (
                        isBreak ? <BreakCard />
                            : isNotInPool ? <NotInPoolCard />
                                : <SimTypeHandler simulationID={selected.simulationID} />
                    )}
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        myTeam: state.simulation.setup.myTeam,
        courses: state.user.courses,
        licenceType: state.userType,
        selected: state.user.selected,
        simulation: state.simulation,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createAlert: alert => dispatch(createAlert(alert)),
        initSimulation: (init) => { dispatch(initSimulation(init)) },
        initFinish: () => { dispatch(initFinish()) },
        setSaveState: (status) => { dispatch(setSaveState(status)) },
        fetchStart: () => { dispatch(fetchStart()) },
        fetchFailed: () => { dispatch(fetchFailed()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Simulation)