import {apiv3, sessionHashName} from "../../axios";
import {fetchEnd, fetchFailed, fetchStart} from "./ui";
import {initRequest} from "./simulation";
import qs from "qs";

export const INIT_USER = "INIT_USER";
export const DELETE_COURSE = "DELETE_COURSE";
export const ACTIVATE_COURSE = "ACTIVATE_COURSE";
export const DELETE_ROUND = "DELETE_ROUND";
export const SELECT_ROUND = "SELECT_ROUND";
export const SELECT_POOL = "SELECT_POOL";
export const DELETE_USER_FROM_TEAM = "DELETE_USER_FROM_TEAM";

export const deleteCourse = (id) => dispatch => {
    dispatch(initRequest());
    dispatch({
        type: DELETE_COURSE,
        id: id,
    });
};

export const deleteRound = (roundID, courseID) => dispatch => {
    dispatch(initRequest());
    dispatch({
        type: DELETE_ROUND,
        roundID: roundID,
        courseID: courseID,
    });
};

export const activateCourse = (id) => dispatch => {
    dispatch(initRequest());
    dispatch({
        type: ACTIVATE_COURSE,
        id: id,
    });
};

export const selectRound = (id) => dispatch => {
    dispatch({
        type: SELECT_ROUND,
        id: id,
    });
};

export const selectPool = (id) => dispatch => {
    dispatch({
        type: SELECT_POOL,
        id: id,
    });
};

export const initUser = (data) => dispatch => {
    dispatch({
        type: INIT_USER,
        data: data,
    });
};

export const fetchUserData = () => dispatch => {
    dispatch(fetchStart());
    return apiv3.get(`user?limit=${500}`)
        .then(res => {
            if (res.data.isValid) {
                localStorage.setItem('ecoSrvDateTime', res.data.data.serverDateTime);
                dispatch(initUser(res.data.data));
                dispatch(fetchEnd());
            } else {
                /*dispatch(fetchFailed(res.data.msg));*/
                const urlParams = new URLSearchParams(window.location.search);
                const loginSC = urlParams.get('loginSC');

                const data = qs.stringify({loginSC});
                apiv3.post("/user/gateway", data)
                    .then(response => {
                        if (response.data.isValid) {
                            localStorage.setItem(sessionHashName, response.data.data.sC);
                            apiv3.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(sessionHashName)}`;
                            window.location.href = response.data.redirect;
                        } else {
                            let redirect = response.data.redirect;
                            if(window.location.href.includes('localhost')){
                                redirect = redirect.replace('https://account-test.ecosim.hu', 'http://localhost:5173');
                            }
                            window.location.href = redirect;
                        }
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.error("There was an error fetching the gateway data!", error);
                    });
            }
        })
        .catch(error => {
            dispatch(fetchFailed(error));
            console.error(error);
        });
};


export const deleteUserFromTeam = (courseID, roundID, teamID, userId) => ({
    type: DELETE_USER_FROM_TEAM,
    courseID,
    roundID,
    teamID,
    userId,
});
