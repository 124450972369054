import React from "react";
import {connect} from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader.js";
import WelcomeCard from "./WelcomeCard/WelcomeCard.js";
import Content from "./Content/Content.js";

const Lobby = ({selected, courses, history}) => {
    if (!selected.courseID || !courses) {
        return (
            <div className='lobby'>
                <PageHeader />
                <div className='container fluid'>
                    <WelcomeCard history={history} />
                </div>
            </div>
        );
    }
    
    return (
        <div className='lobby'>
            <PageHeader />
            <div className='container fluid'>
                <Content selected={selected} courses={courses} />
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    courses: state.user.courses,
    selected: state.user.selected,
});

export default connect(mapStateToProps, null)(Lobby);