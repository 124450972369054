import {InputSelect} from "../../../Form/Inputs";
import React from "react";
import {connect} from "react-redux";
import {selectTeam} from "../../../../store/actions/simulation";

const TeamNameSelect = ({teams, selectedTeamId, selectedTeamIndex, selectTeam}) => {

    return <div className='form m-0'>
        <InputSelect
            name='selectedTeam'
            placeholder='Select a team'
            className='mb-0 mr-1'
            options={teams}
            selected={selectedTeamId || selectedTeamIndex}
            onChange={e => selectTeam(e.target.value)}
            required
        />
    </div>;
};

const mapStateToProps = state => {
    return {
        teams: state.simulation.teams,
        selectedTeamId: state.simulation.setup.selected.teamID,
        selectedTeamIndex: state.simulation.setup.selected.teamIndex,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectTeam: (id, index) => dispatch(selectTeam(id, index)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamNameSelect);