import style from "./CourseSearch.module.css";
import React, {useEffect, useRef, useState} from "react";
import {InputText, InputSelect, InputBox} from "../../../components/Form/Inputs";
import {lang} from "../../../assets/js/language-utils";

export function CourseSearch({courseName, setCourseName, courseCode, setCourseCode, onlyEcoSim, setOnlyEcoSim, notEcoSim, setNotEcoSim, simulationType, setSimulationType, isRunning, setIsRunning, simulations}) {
    const [updateFlag, setUpdateFlag] = useState(Date.now());
    const [active, setActive] = useState(false);
    const minSizeRef = useRef();
    const maxWidthRef = useRef();
    const maxHeightRef = useRef();

    const minSizeRect = minSizeRef.current?.getBoundingClientRect();
    const maxWidthRect = maxWidthRef.current?.getBoundingClientRect();
    const maxHeightRect = maxHeightRef.current?.getBoundingClientRect();

    const width = ((active ? maxWidthRect : minSizeRect)?.width ?? 32);
    const height = ((active ? maxHeightRect : minSizeRect)?.height ?? 32);

    useEffect(() => {
        window.addEventListener("resize", () => setUpdateFlag(Date.now()));
    }, [setUpdateFlag]);

    return (
        <div className="row">
            <div className="col-12">
                <div ref={maxWidthRef}>
                    <div style={{width: `${width}px`, height: `${height}px`}} className={style.searchBox}>
                        <button
                            ref={minSizeRef}
                            className={`${style.searchButton} ${active ? style.active : ""}`}
                            onClick={() => setActive(!active)}>
                            <span className="fas fa-magnifying-glass"></span>
                        </button>
                        <form ref={maxHeightRef} className={style.inputs + " form"}>
                            <h5>{lang("courses.search.title")}</h5>
                            <InputText
                                label={lang("courses.search.courseName")}
                                onChange={e => setCourseName(e.target.value)}
                                value={courseName}
                                type="text"
                                placeholder={lang("courses.search.courseName")}
                            />
                            <InputText
                                label={lang("courses.search.courseCode")}
                                onChange={e => setCourseCode(e.target.value)}
                                value={courseCode}
                                type="text"
                                placeholder={lang("courses.search.courseCode")}
                            />
                            <InputSelect
                                label={lang("courses.search.simulationSelect")}
                                name="simulationType"
                                placeholder={lang("courses.search.simulationSelectPlaceholder")}
                                options={simulations}
                                selected={simulationType}
                                onChange={e => setSimulationType(e.target.value)}
                                required
                            />
                            <InputBox
                                type="checkbox"
                                label={lang("courses.search.createdByEcoSim")}
                                id="onlyEcoSim"
                                name="onlyEcoSim"
                                checked={onlyEcoSim}
                                onChange={e => setOnlyEcoSim(e.target.checked)}
                            />
                            <InputBox
                                type="checkbox"
                                label={lang("courses.search.notCreatedByEcoSim")}
                                name="notEcoSim"
                                id="notEcoSim"
                                checked={notEcoSim}
                                onChange={e => setNotEcoSim(e.target.checked)}
                            />
                            <InputBox
                                type="checkbox"
                                label={lang("courses.search.isRunning")}
                                name="isRunning"
                                id="isRunning"
                                checked={isRunning}
                                onChange={e => setIsRunning(e.target.checked)}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}